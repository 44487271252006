import { Component, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { globalState } from 'src/global';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
 // Parametros paginacion
 @Input() pagina_actual: number = 1;
 @Input() total_paginas!: number;
 @Output() cambia_pagina: EventEmitter<number> = new EventEmitter();

  // Iconos a mostrar
  faAngleLeft : IconDefinition = faAngleLeft;
  faAngleRight : IconDefinition = faAngleRight
  faAnglesLeft : IconDefinition = faAnglesLeft;
  faAnglesRight : IconDefinition = faAnglesRight;
  oldRegistros : number = 1;

  ngOnInit(): void {
    // console.log( typeof this.pagina_actual)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.oldRegistros !== this.total_paginas) {
    }
  }


  suma_pagina() {
    const pagina = Number(this.pagina_actual) + 1;
    this.pagina_actual = pagina;
    this.cambia_pagina.emit(pagina);

  }

  resta_pagina() {
    const pagina = Number(this.pagina_actual) - 1;
    this.pagina_actual = pagina;
    this.cambia_pagina.emit(pagina);
  }
  primera_pagina() {
    const pagina = 1
    this.pagina_actual = pagina;
    this.cambia_pagina.emit(pagina);
  }

  ultima_pagina() {
    const pagina = this.total_paginas;
    this.pagina_actual = pagina;
    this.cambia_pagina.emit(pagina);
  }
}
