import { Component, OnInit } from '@angular/core';
import { globalState, LIMU_VERSION } from 'src/global';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LoadService } from 'src/app/services/helpers/load.service';
import { ConfiguracionService } from 'src/app/services/https/configuracion.services';
import { HttpHeaders } from '@angular/common/http';
import { obtainVersion, saveVersion } from './services/helpers/session.helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    ConfiguracionService
  ]
})
export class AppComponent {
  title = 'frontend';
  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _configuracion: ConfiguracionService,
    private _loader: LoadService,
    ){
  }

  ngOnInit() {
    if (globalState.annioActual == 0) {
      globalState.annioActual = new Date().getFullYear();
      this.getAnnioActual();
    }
  }

  async getAnnioActual() {
    try {
      const config = await this._configuracion.ficha_nueva(1);
      globalState.configuracion_global = config;
      globalState.annioActual = config.annioActual
    } catch (e: any) {
      console.log(e)
    }

  }

}
