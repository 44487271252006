import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BorradoModalComponent } from 'src/app/components/common/borrado-modal/borrado-modal.component';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createZona } from 'src/app/services/helpers/model.helper';
import { ZonasService } from 'src/app/services/https/zonas.services';

@Component({
  selector: 'app-zonas-page',
  templateUrl: './zonas-page.component.html',
  styleUrls: ['./zonas-page.component.scss'],
  providers: [ZonasService]
})
export class ZonasPageComponent implements OnInit{
  es_listado: boolean = true;
  zonas: ZonasModel[] = [];
  zona: ZonasModel | null = null;
  cargando: boolean = true;
  id: number = 0;
  constructor(
    private _router: Router,
    private _service: ZonasService,
    private _loader: LoadService,
    private _toast: ToastrService,
    private _modal_service: NgbModal
    ){
      const { url } = this._router
      const segments = url.split('/');
      this.es_listado = segments.length === 2;
      if (!this.es_listado)
        if (segments[segments.length - 1] !== "nuevo") {
          this.id = Number(segments[segments.length - 1]);
        }
  }
  async ngOnInit(): Promise<void> {
    try {
      this.cargando = true;
      this._loader.notifyLoadChange(true);
      if (this.es_listado) {
        this.zonas = await this.obtener_zonas();
      } else {
        if (this.id !== 0)
          this.zona = await this.obten_zona(this.id);
      }
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this.cargando = false;
      this._loader.notifyLoadChange(false);
    }
  }

  async obtener_zonas(): Promise<ZonasModel[]> {
    try {
      const { zonas } = await this._service.lista_completa();
      return zonas;
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  async obten_zona(id: number): Promise<ZonasModel> {
    try {
      return await this._service.ficha_nuevo(id)
    } catch (e: any){
      return Promise.reject(e);
    }
  }

  abrir_modal_borrado(id_zona: number) {
    const modalRef = this._modal_service.open(BorradoModalComponent, { centered: true })
    modalRef.componentInstance.tipo_objeto = "zona"
    modalRef.componentInstance.id = id_zona
    modalRef.componentInstance.tabla = "zonas"
    modalRef.componentInstance.borrado_exitoso.subscribe(async (borrado_exitoso: boolean) => {
      if (borrado_exitoso) {
        try {
          this.zonas = await this.obtener_zonas()
        } catch (e: any) {
          this._toast.error(e);
        } finally {
          this._loader.notifyLoadChange(false);
        }
      }
    })
  }
}
