import { Component } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BorradoModalComponent } from 'src/app/components/common/borrado-modal/borrado-modal.component';
import { TipoDocuModel } from 'src/app/models/entity/tipoDocu.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { abrir_modal_borrado_helper } from 'src/app/services/helpers/modal.helper';
import {  createTipoDocu } from 'src/app/services/helpers/model.helper';
import { TipoDocuService } from 'src/app/services/https/tipoDocu.services';

@Component({
  selector: 'app-tipo-documentacion-page',
  templateUrl: './tipo-documentacion-page.component.html',
  styleUrls: ['./tipo-documentacion-page.component.scss'],
  providers: [TipoDocuService]
})
export class TipoDocumentacionPageComponent {
  type_form: string = '';
  data: TipoDocuModel[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _service: TipoDocuService,
    private _loader: LoadService,
    private _toast: ToastrService,
    private _modal_service: NgbModal
    ){
    this._route.params.subscribe((params: Params) => {
      // Accede al valor del parámetro y asígnalo a la variable typeConfig
      if (params['slug'] !== undefined) {
        this.type_form = params['slug'];
      }
    });
  }

  ngOnInit(){
    if(this.type_form === '') {
      this.obtener_tipo_documentacion();
    }
  }

  async obtener_tipo_documentacion() {
    try {
      this._loader.notifyLoadChange(true);
      this.data = await this._service.lista_completa();
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  async abrir_modal_borrado(id: number) {
    const modalRef = this._modal_service.open(BorradoModalComponent, { centered: true })
    modalRef.componentInstance.tipo_objeto = "tipo de documentacion"
    modalRef.componentInstance.id = id
    modalRef.componentInstance.tabla = "tipo_documentacion"
    modalRef.componentInstance.borrado_exitoso.subscribe(async (borrado_exitoso: boolean) => {
      if (borrado_exitoso) {
        try {
          this._loader.notifyLoadChange(true);
          this.ngOnInit()
        } catch (e: any) {
          this._toast.error(e);
        } finally {
          this._loader.notifyLoadChange(false);
        }
      }
    })
  }
}
