import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LoadService } from 'src/app/services/helpers/load.service';
import { UsuariosService } from 'src/app/services/https/usuarios.services';

@Component({
  selector: 'app-modal-cambia-clave',
  templateUrl: './modal-cambia-clave.component.html',
  styleUrls: ['./modal-cambia-clave.component.scss'],
  providers: [UsuariosService]
})
export class ModalCambiaClaveComponent {
  @Input() dni!: string;

  constructor(
    public modal: NgbActiveModal,
    private _service: UsuariosService,
    private _toast: ToastrService,
    private _loader: LoadService
  ) {

  }


  async envia_cambio_clave() {
    try {
      this._loader.notifyLoadChange(true);
      await this._service.solicita_cambia_clave(this.dni);
      this._toast.success('Email para cambio de clave enviado')
      this.modal.dismiss('');
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }

  }
}
