import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbConfig, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfigGeneralModel } from 'src/app/models/entity/configuracion.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createConfigGenereal, createZona } from 'src/app/services/helpers/model.helper';
import { generateRandomName, timeoutTime } from 'src/app/services/helpers/utils.helper';
import { ConfiguracionService } from 'src/app/services/https/configuracion.services';
import { UploadServices } from 'src/app/services/https/upload.services';
import { globalState, tiposValidado } from 'src/global';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faEye, faPlus, faTrash
} from '@fortawesome/free-solid-svg-icons';
import { ZonasService } from 'src/app/services/https/zonas.services';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { ConfiguracionZonaModel } from 'src/app/models/entity/configuracion_zona.model';
import { IActualizaConfig } from 'src/app/models/interface/configuracion.interface';

@Component({
  selector: 'app-configuraciones-page',
  templateUrl: './configuraciones-page.component.html',
  styleUrls: ['./configuraciones-page.component.scss'],
  providers: [ConfiguracionService, UploadServices, ZonasService],
})
export class ConfiguracionesPageComponent implements OnInit {

  data: ConfigGeneralModel | null =  null
  // Iconos a mostrar
  verIcon: IconDefinition = faEye;
  eliminaIcon: IconDefinition = faTrash;
  addIcon: IconDefinition = faPlus;

  nombreMapa : string = '';
  urlZona : string = '';
  mapa_zona : string = '';

  showEmailCfg: boolean = false;
  evento_subida: any;
  fileName: string = '';
  zonas_lista: ZonasModel[] = [];
  zonasSeleccionadas: ZonasModel[] = [];
  confZonas: any[] = []
  archivo: File | null = null;
  cargando: boolean = true;

  constructor(
    private _service: ConfiguracionService,
    private _modalService: NgbModal,
    private _toast: ToastrService,
    private _zona_service: ZonasService,
    private _loader: LoadService,
  ) {

  }

  formGroup = new FormGroup({
    nombre: new FormControl<string>('', [Validators.required]),
    cif: new FormControl<string>('', [Validators.required]),
    domicilio: new FormControl<string>('', [Validators.required]),
    codigoPostal: new FormControl<string>('', [Validators.required]),
    localidad: new FormControl<string>('', [Validators.required]),
    provincia: new FormControl<string>('', [Validators.required]),
    web: new FormControl<string>('', [Validators.required]),
    email: new FormControl<string>('', [Validators.required]),
    telefono: new FormControl<string>('', [Validators.required]),
    registroMercantil: new FormControl<string>('', [Validators.required]),
    annioActual: new FormControl<number>(0, [Validators.required]),
    facturaSerie: new FormControl<string>("", [Validators.required]),
    consentimiento: new FormControl<string>('', [Validators.required]),
    condiciones: new FormControl<string>('', [Validators.required]),
    notificacionesEmail: new FormControl<string>('', [Validators.required]),
    notificacionesHost: new FormControl<string>('', [Validators.required]),
    notificacionesPuerto: new FormControl<number>(0, [Validators.required]),
    notificacionesUsuario: new FormControl<string>('', [Validators.required]),
    notificacionesPassword: new FormControl<string>('', [Validators.required]),
    zonasSeleccion: new FormControl<number>(0, []),
    mapa: new FormControl<string>('', []),
  });

  async ngOnInit(): Promise<void> {
    try {
      this.cargando = true;
      this._loader.notifyLoadChange(true);
      this.data = await this.obtener_datos_configuracion();
      this.zonas_lista = await this.obtener_zonas();
      const zonas_configuracion = await this.obtener_zonas_configuracion();
      this.zonasSeleccionadas = this.agrupar_zonas(zonas_configuracion, this.zonas_lista);
      this.actualiza_form(this.data);
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
      this.cargando = false;
    }
  }

  agrupar_zonas(lista: ConfiguracionZonaModel[], zonas: ZonasModel[]): ZonasModel[] {
    const data: ZonasModel[] = [];
    lista.forEach((item: ConfiguracionZonaModel) => {
      const zona = zonas.find((i) => i.id == item.zona_id)!!
      if (zona !== undefined) {
        data.push(zona)
      }
    });
    return data
  }

  async obtener_datos_configuracion(): Promise<ConfigGeneralModel> {
    return await this._service.ficha_nueva(1);
  }

  async obtener_zonas(): Promise<ZonasModel[]> {
    const { zonas } = await this._zona_service.lista_completa();
    return zonas;
  }

  async obtener_zonas_configuracion(): Promise<ConfiguracionZonaModel[]> {
    return await this._service.lista_zonas_nuevo();
  }

  async inserta_configuracion_zona() {
    const { zonasSeleccion } = this.formGroup.value;
    if (!zonasSeleccion || zonasSeleccion === 0) {
      this._toast.error("Debes seleccionar una zona antes de insertarla");
      return ;
    }
    try {
      this._loader.notifyLoadChange(true);
      const data = await this._service.inserta_configuracion_zona(zonasSeleccion);
      const nueva_zona = this.zonas_lista.find((i) => i.id == data.zona_id);
      if(nueva_zona !== undefined) {
        this.zonasSeleccionadas.push(nueva_zona);
      }
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);

    }
  }

  actualiza_form(updateInf: ConfigGeneralModel) {
    try {
      this.formGroup.patchValue({
        nombre: updateInf.nombre,
        cif: updateInf.cif,
        codigoPostal: updateInf.codigoPostal,
        localidad: updateInf.localidad,
        provincia: updateInf.provincia,
        web: updateInf.web,
        email: updateInf.email,
        telefono: updateInf.telefono,
        registroMercantil: updateInf.registroMercantil,
        annioActual: updateInf.annioActual,
        facturaSerie: updateInf.facturaSerie,
        consentimiento: updateInf.consentimiento,
        condiciones: updateInf.condiciones,
        notificacionesEmail: updateInf.notificacionesEmail,
        notificacionesHost: updateInf.notificacionesHost,
        notificacionesPuerto: updateInf.notificacionesPuerto,
        notificacionesUsuario: updateInf.notificacionesUsuario,
        notificacionesPassword: updateInf.notificacionesPassword,
        domicilio: updateInf.domicilio,
      });
      this.mapa_zona = updateInf.mapa
    } catch (e :any) {
      console.log(e.message);
    }
  }

  onSubmit() {
    const {
      nombre, cif, domicilio,
      codigoPostal, localidad,
      provincia, web, email,
      telefono, notificacionesEmail,
      notificacionesHost, notificacionesPassword,
      notificacionesPuerto, notificacionesUsuario,
      registroMercantil, annioActual,
      consentimiento, condiciones,
      mapa, facturaSerie
    } = this.formGroup.value;

    const actualiza_form: IActualizaConfig = {
      id: 1,
      nombre: nombre!!,
      cif: cif!!,
      domicilio: domicilio!!,
      codigopostal: codigoPostal!!,
      localidad: localidad!!,
      provincia: provincia!!,
      notificaciones_email: notificacionesEmail!!,
      notificaciones_host: notificacionesHost!!,
      notificaciones_puerto: notificacionesPuerto!!,
      notificaciones_usuario: notificacionesUsuario!!,
      notificaciones_password: notificacionesPassword!!,
      registro_mercantil: registroMercantil!!,
      annio_actual: annioActual!!,
      factura_serie: facturaSerie!!,
      consentimiento: consentimiento!!,
      condiciones: condiciones!!,
      mapa: mapa ? mapa : undefined,
      archivo: this.archivo ? this.archivo : undefined,
      web: web!!,
      email: email!!,
      telefono: telefono!!
    }
    this._service.actualiza_nuevo(actualiza_form)
    this.ngOnInit()
  }

  showContent(content: any, event: Event) {
     // Evita el comportamiento predeterminado del evento de clic
     // Así evito que se envíe el formulario al darle a enviar contenido
    event.preventDefault();
    this._modalService.open(content, { centered: true }).result.then(() => {});
  }

  async elimina_zona(zona_id: number, position: number) {
    try {
      this._loader.notifyLoadChange(true);
      const data = await this._service.elimina_zona_configuracion(zona_id);
      if (data) {
        this.zonasSeleccionadas = this.zonasSeleccionadas.filter((i) => i.id != zona_id)
        this.formGroup.patchValue({
          zonasSeleccion: 0
        })
      }
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  formatear_archivo(evento: any) {
    this.evento_subida = evento.target;
    try {
      const upload_event = this.evento_subida;
      // Comprobamos que exista algún archivo seleccionado en el evento
      if (upload_event.files.length > 0) {
        // Indicamos que se va a realizar una petición http (pantalla de carga)
        this._loader.notifyLoadChange(true);
        // Recorremos los archivos
        for (let i = 0; i < upload_event.files.length; i++) {
          // Se guarda extension
          const extension = upload_event.files[i].name
            .split('.')
            .slice(-1)[0];
          // Se guarda nombre de archivo
          const filename = `${generateRandomName()}.${extension}`;
          // Creamos un tipo archivo
          const file = new File([upload_event.files[i]], filename);
          // Almacenamos la imagen
          this.archivo = file;
        }
      }
    } catch (e: any) {
      this._loader.notifyLoadChange(false);
      this._toast.error('No ha sido posible subir el archivo.');
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }
}
