import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { ITipoDocu } from 'src/app/models/interface/tipoDocu.interface ';
import { IActualizaUsuario, IInsertaUsuario, IUsuario } from 'src/app/models/interface/usuario.interface';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createTipoDocu, createUsuario } from 'src/app/services/helpers/model.helper';
import { UsuariosService } from 'src/app/services/https/usuarios.services';
import { FormControl } from '@angular/forms';
import { globalState, tiposRole } from 'src/global';
import { UploadServices } from 'src/app/services/https/upload.services';
import { generateRandomName, timeoutTime } from 'src/app/services/helpers/utils.helper';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalCambiaClaveComponent } from 'src/app/components/modals/modal-cambia-clave/modal-cambia-clave.component';

@Component({
  selector: 'app-nuevo-edita-usuario',
  templateUrl: './nuevo-edita-usuario.component.html',
  styleUrls: ['./nuevo-edita-usuario.component.scss'],
  providers: [UsuariosService, UploadServices]
})
export class NuevoEditaUsuarioComponent {

  @Input() id!: number;
  @Input() es_nuevo!: boolean;
  @Input() data!: UsuarioModel | null;
  identity : any;
  idUsuario: number = 0;
  avatar: string ='';
  isNuevo: boolean = false;
  isLectura: boolean = false;
  role: number = 0;
  urlImg: string = `${globalState.urlImg}usuarios/`;
  uploadEvent: any;
  tiposRole : {id: number, descripcion: string}[] = tiposRole;

  // Iconos a mostrar
  keyIcon: IconDefinition = faKey;

  // Definir un validador personalizado que no hace nada (validación válida siempre)
  emptyValidator: Validators = (control: AbstractControl): { [key: string]: any } | null => {
    return null;
  };

  formGroup = new FormGroup({
    nombre: new FormControl<string>('', [Validators.required]),
    activo: new FormControl<boolean>(true, [Validators.required]),
    role: new FormControl<number>(0, [Validators.required]),
    dni: new FormControl<string>('', [Validators.required]),
    telefono: new FormControl<string>('', [Validators.required, Validators.pattern(/^\d{9}$/),]),
    email: new FormControl<string>('', [Validators.email]),
    password: new FormControl<string>('', this.isNuevo ? [] : []),
    localidad: new FormControl<string>('', [Validators.required]),
    provincia: new FormControl<string>('', [Validators.required]),
    codigoPostal: new FormControl<string>('', [Validators.required]),
    domicilio: new FormControl<string>('', [Validators.required]),
    observaciones: new FormControl<string>(''),
  });

  constructor(
    private _usuario_services: UsuariosService,
    private _uploadServices: UploadServices,
    private _route: Router,
    private _nav: Router,
    private _toast: ToastrService,
    private _loader: LoadService,
    private modalService: NgbModal,
  ) {

  }

  ngOnInit(): void {
    if (!this.es_nuevo && this.data) {
      this.rellena_formulario(this.data);
    }
  }


  rellena_formulario(updateInf: UsuarioModel) {
    this.formGroup.patchValue({
      nombre: updateInf.nombre,
      dni: updateInf.dni,
      telefono:updateInf.telefono,
      email: updateInf.email,
      role:updateInf.role,
      activo: updateInf.activo,
      password: updateInf.password,
      localidad: updateInf.localidad,
      provincia: updateInf.provincia,
      codigoPostal: updateInf.codigoPostal,
      domicilio:updateInf.domicilio,
    });
  }

  async onSubmit(){
    this._loader.notifyLoadChange(true);
    const { nombre, dni, telefono, email, role, activo, password, localidad, provincia, codigoPostal, domicilio, observaciones } = this.formGroup.value
    if (this.es_nuevo) {
      try {
        await this._usuario_services.crea_nuevo(new UsuarioModel(0, email!!, dni!!, nombre!!, activo!!, telefono!!, password!!, '', role!!, domicilio!!, codigoPostal!!, localidad!!, provincia!!, observaciones!!))
        this._route.navigate(['usuarios'])
      } catch (e: any) {
        console.log(e);
        this._toast.error(e);
      } finally {
        this._loader.notifyLoadChange(false);
      }
    } else {
      const edita_usuario: IActualizaUsuario = {
        email: email!!,
        dni: dni!!,
        nombre: nombre!!,
        activo: activo!!,
        telefono: telefono!!,
        avatar: '',
        role: role!!,
        domicilio: domicilio!!,
        codigoPostal: codigoPostal!!,
        localidad: localidad!!,
        provincia: provincia!!,
        observaciones: observaciones!!
      }
      try {
        this._loader.notifyLoadChange(true);
        const actualiza = this._usuario_services.actualiza_nuevo(this.id, edita_usuario)
        this._route.navigate(['usuarios'])
      } catch (e: any) {
        this._toast.error(e);
      } finally {
        this._loader.notifyLoadChange(false);
      }
    }
  }

  preparaImagen(event: any) {
    try {
      // Comprobamos que exista algún archivo seleccionado en el evento
      if(event.target.files.length > 0) {
        // Indicamos que se va a realizar una petición http (pantalla de carga)
        this._loader.notifyLoadChange(true);
        // Recorremos los archivos
        for(let i = 0; i < event.target.files.length; i++) {
          // Se guarda extension
          const extension = event.target.files[i].name.split('.').slice(-1)[0];
          // Se guarda nombre de archivo
          let fileName = `${generateRandomName()}.${extension}`;
          // Se genera datos del formulario
          const formData = new FormData();
          // Creamos un tipo archivo
          const file = new File([event.target.files[i]], fileName );
          // Insertamos en los datos
          formData.set('file', file);
          // Subida de imagenes
          this.subeImagen(this.id ?? 0, formData)
        }
      }
    } catch (e: any) {
      this._loader.notifyLoadChange(false);
      this._toast.error('No ha sido posible subir el archivo.');
    }
  }

  subeImagen(id: number, formData: FormData ) {
    this._uploadServices.subir(id, formData, 'usuarios').subscribe({
      next: (res) => {
        this._toast.success(res.data.message, 'Imagen subida con éxito');
        timeoutTime((1000)).then(()=> {
          this._loader.notifyLoadChange(false)
          window.location.reload();
        });
      },
      error: (err) => {
        timeoutTime((1000)).then(()=> {
          this._loader.notifyLoadChange(false)
          this._toast.error(err.error.message, 'Error');
        });
      },
      complete: () => {
        this._loader.notifyLoadChange(false);
      }
    })
  }


  abrir_modal_cambia_clave() {
    const modal_ref = this.modalService.open(ModalCambiaClaveComponent, {centered: true});
    modal_ref.componentInstance.dni = this.data!!.dni;
  }

  mailPassword(id : number) {
    this._loader.notifyLoadChange(true);
    this._usuario_services.mailPassword(this.id,this.formGroup.controls.email.value).subscribe({
      next: (res) => {

        this._toast.success(res.data.message, 'Solicitud enviada correctamente');

        timeoutTime((1000)).then(() => {
          this._loader.notifyLoadChange(false);
        })
      },
      error: (err) => {
        this._loader.notifyLoadChange(false);
        this._toast.error(err.error.message);
      },
      complete: () => {
        this._loader.notifyLoadChange(false);
      }
    })
  }

}
