import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentoModel } from 'src/app/models/entity/documento.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { DocumentosService } from 'src/app/services/https/documentos.services';
import { globalState } from 'src/global';
import { SafeHtml } from '@angular/platform-browser';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { ActualizaServices } from 'src/app/services/https/actualiza.services';

@Component({
  selector: 'app-documento-page',
  templateUrl: './documento-page.component.html',
  styleUrls: ['./documento-page.component.scss'],
  providers: [DocumentosService, ActualizaServices]
})
export class DocumentoPageComponent implements OnInit {
  id: number = 0;
  src: string = globalState.urlImg;
  file: string = '';
  roleAdmin: boolean = false;
  observaciones_usuario: SafeHtml = '';
  observaciones_admin: SafeHtml = '';
  tipo_archivo: string = "";

  faDownload: IconDefinition = faDownload;
  faVolver: IconDefinition = faArrowLeft;
  documento: DocumentoModel | null = null;
  archivo_data: string = "";
  volver: string = "";

  constructor(
    private _route: ActivatedRoute,
    private _service: DocumentosService,
    private _toast: ToastrService,
    private _loader: LoadService,
) {
    this._route.url.subscribe((url: Array<any>) => {
      this.id = url[url.length - 2].path
    })
  }

  ngOnInit(): void {
    if (this.id !== 0) {
      this.obten_documento(this.id);
      if (globalState.identity?.role === 2) {
        this.roleAdmin = true;
      }
    }
  }


  async obten_documento(id: number) {
    this._loader.notifyLoadChange(true);
    try {
      const { documento, archivo } = await this._service.ficha_nuevo(id);
      this.documento = documento
      this.observaciones_admin = documento.observaciones;
      this.observaciones_usuario = documento.observacionesUsuario;
      const es_pdf = documento.archivo.includes(".pdf")
      const tipo_archivo_array = documento.archivo.split(".")
      const tipo_archivo = tipo_archivo_array[tipo_archivo_array.length -1];
      this.volver = `/solicitudes/${this.documento.solicitud_id}`;
      if (es_pdf) {
        this.archivo_data = `data:application/pdf;base64,${archivo}`;
      } else {
        this.archivo_data = `data:image/${tipo_archivo};base64,${archivo}`
      }
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  obten_archivo() {
    const link = document.createElement('a');
    link.href = this.archivo_data;

    const tipo_archivo_array = this.archivo_data.split(';')[0].split('/');
    const extension = tipo_archivo_array[tipo_archivo_array.length - 1];
    link.download = `archivo.${extension}`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async actualiza_validado(validado: number) {
    try {
      this._loader.notifyLoadChange(true)
      const data = await this._service.actualiza_validado(this.id, validado)
      this._toast.success('Información del documento actualizada')
      this.ngOnInit();
      // this.obten_documento(this.id)
    } catch (e: any) {
      this._toast.error(e)
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }
}
