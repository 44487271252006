<form class="form-container" (ngSubmit)="onSubmit()" #zonaForm="ngForm">
  <div class="form-header mt-4">
    <h6 *ngIf="id === 0" class="mb-0">Registro de calle</h6>
    <h6 *ngIf="id !== 0" class="mb-0">Actualización de calle</h6>
    <!--<div class="form-check form-switch form-check">
      <input
        #activo
        name="activo"
        class="form-check-input"
        type="checkbox"
        ngModel="data.activo"
        id="activo"
      >
       <label *ngIf="data.activo" class="form-check-label" for="activo">Activo</label>
      <label *ngIf="!data.activo" class="form-check-label" for="activo">Inactivo</label>
    </div>-->

  </div>
  <div class="form-body-three-cols mt-3">
    <app-input
      class="mt-3"
      [control]="formGroup.controls.nombre"
      [label]="'Nombre *'"
      [type]="'text'"
      [placeholder]="'Ej: Canal de San Diego'"
      [id]="'nombre'">
    </app-input>
    <div class="col mx-auto mt-3">
        <app-select-input
          [optionData]="zonas"
          [optionsLabels]="['id','nombre']"
          [defaultSelectText]="'Selecciona zona'"
          [defaultSelectValue]="0"
          [control]="formGroup.controls.zonaId"
          [name]="'select-documentacion'"
        />

      <app-input
        [control]="formGroup.controls.zonaId"
        [type]="'hidden'">
      </app-input>
    </div>

  </div>
  <div class="d-flex gap-3 justify-content-start">
    <button
      type="submit"
      class="btn btn-aceptar mt-5"
      [disabled]="!formGroup.valid || formGroup.controls.zonaId.value === 0"
      >
      <span  *ngIf="id === 0">
        Crear
      </span>
      <span  *ngIf="id !== 0">
        Actualiza
      </span>
    </button>
    <a [routerLink]="'/zonas/' + id_zona" class="btn btn-outline-error mt-5" >
      Cancelar
    </a>
  </div>
</form>
