import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent {
  list: any[] = [];
  temp_list: any[] = [];
  keyword: string = "";
  _img: string = '';
  _label: string = '';
  _uid: string = '';

  @Output() afterChange = new EventEmitter<any>();
  @ViewChild("input", { static: false }) input!: ElementRef<HTMLInputElement>;

  @Input("size") size?: string;
  @Input("items") set items(value: any[]) {
    this.list = value;
    this.temp_list = value;
  }
  @Input("img") img?: string;
  @Input("label") label?: string;
  @Input("uid") uid?: string;

  onChange: (value: any) => void = () => {};
  onTouch: () => void = () => {};

  value: string = "Selecciona ...";
  shown: boolean = false;

  constructor(private ele: ElementRef) {}

  ngOnChanges(): void {
    this._label = this.label ?? 'name';
    this._img = this.img ?? 'img';
    this._uid = this.uid ?? 'id';
    this.value = 'Selecciona ...';
  }

  writeValue(value: any): void {
    if (value) {
      this.temp_list.forEach(x => {
        if (x[this._uid] === value) {
          this.value = x[this._label];
        }
      });
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  search(e: string): void {
    const val = e.toLowerCase();
    this.list = this.temp_list.filter(x =>
      x[this._label].toLowerCase().includes(val) || !val
    );
  }

  select(item: any): void {
    this.onChange(item[this._label]);
    this.value = item[this._label];
    this.shown = false;
    this.afterChange.emit(item);
  }

  show(): void {
    this.shown = !this.shown;
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 200);
  }

  @HostListener("document:click", ["$event"]) onClick(e: MouseEvent): void {
    if (!this.ele.nativeElement.contains(e.target)) {
      this.shown = false;
    }
  }
}
