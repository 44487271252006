<div
  class="header-bar bg-secondary py-2 px-4 mt-3"
  style="--bs-bg-opacity: 0.1; border-radius: 5px; border: 1px solid #333"
>
  <div class="row">
    <div class="col">
      <p><strong> Solicitud: {{data.annio}}/{{ data.id }} </strong></p>
      <p>Nombre: <strong> {{ data.usuario_nombre }} </strong> </p>
      <p class="mb-0">DNI/NIE:<strong>  {{ data.usuario_dni }}</strong></p>
      <p class="mb-0">Teléfono:<strong>{{ data.usuario_telefono }}</strong></p>
    </div>
    <div class="col">
      <div class="col-center__superior gap-3">
        <p *ngIf="es_administrador"
          class="badge badge-estado user-select-none center-text"
          [ngStyle]="{
            'background-color':
              data.estado === 1
                ? '#007a93'
                : data.estado === 2
                ? '#ffc107'
                : data.estado === 3
                ? '#198754'
                : data.estado === 4
                ? '#dc3545'
                : 'black'
          }"
        >
          {{ estado_texto }}
        </p>
      </div>
      <div class="col-center__inferior" *ngIf="es_administrador">
        <ng-container *ngIf="data.estado === 1">
          <button class="btn btn-warning" (click)="cambia_estado(2)">Validar</button>
          <button class="btn btn-danger" (click)="cambia_estado(4)">Rechazar</button>
        </ng-container>
        <ng-container *ngIf="data.estado === 2">
          <button class="btn btn-success" (click)="cambia_estado(3)">Pagar</button>
          <button class="btn btn-danger" (click)="cambia_estado(4)">Rechazar</button>
        </ng-container>
        <ng-container *ngIf="data.estado === 3">
          <a [routerLink]="(['/tarjetas/' + data.tarjeta_id])" class="btn btn-outline-primary">Tarjeta &nbsp;<span *ngIf="data.tarjeta_numero > 0"> {{data.tarjeta_numero}}</span></a>
          <button class="btn btn-outline-danger" (click)="cambia_estado(2)">Quitar pago</button>

        </ng-container>
        <ng-container *ngIf="data.estado === 4">
          <button class="btn btn-outline-warning" (click)="cambia_estado(1)">Pend. Documentacion</button>
        </ng-container>
      </div>
    </div>
    <div class="col text-end">
      <div class="row justify-content-end">
        <button *ngIf="data.estado === 3" class="col-1 btn btn-outline-primary me-1" (click)="datos_tarjeta(data.tarjeta_id)"><fa-icon [icon]="icono_tarjeta"/></button>
        <button *ngIf="data.estado === 3" class="col-1 btn btn-outline-primary me-1" (click)="datos_factura(data.id)"><fa-icon [icon]="icono_factura" /></button>
        <button *ngIf="data.estado === 3" class="col-1 btn btn-outline-primary" (click)="datos_tarjeta_factura(data.tarjeta_id, data.id)"><fa-icon [icon]="icono_tar_fac" /></button>
      </div>
      <div class="row justify-content-end">
        <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">Factura: </div>
        <div class="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3"><span *ngIf="data.factura_numero > 0"><strong>{{data.factura_numero}}/{{data.factura_serie}}</strong></span></div>
      </div>
      <div class="row justify-content-end">
        <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">Precio: </div>
        <div class="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3"><span ><strong>{{data.importe| number : '1.2'}} €</strong></span></div>
      </div>
      <div class="row justify-content-end">
        <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">IVA: </div>
        <div class="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3"><span ><strong>{{data.iva| number : '1.2'}} €</strong></span></div>
      </div>
      <div class="row justify-content-end">
        <div class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">Total: </div>
        <div class="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3"><span ><strong>{{data.total| number : '1.2'}} €</strong></span></div>
      </div>

      <!--<p class="mb-0">Factura: <span *ngIf="data.facturaNumero > 0"><strong>{{data.facturaNumero}}/{{data.facturaSerie}}</strong></span></p>
      <p class="mb-0">
        Precio:
        <strong>
          {{data.importe}} €
        </strong>
      </p>
      <p class="mb-0">
        IVA:
        <strong>
          {{data.iva}} €
        </strong>
      </p>
      <p class="mb-">
        Total:
        <strong>
          {{data.total}} €
        </strong>
      </p>-->
    </div>
  </div>
  <div class="row w-100" style="border-top: 1px solid #333;">
    <div class="col">
      <p class="mt-2 mb-0">
        F. Solicitud: <strong>{{data.fechasolicitud | date : 'dd/MM/YYYY'}}</strong>
      </p>
    </div>
    <div class="col">
      <p class="mt-2 mb-0" *ngIf="es_administrador">
        Tramita: <strong>{{data.tramita_nombre}}</strong>
      </p>
    </div>
    <div class="col text-center">
      <p class="badge badge-success mt-2 mb-0" *ngIf="data.fechaaceptacion !== null">
        F. Aceptacion: {{ data.fechaaceptacion }}
      </p>
      <p class="badge badge-error mt-2 mb-0" *ngIf="data.fechadenegacion !== null">
        F. Denegacion: {{ data.fechadenegacion }}
      </p>
    </div>
    <div class="col">
      <p class="mt-2 mb-0 text-end">
        Doc. Pendientes:
        <span class="badge badge-success"  *ngIf="data.documentosPendientes == 0">{{ data.documentosPendientes }}</span>
        <span class="badge badge-error"  *ngIf="data.documentosPendientes > 0">{{ data.documentosPendientes }}</span>
         / {{ data.documentosCorrectos + data.documentosPendientes }}
      </p>
    </div>
  </div>
</div>
