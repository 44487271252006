import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TipoClienteModel } from 'src/app/models/entity/tipoCliente.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createTipoClient, createZona } from 'src/app/services/helpers/model.helper';
import { TipoClienteService } from 'src/app/services/https/tipoCliente.services';
import { TipoClienteDocumentacionComponent } from '../tipo-cliente-documentacion/tipo-cliente-documentacion.component';
import { TableDefaultComponent } from 'src/app/components/common/table-default/table-default.component';

@Component({
  selector: 'app-nuevo-edita-tipo-cliente',
  templateUrl: './nuevo-edita-tipo-cliente.component.html',
  styleUrls: ['./nuevo-edita-tipo-cliente.component.scss'],
  providers: [TipoClienteService]
})
export class NuevoEditaTipoClienteComponent {
  id: number = 0;
  es_nuevo: boolean = true;
  titulo = 'Tipo de Cliente';
  tipo_cliente: TipoClienteModel = new TipoClienteModel(0,'',true,'');
  formGroup = new FormGroup({
    nombre: new FormControl<string>("", [Validators.required]),
    color: new FormControl<string>("", [Validators.required]),
  })
  tipoDocu: any = TipoClienteDocumentacionComponent;
  table: any = TableDefaultComponent;

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _service: TipoClienteService,
    private _toast: ToastrService,
    private _loader: LoadService,
    ){
    this._route.params.subscribe((params: Params) => {
      // Accede al valor del parámetro y asígnalo a la variable typeConfig
      if (params['slug'] !== undefined) {
        this.es_nuevo = params['slug'] === 'nuevo' ? true : false;
        this.id = Number(params['slug'])
      }
    });
  }

  async ngOnInit() {
    try {
      if(!this.es_nuevo) {
        this.tipo_cliente = await this.obten_tipo_cliente(this.id);
        this.actualizaFormGroup(this.tipo_cliente);
      }
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  async obten_tipo_cliente(id: number): Promise<TipoClienteModel>{
    try {
      return await this._service.ficha_nueva(id);
    } catch (e) {
      return Promise.reject(e)
    }
  }

  actualizaFormGroup(zonaUpdated: TipoClienteModel) {
    this.formGroup.patchValue({
      nombre: zonaUpdated.nombre,
      color: zonaUpdated.color,
    })
  }

  onSubmit() {
    if (this.es_nuevo) {
      this.crea();
    } else {
      this.actualiza();
    }
  }

  async crea() {
    const nuevo_tipo_cliente = new TipoClienteModel(
      this.id,
      this.formGroup.value.nombre ?? '',
      this.tipo_cliente.activo,
      this.formGroup.value.color === '' ? '#000000' : this.formGroup.value.color ?? '#000000',
    )
    try {
      this._loader.notifyLoadChange(true)
      const data = await this._service.crea_nuevo(nuevo_tipo_cliente)
      this._nav.navigate(['/tipo-cliente/' + data.id]);
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  async actualiza() {
    const actualiza_cliente = new TipoClienteModel(
      this.id,
      this.formGroup.value.nombre ?? '',
      this.tipo_cliente.activo,
      this.formGroup.value.color === '' ? '#000000' : this.formGroup.value.color ?? '#000000',
    )
    try {
      this._loader.notifyLoadChange(true)
      this.tipo_cliente = await this._service.actualiza_nuevo(actualiza_cliente, this.id)
      this.actualizaFormGroup(this.tipo_cliente);
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  toggle_activo(activo: any) {
    this.tipo_cliente.activo = !activo;
    this.actualiza();
  }
}
