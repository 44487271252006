import { Component } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createTarjetaVista, createZona } from 'src/app/services/helpers/model.helper';
import { timeoutTime } from 'src/app/services/helpers/utils.helper';
import { TarjetasServices } from 'src/app/services/https/tarjetas.service';
import { ZonasService } from 'src/app/services/https/zonas.services';
import { ConfiguracionService } from 'src/app/services/https/configuracion.services';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { FiltroModel } from 'src/app/models/entity/filtro.model';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { TarjetaView } from 'src/app/models/entity/views/tarjetas.view';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BorradoModalComponent } from 'src/app/components/common/borrado-modal/borrado-modal.component';

@Component({
  selector: 'app-tarjeta-page',
  templateUrl: './tarjeta-page.component.html',
  styleUrls: ['./tarjeta-page.component.scss'],
  providers: [
    TarjetasServices,
    ConfiguracionService,
    ZonasService
  ]
})
export class TarjetaPageComponent {
  usuario: UsuarioModel | undefined = globalState.identity;
  tarjetas: TarjetaView[] = [];
  zonas: ZonasModel[] = [];
  typeForm: string = '';
  filtro: string = '';
  filtro_dinamico: string = '';
  filtros_dinamicos_principales: any[] = [];
  id_seleccionado: number = 0;
  pagina_actual = 1;
  total_paginas = 0;
  registros_pagina: number = globalState.registrosPagina;
  datos_tarjeta: any = null;
  datos_factura: any = null;
  timeout: any;

  // Impresion de documentos
  imprimir_tarjeta: boolean = false;
  imprimir_factura: boolean = false;
  constructor(
    private _tar_service: TarjetasServices,
    private _zonas_service: ZonasService,
    private _toast: ToastrService,
    private _loader: LoadService,
    private _router: ActivatedRoute,
  ) {
    this._router.params.subscribe((params: Params) => {
      // Accede al valor del parámetro y asígnalo a la variable typeConfig
      if (params['slug'] !== undefined) {
        this.typeForm = params['slug'];
        if (!isNaN(Number(this.typeForm))) {
          this.id_seleccionado = Number(this.typeForm);
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this._loader.notifyLoadChange(true)
    if (this.typeForm === "") {
      try {
        const filtro = this.obten_filtro_tarjetas(this.filtro, this.filtro_dinamico);
        const { tarjetas, pagina_actual, total_paginas } = await this.obtener_tarjetas(filtro);
        this.tarjetas = tarjetas;
        this.pagina_actual = pagina_actual;
        this.total_paginas = total_paginas;
        const { zonas } = await this.obtener_zonas();
        this.zonas = zonas;
      } catch (e: any) {
        this._toast.error(e);
      } finally {
        this._loader.notifyLoadChange(false)
        if (this.zonas.length > 0) {
          this.filtros_dinamicos_principales.push(
            new FiltroModel(
              0, 'zona-filtro', 'id', 'Filtrar por zonas', 'Todas las zonas', 0, 'nombre', 'zona_id', this.zonas, 0
            ));
        }
      }
    }
  }

  async obtener_tarjetas(filtro: string): Promise<{ tarjetas: TarjetaView[], pagina_actual: number, total_paginas: number }> {
    try {
      return await this._tar_service.lista_paginacion_nueva(this.pagina_actual, this.registros_pagina, filtro);
    } catch (e: any) {
      return e;
    }
  }

  obten_filtro_tarjetas(filtro_tabla: string, filtro_dinamico: string): string {
    let filtro = '';
    // Si el role es de usuario solo debe ver sus solicitudes
    if (this.usuario!!.role < 1) {
      filtro += `where (usuario_id = '${this.usuario!!.id}')`;
    }

    // Se pasa parametros de annio para reducir el tiempo de la petición
    if (globalState.annioActual > 0) {
      filtro = filtro == '' ? ' where ' : ' and ';
      filtro += `(annio = '${globalState.annioActual}')`;
    }
    // Filtro del cuadro de busqueda
    if (filtro_tabla != '') {
      filtro += filtro == '' ? ' where ' : ' and ';
      filtro += `((INSTR(nombre, '${filtro_tabla}') > 0) OR (INSTR(dni, '${filtro_tabla}') > 0) OR (INSTR(matricula, '${filtro_tabla}') > 0) OR (INSTR(numero, '${filtro_tabla}') > 0) OR (INSTR(destinatario, '${filtro_tabla}') > 0) OR (INSTR(domicilio, '${filtro_tabla}') > 0))`;
    }
    if (filtro_dinamico != '') {
      filtro += filtro == '' ? ' where ' : ' and ';
      filtro += filtro_dinamico;
    }
    return filtro;
  }

  async obtener_zonas(): Promise<{ zonas: ZonasModel[] }> {
    try {
      return await this._zonas_service.lista_completa();
    } catch (e: any) {
      return e;
    }
  }

  async recibe_filtro(value: { filtro_texto: string, filtro_dinamico: string }) {
    const filtro = this.obten_filtro_tarjetas(value.filtro_texto, value.filtro_dinamico);
    this.filtro = filtro;
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(async () => {
      this._loader.notifyLoadChange(true);
      try {
        const { tarjetas, pagina_actual, total_paginas } = await this.obtener_tarjetas(filtro);
        this.tarjetas = tarjetas;
        this.pagina_actual = pagina_actual;
        this.total_paginas = total_paginas;
      } catch (e: any) {
        this._toast.error(e)
      } finally {
        this._loader.notifyLoadChange(false)
      }
    })
  }

  async recibe_pagina(value: number) {
    this.pagina_actual = value;
    this._loader.notifyLoadChange(true);
    try {
      const { tarjetas, pagina_actual, total_paginas } = await this.obtener_tarjetas(this.filtro);
      this.tarjetas = tarjetas;
      this.pagina_actual = pagina_actual;
      this.total_paginas = total_paginas;
    } catch (e: any) {
      this._toast.error(e)
    } finally {
      this._loader.notifyLoadChange(false)
    }
  }

  async recibir_ambos(datos_tarjeta: TarjetaView) {
    try {
      this._loader.notifyLoadChange(true);
      const datos_impresion = {
        id: datos_tarjeta.id,
        annio: datos_tarjeta.annio,
        numero: datos_tarjeta.numero,
        zona_id: datos_tarjeta.zonaId,
        zonaAbreviatura: datos_tarjeta.zonaAbreviatura,
        matricula: datos_tarjeta.matricula,
        color: datos_tarjeta.zonaColor
      }
      const datos_final = {datos_tarjeta: datos_impresion, datos_factura: {id: datos_tarjeta.solicitudId} };
      this.datos_factura = datos_final.datos_factura
      this.datos_tarjeta = datos_final.datos_tarjeta;
      timeoutTime(500).then(() => {
        this._loader.notifyLoadChange(false);
        this.imprimir_tarjeta = true;
        timeoutTime(5).then(() => {
          this.datos_factura = null;
          this.datos_tarjeta = null;
        });
    });
    this.imprimir_tarjeta = false;
    this.imprimir_factura = false;
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  /*
  typeForm: string = '';
  allData: any[] = [];
  zonasData: ZonasModel[] = [];
  imprimirTarjeta: boolean = false;
  datosTarjeta: any = null;
  idUsuario: number = 0;
  role: number = 0;
  pagina: number = 1;
  registros: number = globalState.registrosPagina;
  totalRegistros: number = 0;
  filtro : string = '';
  filtroDinamico: string = '';
  outFiltro : string = '';
  filtros: any[] = [];
  annioActual: number = 0;

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _service: TarjetasServices,
    private _zonasServices: ZonasService,
    private _configuracion: ConfiguracionService,
    private _loader: LoadService,
    ){
      if (globalState.identity) {
        this.role = globalState.identity.role;
        this.idUsuario = globalState.identity.id;
      }


      this._route.params.subscribe((params: Params) => {
      // Accede al valor del parámetro y asígnalo a la variable typeConfig
      if (params['slug'] !== undefined) {
        this.typeForm = params['slug'];
      }
    });
    this.annioActual =  globalState.annioActual;
    this.obtenZonas();
  }

  ngOnInit(){
    if((this.typeForm === '') && (this.annioActual > 0)) {
      this.obtainData();
    }
  }

  ngDoCheck(){
    if((globalState.annioActual != this.annioActual) && (globalState.annioActual > 0)) {
      this.annioActual = globalState.annioActual;
      this.obtainData();
    }
  }

  getFiltro(tablaFiltro: string = '', dinamicFilter: string = '') {
    let filtro = '';

    if (this.role < 1) {
      filtro += `where (usuario_id = ${this.idUsuario})`;
    }

    if (this.annioActual > 0) {
      filtro += filtro == '' ? " where " : " and ";

      filtro += `(annio = ${this.annioActual})`
    }

    if (tablaFiltro != '') {
      filtro += filtro == '' ? " where " : " and ";

      filtro += " ((nombre like '%"+tablaFiltro+"%') or "+
                        "(dni like '%"+tablaFiltro+"%') or"+
                        "(matricula like '%"+tablaFiltro+"%') or"+
                        "(numero like '%"+tablaFiltro+"%') or"+
                        "(destinatario like '%"+tablaFiltro+"%'))";
    }

    // Filtro dinamico para el array de filtroDinamicoPr
    if (dinamicFilter != '') {
      filtro += filtro == '' ? ' where ' : ' and '
      filtro += dinamicFilter
    }
    return filtro;
  }

  obtainData() {
    if (this.filtro == '') {
        this._loader.notifyLoadChange(true);
    }

    this.allData = [];
    let nuevoFiltro = this.getFiltro(this.filtro, this.filtroDinamico);
    this.outFiltro = nuevoFiltro;

    this._service.listapag(this.pagina,this.registros,nuevoFiltro).subscribe({
      next: (res) => {

        res.data.forEach((element: any) => {
          this.allData.push(
              createTarjetaVista(element)
            );
        });

        this._loader.notifyLoadChange(false);
      },
      error: (err) => {
        console.log(err.error);
        this._loader.notifyLoadChange(false);
      },
      complete: () => {
        this._loader.notifyLoadChange(false);
      }
    })
  }

  // Se obtienen las zonas
  obtenZonas() {
    this._zonasServices.lista().subscribe({
      next: (res) => {
        if (res.data.length > 0) {
          res.data.forEach((element: any) => {
            this.zonasData.push(createZona(element));
          });
        }
      },
      error: (err) => {
      },
      complete: () => {
        this.filtros.push(
          new FiltroModel(
            0,'zona-filtro','id','Filtrar por zonas', 'Todas las zonas', 0,'nombre','zona_id',this.zonasData,0
          ));
      },
    });
  }

  recibirDatos(datos: any) {
    this._loader.notifyLoadChange(true);
    this.datosTarjeta = datos;
    timeoutTime((500)).then(() => {
      this._loader.notifyLoadChange(false);
      this.imprimirTarjeta = true;
    });
    this.imprimirTarjeta = false;
  }

  recibePagina(pagina: number) {
    this.pagina = pagina;
    this.obtainData();
  }

  recibeFiltro(filtro: [string, string]) {
    this.filtro = filtro[0];
    this.filtroDinamico = filtro[1];
    this.obtainData();
  }
    */
}
