<app-page-container-publico [title]="'Usuarios'">
  <div class="container-fluid">
    <ng-container *ngIf="es_listado && cargando === false">
      <app-table-default
        [data]=data
        [columnas_tabla]="['nombre', 'email', 'dni', 'activo']"
        [nombre_columnas]="['Nombre', 'Email', 'DNI/CIF', 'Activo']"
        [tipo_dato_tabla]="['','','','boolean']"
        [alineacion_columnas]="['start','start','centar','']"
        [ancho_columnas]="[]"
        [ver_boton_editar]="true"
        [ver_boton_borrar]="false"
        [tiene_paginacion]="true"
        [pagina_actual]="pagina_actual"
        [total_paginas]="total_paginas"
        (envia_pagina)="recibe_pagina($event)"
        (envia_filtro)="recibe_filtro($event)"></app-table-default>

    </ng-container>
    <!-- <app-edita-mi-usuario *ngIf="typeForm==='mi-usuario'" /> -->
    <app-nuevo-edita-usuario
      *ngIf="!es_listado && !cargando"
      [data]="usuario_nuevo"
      [id]="id"
      [es_nuevo]="id === 0" />
  </div>
</app-page-container-publico>
