import { Component, EventEmitter, Input, Output,SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsuariosService } from 'src/app/services/https/usuarios.services';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createUsuario } from 'src/app/services/helpers/model.helper';
import { TarjetasServices } from 'src/app/services/https/tarjetas.service';
import { globalState } from 'src/global';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight
} from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-search-usuario',
  templateUrl: './modal-search-usuario.component.html',
  styleUrls: ['./modal-search-usuario.component.scss'],
  providers: [UsuariosService]
})
export class ModalSearchUsuarioComponent {
   // Parametros paginacion
   @Output() envia_usuario: EventEmitter<UsuarioModel> = new EventEmitter();

  usuarios: UsuarioModel[] = [];

  texto_filtro: string = '';
  total_paginas : number = 1;

  totalUsuarios : number = 0;
  pagina_actual : number = 1;
  registros_pagina : number = globalState.registrosPagina;


  // Iconos a mostrar
  faAngleLeft : IconDefinition = faAngleLeft;
  faAngleRight : IconDefinition = faAngleRight
  faAnglesLeft : IconDefinition = faAnglesLeft;
  faAnglesRight : IconDefinition = faAnglesRight;

  constructor(
    private _service: UsuariosService,
    private _loader: LoadService,
    private _toastr: ToastrService,
    public active_modal: NgbActiveModal,
  ) {

  }

  async ngOnInit() {
    await this.obten_usuarios_paginacion();
  }

  async obten_usuarios_paginacion() {
    try {
      const filtro = this.texto_filtro !== "" ? this.get_filtro_usuario(this.texto_filtro) : "";
      const data = await this._service.lista_paginacion(this.pagina_actual, this.registros_pagina, filtro)
      this.total_paginas = data.total_paginas;
      this.pagina_actual = data.pagina_actual;
      this.usuarios = data.usuarios;
    } catch (e: any) {
      this._toastr.error(e);
      this.usuarios = [];
    }
  }

  get_filtro_usuario(tabla_filtro: string = ""): string {
    return `where (INSTR(nombre, '${tabla_filtro}') or INSTR(dni, '${tabla_filtro}'))`;
  }


  recibePagina(pagina: number) {
    if (pagina > 0) {
      this.pagina_actual = pagina;
    } else {
      this.pagina_actual = 1;
    }
    this.obten_usuarios_paginacion();

  }

  selectUsuario(usuario: UsuarioModel) {
    this.envia_usuario.emit(usuario);
  }


}
