
<div class="form-group ">
    <label for="{{id}}">{{ label }}</label>
    <textarea
        class="form-control"
        [id]="id"
        [class.invalid]="control.invalid && control.dirty && control.touched"
        [formControl]="control"
        rows="7"></textarea>
  </div>

<ng-container class="position-relative" *ngFor="let err of control.errors | keyvalue">
<div class="position-absolute error-message" [ngClass]="{'not-show' : !control.touched && control.invalid}">
    <p>
    {{errorMessages[err.key]}}
    </p>
</div>
</ng-container>
