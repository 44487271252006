<form class="form-container" (ngSubmit)="onSubmit()" #zonaForm="ngForm">
  <div class="form-header mt-4">
    <h6 *ngIf="es_nuevo" class="mb-0">Registro de usuario</h6>
    <h6 *ngIf="!es_nuevo" class="mb-0">Actualización de usuario</h6>
    <app-reverse-switch
      [label]="'Activo'"
      [control]="formGroup.controls.activo" />

    <div *ngIf="!es_nuevo" class="bg-primary text-white cursor p-2 rounded"
      (click)="abrir_modal_cambia_clave()">
      <fa-icon [icon]="keyIcon"></fa-icon>
      <span class="ms-2">Resetear</span>
    </div>

  </div>
  <div class="form-body-three-cols mt-3">
    <div class="two-cols mt-3">
      <app-input
        [control]="formGroup.controls.nombre"
        [label]="'Nombre completo*'"
        [type]="'text'"
        [id]="'nombre'" />
    </div>
    <app-input
      [control]="formGroup.controls.dni"
      class="mt-3"
      [label]="'DNI/NIE*'"
      [type]="'text'"
      [id]="'dni'" />
    <app-input
      [control]="formGroup.controls.email"
      class="mt-3"
      [label]="'Email'"
      [ngClass]="{ 'two-cols': !es_nuevo }"
      [type]="'email'"
      [id]="'email'" />
    <app-input
      [control]="formGroup.controls.telefono"
      class="mt-3"
      [label]="'Telefono*'"
      [type]="'tel'"
      [id]="'telefono'" />
    <app-input
      *ngIf="es_nuevo"
      [control]="formGroup.controls.password"
      class="mt-3"
      [label]="'Password'"
      [type]="'password'"
      [id]="'password'" />
    <app-input
      [control]="formGroup.controls.codigoPostal"
      class="mt-3"
      [label]="'Código Postal*'"
      [type]="'number'"
      [id]="'codigoPostal'" />
    <app-input
      [control]="formGroup.controls.localidad"
      class="mt-3"
      [label]="'Localidad*'"
      [type]="'text'"
      [id]="'localidad'" />
    <app-input
      [control]="formGroup.controls.provincia"
      class="mt-3"
      [label]="'Provincia*'"
      [type]="'text'"
      [id]="'provincia'" />
    <div class="full-width mt-3">
      <app-input
        [control]="formGroup.controls.domicilio"
        [label]="'Domicilio*'"
        [type]="'text'"
        [id]="'domicilio'" />
    </div>

    <div class="full-width mt-3">
      <app-select-input
        *ngIf="!isLectura"
        [label]="'Role*'"
        style="display:block;width:80%;"
        [optionData]="tiposRole"
        [optionsLabels]="['id', 'descripcion']"
        [defaultSelectText]="''"
        [control]="formGroup.controls.role"
        [name]="'select-role'"
        [id]="'select-role'" />
      <label for="roleLectura" *ngIf="isLectura">Role</label>
      <input
        *ngIf="isLectura"
        id="roleLectura"
        type="text"
        class="form-control"
        value="{{tiposRole[role].descripcion}}"
        readonly>
    </div>
  </div>

  <div class="d-flex gap-3 justify-content-start">
    <button
      type="submit"
      class="btn btn-aceptar mt-5"
      [disabled]="!formGroup.valid">
      <span *ngIf="es_nuevo"> Crear </span>
      <span *ngIf="!es_nuevo"> Actualiza </span>
    </button>
    <a
      [routerLink]="'/usuarios'"
      class="btn btn-outline-error mt-5">
      Cancelar
    </a>
  </div>
</form>
