import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioPageComponent } from './pages/publico/inicio-page/inicio-page.component';
import { PageContainerPublicoComponent } from './components/publico/page-container-publico/page-container-publico.component';
import { HeaderContainerComponent } from './components/publico/header/header-container/header-container.component';
import { HeaderTopComponent } from './components/common/header-top/header-top.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderNavPublicoComponent } from './components/publico/header/header-nav-publico/header-nav-publico.component';
import { SliderComponent } from './components/common/slider/slider.component';
import { LoginPageComponent } from './pages/login/login-page/login-page.component';
import { LoginFormComponent } from './pages/login/components/login-form/login-form.component';
import { InputComponent } from './components/common/forms/input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomHttpInterceptor } from './services/https/http-interceptor';
import { LoadingComponentComponent } from './components/common/loading-component/loading-component.component';
import { ToastrModule } from 'ngx-toastr';
import { RegistroPageComponent } from './pages/publico/registro-page/registro-page.component';
import { RegistroFormComponent } from './pages/publico/registro-page/components/registro-form/registro-form.component';
import { AvisoEmailComponent } from './pages/publico/aviso-email/aviso-email.component';
import { BoxMensajeComponent } from './components/box-mensaje/box-mensaje.component';
import { UsuarioOptionsComponent } from './components/privado/header/usuario-options/usuario-options.component';
import { AdminOptionsComponent } from './components/privado/header/admin-options/admin-options.component';
import { SuperOptionsComponent } from './components/privado/header/super-options/super-options.component';
import { GeneralOptionsComponent } from './components/privado/header/general-options/general-options.component';
import { ZonasPageComponent } from './pages/privado/zonas/zonas-page/zonas-page.component';
import { TableDefaultComponent } from './components/common/table-default/table-default.component';
import { CommonModule } from '@angular/common';
import { NuevoEditaZonaComponent } from './pages/privado/zonas/components/nuevo-edita-zona/nuevo-edita-zona.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TinyEditorComponent } from './components/common/forms/tiny-editor/tiny-editor.component';
import { ColorPickerComponent } from './components/common/forms/color-picker/color-picker.component';
import { TipoClientePageComponent } from './pages/privado/tipo-cliente/tipo-cliente-page/tipo-cliente-page.component';
import { NuevoEditaTipoClienteComponent } from './pages/privado/tipo-cliente/components/nuevo-edita-tipo-cliente/nuevo-edita-tipo-cliente.component';
import { TipoDocumentacionPageComponent } from './pages/privado/tipo-documentacion/tipo-documentacion-page/tipo-documentacion-page.component';
import { NuevoEditaTipoDocumentacionComponent } from './pages/privado/tipo-documentacion/components/nuevo-edita-tipo-documentacion/nuevo-edita-tipo-documentacion.component';
import { ReverseSwitchComponent } from './components/common/forms/reverse-switch/reverse-switch.component';
import { LinealComponentSelectorComponent } from './components/common/lineal-component-selector/lineal-component-selector.component';
import { DynamicComponentDirective } from './directives/dynamic-component';
import { TipoClienteDocumentacionComponent } from './pages/privado/tipo-cliente/components/tipo-cliente-documentacion/tipo-cliente-documentacion.component';
import { NuevoEditaDocuClienteComponent } from './pages/privado/tipo-cliente-documentacion/components/nuevo-edita-docu-cliente/nuevo-edita-docu-cliente.component';
import { TipoClienteDocumentacionPageComponent } from './pages/privado/tipo-cliente-documentacion/tipo-cliente-documentacion-page/tipo-cliente-documentacion-page.component';
import { SelectInputComponent } from './components/common/forms/select-input/select-input.component';
import { CallesPageComponent } from './pages/privado/calles/calles-page/calles-page.component';
import { NuevoEditaCallesComponent } from './pages/privado/calles/components/nuevo-edita-calles/nuevo-edita-calles.component';
import { CallesZonaComponent } from './pages/privado/zonas/components/calles-zona/calles-zona.component';
import { EditaSolicitudAdminComponent } from './pages/privado/solicitudes/components/admin/edita-solicitud-admin/edita-solicitud-admin.component';
import { EditaSolicitudUsuarioComponent } from './pages/privado/solicitudes/components/usuario/edita-solicitud-usuario/edita-solicitud-usuario.component';
import { ConfiguracionesPageComponent } from './pages/privado/configuracion/configuraciones-page/configuraciones-page.component';
import { SolicitudDocumentosListComponent } from './pages/privado/solicitudes/components/admin/solicitud-documentos-list/solicitud-documentos-list.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './components/common/pdf-viewer/pdf-viewer.component';
import { DocumentoPageComponent } from './pages/privado/documentos/documento-page/documento-page.component';
import { TarjetaPageComponent } from './pages/privado/tarjetas/tarjeta-page/tarjeta-page.component';
import { TarjetaPrintComponent } from './components/privado/tarjeta-print/tarjeta-print.component';
import { FormAdminDocsComponent } from './pages/privado/documentos/components/form-admin-docs/form-admin-docs.component';
import { UsuariosPageComponent } from './pages/privado/usuarios/usuarios-page/usuarios-page.component';
import { NuevoEditaUsuarioComponent } from './pages/privado/usuarios/components/admin/nuevo-edita-usuario/nuevo-edita-usuario.component';
import { QRCodeModule } from 'angularx-qrcode';
import { FormUserDocsComponent } from './pages/privado/documentos/components/form-user-docs/form-user-docs.component';
import { EditaMiUsuarioComponent } from './pages/privado/usuarios/components/usuarios/edita-mi-usuario/edita-mi-usuario.component';
import { ItemTableNumberNameComponent } from './components/common/table-default/components/item-table-validado/item-table-number-name.component';
import { ItemTableBooleansComponent } from './components/common/table-default/components/item-table-booleans/item-table-booleans.component';
import { SolicitudHeaderFormComponent } from './pages/privado/solicitudes/components/admin/solicitud-header-form/solicitud-header-form.component';
import { TarjetaEditNuevoComponent } from './pages/privado/tarjetas/tarjeta-page/components/tarjeta-edit-nuevo/tarjeta-edit-nuevo.component';
import { CheckTarjetaValidaComponent } from './pages/privado/tarjetas/tarjeta-page/components/check-tarjeta-valida/check-tarjeta-valida.component';
import { NotFoundComponent } from './pages/publico/not-found/not-found.component';
import { TextEditorComponent } from './components/common/forms/text-editor/text-editor.component';
import { FacturaPrintComponent } from './components/privado/factura-print/factura-print.component';
import { ModalRenovacionesComponent } from './components/modals/modal-renovaciones/modal-renovaciones.component';
import { UtilidadesPageComponent } from './pages/privado/utilidades-page/utilidades-page.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { NgChartsModule } from 'ng2-charts';
import { ChartComponent } from './components/common/forms/chart/chart.component';
import { ChangePasswordComponent } from './pages/publico/change-password/change-password.component';
import { ModalSearchUsuarioComponent } from './components/modals/modal-search-usuario/modal-search-usuario.component';
import { ModalNuevoUsuarioComponent } from './components/modals/modal-nuevo-usuario/modal-nuevo-usuario.component';
import { ItemTableStringComponent } from './components/common/table-default/components/item-table-string/item-table-string.component';
import { InformesPageComponent } from './pages/privado/informes/informes-page/informes-page.component';
import { BorradoModalComponent } from './components/common/borrado-modal/borrado-modal.component';
import { SolicitudesPageComponent } from './pages/privado/solicitudes/solicitudes-page/solicitudes-page.component';
import { ModalConsentimientoComponent } from './components/modals/modal-consentimiento/modal-consentimiento.component';
import { ModalCondicionesComponent } from './components/modals/modal-condiciones/modal-condiciones.component';
import { ModalMapaZonasComponent } from './components/modals/modal-mapa-zonas/modal-mapa-zonas.component';
import { ModalCambioMatriculaComponent } from './components/modals/modal-cambio-matricula/modal-cambio-matricula.component';
import { ModalCambioDomicilioComponent } from './components/modals/modal-cambio-domicilio/modal-cambio-domicilio.component';
import { ModalSubirArchivoComponent } from './components/modals/modal-subir-archivo/modal-subir-archivo.component';
import { ModalObservacionesComponent } from './components/modals/modal-observaciones/modal-observaciones.component';
import { ModalEditaObservacionesComponent } from './components/modals/modal-edita-observaciones/modal-edita-observaciones.component';
import { ModalVerObservacionesComponent } from './components/modals/modal-ver-observaciones/modal-ver-observaciones.component';
import { SearchDropdownComponent } from './components/search-dropdown/search-dropdown.component';
import { ModalCambioParticularComponent } from './components/modals/modal-cambio-particular/modal-cambio-particular.component';
import { ModalCambiaClaveComponent } from './components/modals/modal-cambia-clave/modal-cambia-clave.component';
import { VerificaUsuarioPageComponent } from './pages/verifica-usuario-page/verifica-usuario-page.component';

@NgModule({
  declarations: [
    AppComponent,
    InicioPageComponent,
    PageContainerPublicoComponent,
    HeaderContainerComponent,
    HeaderTopComponent,
    HeaderNavPublicoComponent,
    SliderComponent,
    LoginPageComponent,
    LoginFormComponent,
    InputComponent,
    LoadingComponentComponent,
    RegistroPageComponent,
    RegistroFormComponent,
    AvisoEmailComponent,
    BoxMensajeComponent,
    UsuarioOptionsComponent,
    AdminOptionsComponent,
    SuperOptionsComponent,
    GeneralOptionsComponent,
    ZonasPageComponent,
    TableDefaultComponent,
    NuevoEditaZonaComponent,
    TinyEditorComponent,
    TextEditorComponent,
    ColorPickerComponent,
    TipoClientePageComponent,
    NuevoEditaTipoClienteComponent,
    TipoDocumentacionPageComponent,
    NuevoEditaTipoDocumentacionComponent,
    ReverseSwitchComponent,
    LinealComponentSelectorComponent,
    DynamicComponentDirective,
    TipoClienteDocumentacionComponent,
    NuevoEditaDocuClienteComponent,
    TipoClienteDocumentacionPageComponent,
    SelectInputComponent,
    CallesPageComponent,
    NuevoEditaCallesComponent,
    SolicitudesPageComponent,
    CallesZonaComponent,
    EditaSolicitudAdminComponent,
    EditaSolicitudUsuarioComponent,
    ConfiguracionesPageComponent,
    SolicitudDocumentosListComponent,
    PdfViewerComponent,
    DocumentoPageComponent,
    TarjetaPageComponent,
    FormAdminDocsComponent,
    UsuariosPageComponent,
    NuevoEditaUsuarioComponent,
    FormUserDocsComponent,
    EditaMiUsuarioComponent,
    ItemTableNumberNameComponent,
    ItemTableBooleansComponent,
    SolicitudHeaderFormComponent,
    TarjetaPrintComponent,
    TarjetaEditNuevoComponent,
    CheckTarjetaValidaComponent,
    NotFoundComponent,
    FacturaPrintComponent,
    ModalRenovacionesComponent,
    UtilidadesPageComponent,
    PaginatorComponent,
    ChartComponent,
    ChangePasswordComponent,
    ModalSearchUsuarioComponent,
    ModalNuevoUsuarioComponent,
    ItemTableStringComponent,
    InformesPageComponent,
    BorradoModalComponent,
    ModalConsentimientoComponent,
    ModalCondicionesComponent,
    ModalMapaZonasComponent,
    ModalCambioMatriculaComponent,
    ModalCambioDomicilioComponent,
    ModalSubirArchivoComponent,
    ModalObservacionesComponent,
    ModalEditaObservacionesComponent,
    ModalVerObservacionesComponent,
    SearchDropdownComponent,
    ModalCambioParticularComponent,
    ModalCambiaClaveComponent,
    VerificaUsuarioPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    CommonModule,
    EditorModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    HttpClientModule,
    PdfViewerModule,
    QRCodeModule,
    NgChartsModule
  ],
  providers: [
    {
       provide: HTTP_INTERCEPTORS,
       useClass: CustomHttpInterceptor,
       multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
