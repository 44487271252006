import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { createUsuario } from '../helpers/model.helper';
import { IActualizaUsuario } from 'src/app/models/interface/usuario.interface';

@Injectable()
export class UsuariosService {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;
  public tableName: string = 'usuarios';

  constructor(private _http: HttpClient, private _router: Router,) {
  }


  ficha(id: number): Observable<any> {
    const params = new HttpParams()
      .set('id', id + '')

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + this.tableName + '/ficha', params, httpOptions);
  }

  // Realiza el login del usuario
  lista(): Observable<any> {
    const params = new HttpParams()
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + this.tableName + '/lista', params, httpOptions);
  }

  // Realiza el login del usuario
  listapag(pagina: number, registros: number, filtro: string): Observable<any> {
    const params = new HttpParams()
      .set('pagina', pagina + '')
      .set('registros', registros + '')
      .set('filtro', filtro);
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(
      this.url + this.tableName + '/listapag',
      params,
      httpOptions
    );
  }

  // Realiza el login del usuario
  async lista_paginacion(pagina: number, registros: number, filtro: string): Promise<{ usuarios: UsuarioModel[], pagina_actual: number, total_paginas: number }> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const res = await firstValueFrom(this._http.get<any>(`${this.url}usuarios?pagina=${pagina}&registros=${registros}&filtro=${filtro}`, httpOptions));
      const list_usuarios: UsuarioModel[] = [];
      res.data.usuarios.forEach((item: any) => {
        list_usuarios.push(createUsuario(item))
      })
      const { pagina_actual, total_paginas } = res.data
      return { usuarios: list_usuarios, pagina_actual, total_paginas };
    } catch (e: any) {
      console.log(e);
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }

  // Realiza el login del usuario
  total(filtro: string): Observable<any> {
    const params = new HttpParams()
      .set('filtro', filtro);
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + this.tableName + '/total', params, httpOptions);
  }

  crea(usuario: UsuarioModel): Observable<any> {
    const params = new HttpParams()
      .set('nombre', usuario.nombre)
      .set('dni', usuario.dni)
      .set('email', usuario.email)
      .set('password', usuario.password)
      .set('telefono', usuario.telefono)
      .set('domicilio', usuario.domicilio)
      .set('activo', true)
      .set('role', 0)
      .set('codigopostal', usuario.codigoPostal)
      .set('localidad', usuario.localidad)
      .set('provincia', usuario.provincia)


    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + this.tableName + '/crea', params, httpOptions);
  }

  creaActualiza(usuario: UsuarioModel, funcion: string): Observable<any> {
    let params = new HttpParams()
      .set('nombre', usuario.nombre)
      .set('dni', usuario.dni)
      .set('email', usuario.email)
      .set('telefono', usuario.telefono)
      .set('domicilio', usuario.domicilio)
      .set('activo', usuario.activo ? '1' : '0')
      .set('role', usuario.role)
      .set('codigopostal', usuario.codigoPostal)
      .set('localidad', usuario.localidad)
      .set('provincia', usuario.provincia)
      .set('avatar', usuario.avatar)
      .set('observaciones', usuario.observaciones ? usuario.observaciones : '');

    if (funcion === 'actualiza') {
      params = params.set('id', usuario.id)
    } else {
      params = params.set('password', usuario.password)
    }

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + this.tableName + '/' + funcion, params, httpOptions);
  }


  // Realiza el login del usuario
  existeEmail(email: string): Observable<any> {
    const params = new HttpParams()
      .set('email', email)

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + this.tableName + '/existeEmail', params, httpOptions);
  }

  mailPassword(id: number, email: string | null): Observable<any> {
    const params = new HttpParams()
      .set('id', id + '')
      .set('email', email ?? '');

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + this.tableName + '/mailpassword', params, httpOptions);
  }

  changePassword(id: number, password: string | null): Observable<any> {
    const params = new HttpParams()
      .set('id', id + '')
      .set('password', password ?? '');

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + this.tableName + '/cambiaclave', params, httpOptions);
  }


  async ficha_nuevo(id: number): Promise<UsuarioModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}usuarios/${id}`, httpOptions))
      return createUsuario(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener el usuario';
      return Promise.reject(errorMessage);
    }
  }


  async crea_nuevo(usuario: UsuarioModel): Promise<UsuarioModel> {
    const json_payload = {
      nombre: usuario.nombre,
      dni: usuario.dni,
      email: usuario.email,
      telefono: usuario.telefono,
      password: usuario.password,
      domicilio: usuario.domicilio,
      activo: usuario.activo ? '1' : '0',
      role: usuario.role,
      codigopostal: usuario.codigoPostal,
      localidad: usuario.localidad,
      provincia: usuario.provincia,
      avatar: usuario.avatar,
      observaciones: usuario.observaciones ? usuario.observaciones : ''
    };


    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}usuarios`, json_payload, httpOptions))
      return createUsuario(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear el usuario';
      return Promise.reject(errorMessage);
    }

  }

  async actualiza_nuevo(id:number, usuario: IActualizaUsuario): Promise<UsuarioModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.put<any>(`${this.url}usuarios/${id}`, usuario, httpOptions))
      return createUsuario(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear el usuario';
      return Promise.reject(errorMessage);
    }
  }

  async solicita_cambia_clave(dni: string): Promise<void> {
    const json_payload = {
      dni: dni
    };

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      await firstValueFrom(this._http.post<any>(`${this.url}usuarios/solicita_cambia_clave`, json_payload, httpOptions))
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear el usuario';
      return Promise.reject(errorMessage);
    }
  }

  async cambia_clave (dni: string, clave: string, token: string): Promise<boolean> {
    const json_payload = {
      dni: dni,
      password: clave,
      token_cambio: token
    };

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      await firstValueFrom(this._http.post<any>(`${this.url}usuarios/cambia_clave`, json_payload, httpOptions))
      return true
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al cambiar la clave';
      return Promise.reject(errorMessage);
    }
  }

  async verifica_usuario(token: string, email: string) {
    const json_payload = {
      token_verificacion: token,
      email: email
    };

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      await firstValueFrom(this._http.post<any>(`${this.url}usuarios/verifica_usuario`, json_payload, httpOptions))
      return true
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al cambiar la clave';
      return Promise.reject(errorMessage);
    }
  }
}
