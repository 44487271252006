<app-page-container-publico [title]="'Tipo Documentación'">
  <div class="container-fluid">
    <ng-container *ngIf="type_form === ''">
      <h3
        *ngIf="data.length <= 0"
        class="text-gray text-center mt-5"
      >
        No hay zonas registradas
      </h3>
      <app-table-default
        *ngIf="data.length > 0"
        [data] = data
        [columnas_tabla] = "['nombre', 'activo', 'caducidad']"
        [tipo_dato_tabla] = "['', 'boolean', 'boolean']"
        [nombre_columnas] = "['Nombre', 'Activo', 'Caducidad']"
        [alineacion_columnas]="['start','','']"
        [ancho_columnas] = "['','10%', '10%']"
        [propiedades_para_filtro] = "['nombre']"
        [nombre_tabla] = "'tiposdocumentacion'"
        [ver_boton_editar]="true"
        [ver_boton_borrar]="true"
        [id_nombre] = "'id'"
        (envia_borrado)="abrir_modal_borrado($event)"
      ></app-table-default>
    </ng-container>
    <app-nuevo-edita-tipo-documentacion *ngIf="type_form !== ''"></app-nuevo-edita-tipo-documentacion>
  </div>
</app-page-container-publico>
