import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadService } from 'src/app/services/helpers/load.service';
import { UsuariosService } from 'src/app/services/https/usuarios.services';

@Component({
  selector: 'app-verifica-usuario-page',
  templateUrl: './verifica-usuario-page.component.html',
  styleUrls: ['./verifica-usuario-page.component.scss'],
  providers: [UsuariosService]
})
export class VerificaUsuarioPageComponent {
  email: string | null = null;
  token: string | null = null;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _nav: Router,
    private _toast: ToastrService,
    private _loader: LoadService,
    private _service: UsuariosService,
  ) {
    // Obtén los parámetros de consulta
    this._route.queryParamMap.subscribe(params => {
      this.email = params.get('email');
      this.token = params.get('token');
    });
  }

  async ngOnInit(): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      const verificado = await this._service.verifica_usuario(this.token!!, this.email!!)
      if (verificado) {
        this._toast.success('Usuario autentificado');
        this._router.navigate(['login']);
      }
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);

    }
  }
}
