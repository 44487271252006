import { Component, OnInit, DoCheck } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { globalState, TIPO_ESTADO } from 'src/global';
import { timeoutTime } from 'src/app/services/helpers/utils.helper';
import { SolicitudesServices } from 'src/app/services/https/solicitudes.services';
import { TarjetasServices } from 'src/app/services/https/tarjetas.service';
import { ZonasService } from 'src/app/services/https/zonas.services';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { FiltroModel } from 'src/app/models/entity/filtro.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SolicitudView } from 'src/app/models/entity/views/solicitudes.view';
import { ModalRenovacionesComponent } from '../../../../components/modals/modal-renovaciones/modal-renovaciones.component';
import { BorradoModalComponent } from 'src/app/components/common/borrado-modal/borrado-modal.component';
import { SolicitudModel } from 'src/app/models/entity/solicitud.model';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { CallesService } from 'src/app/services/https/calles.services';
import { EditaSolicitudView } from 'src/app/models/entity/views/edita_solicitud.view';
import { TipoClienteService } from 'src/app/services/https/tipoCliente.services';
import { TipoClienteModel } from 'src/app/models/entity/tipoCliente.model';
import { TarjetaView } from 'src/app/models/entity/views/tarjetas.view';
import { IActualizaSolicitud, IInsertaSolicitud } from 'src/app/models/interface/solicitud.interface';
import { UsuariosService } from 'src/app/services/https/usuarios.services';
import { createSolicitud } from 'src/app/services/helpers/model.helper';

@Component({
  selector: 'app-solicitudes-page',
  templateUrl: './solicitudes-page.component.html',
  styleUrls: ['./solicitudes-page.component.scss'],
  providers: [SolicitudesServices, ZonasService, TarjetasServices, CallesService, TipoClienteService, UsuariosService],
})
export class SolicitudesPageComponent implements OnInit {
  // Datos
  allData: SolicitudView[] = [];
  zonas: ZonasModel[] = [];
  tipos_cliente: TipoClienteModel[] = [];
  calles: CalleModel[] = [];
  solicitud: EditaSolicitudView | null = null;
  estados = TIPO_ESTADO;

  usuario: UsuarioModel = globalState.identity!!;
  es_administrador: boolean = this.usuario.role === 2;
  es_listado: boolean = true;
  cargando: boolean = true;
  // Paginacion y filtrado de solicitudes
  filtro: string = '';
  filtro_dinamico: string = '';
  filtros_dinamicos_principales: any[] = [];
  id: number = 0;
  pagina_actual = 1;
  total_paginas = 0;
  registros_pagina: number = globalState.registrosPagina;
  datos_tarjeta: any = null;
  datos_factura: any = null;
  timeout: any;

  // Impresion de documentos
  imprimir_tarjeta: boolean = false;
  imprimir_factura: boolean = false;

  constructor(
    private _router: Router,
    private _loader: LoadService,
    private _toast: ToastrService,
    private _service: SolicitudesServices,
    private _tar_service: TarjetasServices,
    private _serviceZonas: ZonasService,
    private _calle_service: CallesService,
    private _tc_service: TipoClienteService,
    private _us_service: UsuariosService,
    private _modalService: NgbModal
  ) {
    const { url } = this._router
    const segments = url.split('/');
    this.es_listado = segments.length === 2;
    if (!this.es_listado)
      if (segments[segments.length - 1] !== "nuevo") {
        this.id = Number(segments[segments.length - 1]);
      }
  }


  async ngOnInit() {
    try {
      this._loader.notifyLoadChange(true);
      this.zonas = await this.obtener_zonas();
      this.tipos_cliente = await this.obten_tipos_clientes();
      if (this.es_listado) {
        const { solicitudes, total_paginas, pagina_actual} = await this.obten_solicitudes();
        this.calles = await this._calle_service.calles_lista()
        this.allData = solicitudes;
        this.total_paginas = total_paginas;
        this.pagina_actual = pagina_actual;
        this.filtros_dinamicos_principales.push(
          new FiltroModel(0, 'zona-filtro', 'id', 'Filtrar por zonas', 'Todas las zonas', 0, 'nombre', 'zona_id', this.zonas, 0),
          new FiltroModel(1, 'estado-filtro', 'id', 'Selecciona estado', 'Todas los estados', 0, 'descripcion', 'estado', TIPO_ESTADO, 0)
        );
      } else {
        if (this.id != 0) {
          this.solicitud = await this.obten_solicitud(this.id);
          await this.obten_calles_por_zona(this.solicitud.zona_id);
        }
      }
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this.cargando = false;
      this._loader.notifyLoadChange(false);
    }
  }

  async obten_solicitud(id: number) {
    return await this._service.ficha_nueva(id)
  }

  async obten_tipos_clientes() {
    const { tipos } = await this._tc_service.lista_completa();
    return tipos
  }

    // Obtenemos los datos de solicitudes, total de paginas y pagina actual
  async obten_solicitudes(): Promise<{solicitudes: SolicitudView[], total_paginas: number, pagina_actual: number}> {
    return await this._service.listapag(this.pagina_actual, this.registros_pagina, this.filtro);
  }

  async obtener_zonas(): Promise<ZonasModel[]> {
    try {
      const { zonas } = await this._serviceZonas.lista_completa();
      return zonas;
    } catch (e: any) {
      return Promise.reject(e);
    }

  }

  get_filtro_solicitudes(calle_matricula: string, calle_piso: string, calle_numero: string, calle_letra: string, calle_id: number, filtro_dinamico: string): string {
    let filtro = '';

    // Si el role es de usuario solo debe ver sus solicitudes
    if (this.usuario!!.role < 1) {
      filtro += `where (usuario_id = '${this.usuario!!.id}')`;
    }

    // Se pasa parámetros de annio para reducir el tiempo de la petición
    if (globalState.annioActual > 0) {
      filtro += filtro === '' ? ' where ' : ' and ';
      filtro += `(annio = '${globalState.annioActual}')`;
    }

    // Filtro del cuadro de búsqueda
    if (calle_matricula !== '') {
      filtro += filtro === '' ? ' where ' : ' and ';
      filtro += `((INSTR(nombre, '${calle_matricula}') > 0) OR (INSTR(dni, '${calle_matricula}') > 0) OR (INSTR(matricula, '${calle_matricula}') > 0) OR (INSTR(numero, '${calle_matricula}') > 0) OR (INSTR(destinatario, '${calle_matricula}') > 0) OR (INSTR(domicilio, '${calle_matricula}') > 0))`;
    }
    if (calle_piso !== '') {
      filtro += filtro === '' ? ' where ' : ' and ';
      filtro += `((INSTR(calle_piso, '${calle_piso}') > 0))`;
    }
    if (calle_numero !== '') {
      filtro += filtro === '' ? ' where ' : ' and ';
      filtro += `((INSTR(calle_numero, '${calle_numero}') > 0))`;
    }
    if (calle_letra !== '') {
      filtro += filtro === '' ? ' where ' : ' and ';
      filtro += `((INSTR(calle_letra, '${calle_letra}') > 0))`;
    }

    if (filtro_dinamico !== '') {
      filtro += filtro === '' ? ' where ' : ' and ';
      filtro += filtro_dinamico;
    }

    if (calle_id !== 0) {
      filtro += filtro === '' ? ' where ' : ' and ';
      filtro += `( calle_id = ${calle_id})`;
    }
    return filtro;
  }

  cambia_pagina(nueva_pagina: number) {
    this.pagina_actual = nueva_pagina
  }

  abrir_modal_externo(abrir: boolean) {
    if (abrir) {
      const modal = this._modalService.open(ModalRenovacionesComponent, { centered: true, size: 'lg' });
      modal.componentInstance.envia_renovacion.subscribe(async(tarjeta: TarjetaView) => {
        try {
          this._loader.notifyLoadChange(true);
          const solicitud_renovacion = await this.obten_solicitud(tarjeta.solicitudId);
          const usuario_renovacion = await this._us_service.ficha_nuevo(solicitud_renovacion.usuario_id)
          const solicitud_insercion: IInsertaSolicitud = {
            usuario_id: solicitud_renovacion.usuario_id,
            destinatario:solicitud_renovacion.destinatario,
            tipocliente_id: solicitud_renovacion.tipocliente_id,
            matricula: solicitud_renovacion.matricula,
            marca: solicitud_renovacion.marca,
            modelo: solicitud_renovacion.modelo,
            medio: 0,
            consentimiento: 1,
            tramita_id: globalState.identity!!.id,
            zona_id: solicitud_renovacion.zona_id,
            calle_id: solicitud_renovacion.calle_id,
            domicilio: solicitud_renovacion.domicilio,
            estado: solicitud_renovacion.estado,
            tipo_pago: 1,
            telefono: solicitud_renovacion.telefono === '' ? usuario_renovacion.telefono : solicitud_renovacion.telefono,
            calle_numero: solicitud_renovacion.calle_numero,
            calle_piso: solicitud_renovacion.calle_piso,
            calle_letra: solicitud_renovacion.calle_letra,
            calle_bloque: solicitud_renovacion.calle_bloque,
            renovacion: 1
          }
          await this.inserta_nueva_solicitud(solicitud_insercion);

        } catch (e: any) {
          this._toast.error(e)
        } finally {
          this._loader.notifyLoadChange(false);
        }
      })
    }
  }

  async recibe_filtro(value: {calle_matricula: string, numero: string, piso: string, letra: string, filtro_dinamico: string, calle_id: number }) {
    const {calle_matricula, numero, piso, letra, calle_id, filtro_dinamico} = value
    this.filtro_dinamico = filtro_dinamico;
    await this.obtiene_solicitudes_filtro(calle_matricula, numero, piso, letra, calle_id, filtro_dinamico, this.pagina_actual, this.registros_pagina);
  }

  async recibe_pagina(value: number) {
    this.pagina_actual = value;
    try {
      this._loader.notifyLoadChange(true);
      const { solicitudes, total_paginas, pagina_actual} = await this.obten_solicitudes()
      this.allData = solicitudes;
      this.total_paginas = total_paginas;
      this.pagina_actual = pagina_actual;
    } catch (e: any) {
      this._toast.error(e);
      this.allData = [];
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  async obtiene_solicitudes_filtro(calle: string, numero: string, piso:string, letra: string, calle_id: number, filtro_dinamico: string, pagina_actual: number, registros_pagina: number): Promise<void> {
    this._loader.notifyLoadChange(true);
    let filtro = this.get_filtro_solicitudes(calle, piso, numero, letra, calle_id, filtro_dinamico);
    this.filtro = filtro;
      try {
        const data = await this._service.listapag(pagina_actual, registros_pagina, filtro);
        this.allData = data.solicitudes;
        this.total_paginas = data.total_paginas;
        this.pagina_actual = data.pagina_actual;
      } catch (e: any) {
        this._toast.error(e);
        this.allData = [];
        this.total_paginas = 1;
        this.pagina_actual = 1;
      } finally {
        this._loader.notifyLoadChange(false)
      }
  }

  async inserta_nueva_solicitud(datos: IInsertaSolicitud): Promise<void> {
    try {
      this._loader.notifyLoadChange(true)
      const { id } = await this._service.crea_nuevo(datos);
      this._router.navigate([`solicitudes/${id}`])
      this.id = id;
      this.ngOnInit();
    } catch (e: any) {
      return Promise.reject(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  async actualiza_solicitud(datos: IActualizaSolicitud): Promise<void> {
    try {
      this._loader.notifyLoadChange(true)
      const data = await this._service.actualiza_nuevo(datos);
      this._toast.success(`Solicitud actualizada`);
    } catch (e: any) {
      return Promise.reject(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  abrir_modal_borrado(id: number): void {
    const modalRef = this._modalService.open(BorradoModalComponent, { centered: true })
    modalRef.componentInstance.tipo_objeto = "solicitud"
    modalRef.componentInstance.id = id
    modalRef.componentInstance.tabla = "solicitudes"
    modalRef.componentInstance.borrado_exitoso.subscribe(async (borrado_exitoso: boolean) => {
      if (borrado_exitoso) {
        try {
          this._loader.notifyLoadChange(true);
          const { solicitudes, total_paginas, pagina_actual} = await this.obten_solicitudes()
          this.allData = solicitudes;
          this.total_paginas = total_paginas;
          this.pagina_actual = pagina_actual;
        } catch (e: any) {
          this._toast.error(e);
        } finally {
          this._loader.notifyLoadChange(false);
        }
      }
    })
  }

  recibir_datos_factura(datos: any) {
    this._loader.notifyLoadChange(true);
    this.datos_factura = datos;
    timeoutTime(500).then(() => {
      this._loader.notifyLoadChange(false);
      this.imprimir_factura = true;
      timeoutTime(500).then(() => {
        this.datos_factura = null;
      });
    });
    this.imprimir_factura = false;
  }

  recibir_datos_tarjeta(datos: any) {
    this.datos_tarjeta = datos
    this._loader.notifyLoadChange(true);
    timeoutTime(500).then(() => {
      this._loader.notifyLoadChange(false);
      this.imprimir_tarjeta = true;
      timeoutTime(500).then(() => {
        this.datos_tarjeta = null;
      });
    });
    this.imprimir_tarjeta = false;
  }

  async recibir_ambos(datos: any) {
    try {
      this._loader.notifyLoadChange(true);
      const { datos_tarjeta, datos_factura } = datos
      // const datos_final = { datos_tarjeta: datos_impresion, datos_factura: { id: datos.id } };
      // this.datos_factura = datos_final.datos_factura
      // this.datos_tarjeta = datos_final.datos_tarjeta;
      this.datos_factura = datos_factura
      this.datos_tarjeta = datos_tarjeta
      timeoutTime(500).then(() => {
        this._loader.notifyLoadChange(false);
        this.imprimir_tarjeta = true;
        timeoutTime(5).then(() => {
          this.datos_factura = null;
          this.datos_tarjeta = null;
        });
      });
      this.imprimir_tarjeta = false;
      this.imprimir_factura = false;
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  recibir_datos_listado(datos: any) {
    this.recibir_datos_factura({ id: datos.id });
  }

/**
 * Obtiene las calles correspondientes a una zona específica.
 * @param zona_id - El ID de la zona para obtener sus calles.
 */
  async obten_calles_por_zona(zona_id: number): Promise<void> {
    try {

      this.calles = await this._calle_service.calles_by_zona(zona_id);
    } catch (e: any) {
      this._toast.error(e);
      this.calles = [];
    }
  }
}
