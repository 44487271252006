import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { SolicitudModel } from 'src/app/models/entity/solicitud.model';
import { TipoClienteModel } from 'src/app/models/entity/tipoCliente.model';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { TipoClienteService } from 'src/app/services/https/tipoCliente.services';
import { UsuariosService } from 'src/app/services/https/usuarios.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { globalState } from 'src/global';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faMagnifyingGlass, faCaretDown, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ConfiguracionService } from 'src/app/services/https/configuracion.services';
import { ToastrService } from 'ngx-toastr';
import { LoadService } from 'src/app/services/helpers/load.service';
import { Router } from '@angular/router';
import { SolicitudesServices } from 'src/app/services/https/solicitudes.services';
import { ZonasService } from 'src/app/services/https/zonas.services';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { CallesService } from 'src/app/services/https/calles.services';
import { faMap } from '@fortawesome/free-regular-svg-icons';
import { EditaSolicitudView } from 'src/app/models/entity/views/edita_solicitud.view';
import { IEditaSolicitudView } from 'src/app/models/interface/ver_edita_solicitud.interface';
import { ModalSearchUsuarioComponent } from '../../../../../../components/modals/modal-search-usuario/modal-search-usuario.component';
import { ModalConsentimientoComponent } from 'src/app/components/modals/modal-consentimiento/modal-consentimiento.component';
import { ModalCondicionesComponent } from 'src/app/components/modals/modal-condiciones/modal-condiciones.component';
import { ModalMapaZonasComponent } from 'src/app/components/modals/modal-mapa-zonas/modal-mapa-zonas.component';
import { ModalCambioMatriculaComponent } from '../../../../../../components/modals/modal-cambio-matricula/modal-cambio-matricula.component';
import { ModalNuevoUsuarioComponent } from 'src/app/components/modals/modal-nuevo-usuario/modal-nuevo-usuario.component';
import { ModalCambioDomicilioComponent } from 'src/app/components/modals/modal-cambio-domicilio/modal-cambio-domicilio.component';
import { SolicitudDocumentosListComponent } from '../solicitud-documentos-list/solicitud-documentos-list.component';
import { IActualizaSolicitud, IInsertaSolicitud } from 'src/app/models/interface/solicitud.interface';
import { ModalCambioParticularComponent } from 'src/app/components/modals/modal-cambio-particular/modal-cambio-particular.component';

@Component({
  selector: 'app-edita-solicitud-admin',
  templateUrl: './edita-solicitud-admin.component.html',
  styleUrls: ['./edita-solicitud-admin.component.scss'],
  providers: [ TipoClienteService, UsuariosService, ConfiguracionService, SolicitudesServices, ZonasService, CallesService, UsuariosService],
})
export class EditaSolicitudAdminComponent implements OnInit {
  @Input() id!: number;
  @Input() es_nuevo: boolean = true;
  @Input() solicitud!: EditaSolicitudView | null;
  @Input() zonas!: ZonasModel[];
  @Input() calles!: CalleModel[];
  @Input() tipos_clientes!: TipoClienteModel[];
  es_administrador: boolean = globalState.identity!!.role > 1;
  @Output() envia_datos_tarjeta: EventEmitter<any> = new EventEmitter<any>
  @Output() envia_datos_factura: EventEmitter<any> = new EventEmitter<any>
  @Output() envia_datos_tar_fac: EventEmitter<any> = new EventEmitter<any>
  @Output() envia_nueva_solicitud: EventEmitter<IInsertaSolicitud> = new EventEmitter<IInsertaSolicitud>
  @Output() envia_actualiza_solicitud: EventEmitter<IActualizaSolicitud> = new EventEmitter<IActualizaSolicitud>
  @Output() envia_refresca_datos: EventEmitter<void> = new EventEmitter<void>
  estado: number = 0;
  // Datos para dibujar en pantalla
  cargando: boolean = true;
  tipo_pago_data = [
    { id: 1, nombre: 'Efectivo' },
    { id: 2, nombre: 'Tarjeta' },
    { id: 3, nombre: 'Online' }
  ]
  menu_boton_usuario: boolean = false;
  documentos_lista: Function = SolicitudDocumentosListComponent
  // Datos para el formulario
  formGroup = new FormGroup({
    // Variables para el formulario
    marca: new FormControl<string>("", [Validators.required]),
    usuarioId: new FormControl<number>(0, [Validators.required]),
    destinatario: new FormControl<string>(''),
    telefono: new FormControl<string>('', []),
    estado: new FormControl<number>(-1, [Validators.required]),
    tramitaId: new FormControl<number>(0, []),
    zonaId: new FormControl<number>(0, []),
    tarjetaId: new FormControl<number>(0, []),
    importe: new FormControl<number>(0, []),
    modelo: new FormControl<string>("", [Validators.required]),
    matricula: new FormControl<string>("", [Validators.required]),
    tipoClienteId: new FormControl<number>(0, [Validators.required]),
    usuarioNombre: new FormControl<string>('', [Validators.required]),
    calle_numero: new FormControl<string>(''),
    calle_piso: new FormControl<string>(''),
    calle_letra: new FormControl<string>(''),
    calle_bloque: new FormControl<string>(''),
    pagada: new FormControl<boolean>(false, []),
    consentimiento: new FormControl<boolean>(false, [Validators.required]),
    annio: new FormControl<number>(0, []),
    iva: new FormControl<number>(0, []),
    total: new FormControl<number>(0, []),
    facturaSerie: new FormControl<string>('', []),
    facturaNumero: new FormControl<number>(0, []),
    medio: new FormControl<boolean>(false, [Validators.required]),
    tipoPagoId: new FormControl<number>(1, [Validators.required]),
    // Variable para saber si se usa o o no la variable de la calle manual
    calleEncontrada: new FormControl<boolean>(false, [Validators.required]),
    calleId: new FormControl<number>(0, [Validators.required]),
    domicilio: new FormControl<string>('', []),
    matricula_matricula: new FormControl<string>('', []),
    matricula_modelo: new FormControl<string>('', []),
    matricula_marca: new FormControl<string>('', []),
    matricula_error: new FormControl<string>('', []),
    domicilio_zonaId: new FormControl<number>(0, []),
    domicilio_calle: new FormControl<string>('', []),
    domicilio_error: new FormControl<string>('', [])
  });

  // Iconos
  flecha_abajo: IconDefinition = faCaretDown;
  icono_anyadir: IconDefinition = faPlus;
  icono_buscar: IconDefinition = faMagnifyingGlass;
  icono_editar: IconDefinition = faEdit;
  icono_mapa: IconDefinition = faMap;

  constructor(
    private _sol_service: SolicitudesServices,
    private _load_service: LoadService,
    private _modalService: NgbModal,
    private _calle_services: CallesService,
    private _toasr: ToastrService,
    private _usu_service: UsuariosService,
    private _router: Router
  ) {

  }

  /**
    * - Determina si se está creando una nueva solicitud o editando una existente.
    * - Carga la solicitud y otros datos necesarios.
    * - Suscribe a cambios en el campo `zonaId` del formulario para realizar operaciones adicionales.
  */
  async ngOnInit(): Promise<void> {
    if (!this.es_nuevo) {
      if (!this.es_nuevo) {
        this.actualizaFormGroup(this.solicitud!!);
      }
    } else {
      if (!this.es_administrador) {
        const usuario = await this._usu_service.ficha_nuevo(globalState.identity!!.id)
        this.formGroup.patchValue({
          usuarioId: usuario!!.id,
          usuarioNombre: usuario.nombre,
          telefono: usuario.telefono
        });
      }
    }
  }

  /**
   * Obtiene los datos de la solicitud a editar.
   * @param id - El ID de la solicitud a obtener.
   * @returns Una promesa que resuelve con los datos de la solicitud.
   */
  async obten_solicitud(id: number): Promise<IEditaSolicitudView> {
    try {
      return await this._sol_service.ficha_nueva(id)
    } catch (e: any) {
      this._toasr.error(e);
      return Promise.reject(e);
    }
  }

  /**
   * Envía una peticion al componente padre para refrescar los datos de la solicitud
   * @param refresca
   */
  async refresca_datos(refresca: boolean) {
    this.envia_refresca_datos.emit();
  }

  /**
   * Abre un modal para seleccionar un usuario y actualiza el formulario con los datos del usuario seleccionado.
   */
  abrir_modal_selecciona_usuario() {
    const modalRef = this._modalService.open(ModalSearchUsuarioComponent, { centered: true })
    modalRef.componentInstance.envia_usuario.subscribe((usuario_seleccionado: UsuarioModel) => {
      this.formGroup.patchValue({
        usuarioId: usuario_seleccionado.id,
        usuarioNombre: usuario_seleccionado.nombre,
        telefono: usuario_seleccionado.telefono
      })
      this.menu_boton_usuario = false;
    });
  }

  /**
   *
   */
  abrir_modal_nuevo_usuario() {
    const modalRef = this._modalService.open(ModalNuevoUsuarioComponent, { centered: true, });
    modalRef.componentInstance.envia_usuario.subscribe((nuevo_usuario: UsuarioModel) => {
      this.formGroup.patchValue({
        usuarioId: nuevo_usuario.id,
        usuarioNombre: nuevo_usuario.nombre
      })
      this.menu_boton_usuario = false;
    })
  }

  abrir_modal_mapa() {
    const modalRef = this._modalService.open(ModalMapaZonasComponent, { centered: true, });
    modalRef.componentInstance.selecciona_zona.subscribe((zona_id: number) => {
      this.formGroup.patchValue({
        zonaId: zona_id
      });
    })
  }

  abrir_modal_consentimiento() {
    const modalRef = this._modalService.open(ModalConsentimientoComponent, { centered: true, });
    modalRef.componentInstance.envia_consentimiento.subscribe((acepta_terminos: boolean) => {
      this.formGroup.patchValue({
        consentimiento: acepta_terminos
      })
    })
  }

  abrir_modal_condiciones() {
    this._modalService.open(ModalCondicionesComponent, { centered: true, });
  }

  abrir_modal_cambio_matricula() {
    const modal_ref = this._modalService.open(ModalCambioMatriculaComponent, { centered: true });
    modal_ref.componentInstance.id = this.solicitud!!.id;

  }

  abrir_modal_cambio_domicilio() {
    const modal_ref = this._modalService.open(ModalCambioDomicilioComponent, { centered: true });
    modal_ref.componentInstance.id = this.solicitud!!.id;
  }

  abrir_modal_cambio_particular() {
    const modalRef = this._modalService.open(ModalSearchUsuarioComponent, { centered: true })
    modalRef.componentInstance.envia_usuario.subscribe((usuario_seleccionado: UsuarioModel) => {
      try {
        this._load_service.notifyLoadChange(true);
        this._sol_service.cambia_particular(usuario_seleccionado.id, this.solicitud!!.id);
        this._toasr.success("Cambio de particular creado")
        this._router.navigate(['/solicitudes']);
      } catch (e: any) {
        this._toasr.error(e);
      } finally {
        this._load_service.notifyLoadChange(false);
      }
      this.menu_boton_usuario = false;
    });
  }

  async actualizaFormGroup(updateInf: EditaSolicitudView) {
    this.estado = updateInf.estado
    let tipo_pago_id = 0;
    const tipo_pago = this.tipo_pago_data.find((x) => x.nombre === updateInf.tipo_pago);
    if (tipo_pago !== undefined) {
      tipo_pago_id = tipo_pago.id;
    }
    // await this.obten_calles_por_zona(updateInf.zona_id)

    this.formGroup.patchValue({
      marca: updateInf.marca ?? '',
      usuarioId: updateInf.usuario_id,
      usuarioNombre: updateInf.usuario_nombre,
      destinatario: updateInf.destinatario,
      estado: updateInf.estado,
      tramitaId: globalState.identity?.id ?? 0,
      tarjetaId: updateInf.tarjeta_id,
      importe: updateInf.importe,
      modelo: updateInf.modelo,
      matricula: updateInf.matricula,
      tipoClienteId: updateInf.tipocliente_id,
      consentimiento: updateInf.consentimiento,
      pagada: updateInf.pagado,
      annio: updateInf.annio,
      iva: updateInf.iva,
      facturaSerie: updateInf.factura_serie,
      facturaNumero: updateInf.factura_numero,
      medio: updateInf.medio,
      zonaId: updateInf.zona_id,
      calleId: updateInf.calle_id,
      domicilio: updateInf.domicilio,
      calleEncontrada: updateInf.domicilio === '' ? false : true,
      tipoPagoId: tipo_pago_id,
      telefono: updateInf.telefono === '' ? updateInf.usuario_telefono : updateInf.telefono,
      calle_numero: updateInf.calle_numero,
      calle_piso: updateInf.calle_piso,
      calle_letra: updateInf.calle_letra,
      calle_bloque: updateInf.calle_bloque
    });
    this.formGroup.value.calleId = updateInf.calle_id;
  }

  /**
   * Cambio de valor del botón que permite crear o seleccionar usuario
   */
  pulsa_boton_menu() {
    this.menu_boton_usuario = !this.menu_boton_usuario;
  }

  async onSubmit() {
    this._load_service.notifyLoadChange(true);

    const {
      marca, usuarioId, destinatario, telefono,
      estado, tramitaId, zonaId, tarjetaId, importe,
      modelo, matricula, tipoClienteId, usuarioNombre,
      calle_numero, calle_piso, calle_letra, calle_bloque,
      pagada, consentimiento, annio, iva, total, facturaSerie,
      facturaNumero, medio, tipoPagoId, calleEncontrada, calleId,
      domicilio
    } = this.formGroup.value!!
    const valido = this.valida_formulario(calleEncontrada!!, calleId!!, domicilio!!);
    if (!valido) {
      return;
    }
    if (this.es_nuevo) {
      const nueva_solicitud: IInsertaSolicitud = {
        usuario_id: usuarioId!!,
        destinatario: destinatario!!,
        estado: estado!!,
        tramita_id: tramitaId!!,
        matricula: matricula!!,
        marca: marca!!,
        modelo: modelo!!,
        tipocliente_id: tipoClienteId!!,
        consentimiento: consentimiento!! ? 1 : 0,
        zona_id: zonaId!!,
        calle_id: calleId!!,
        domicilio: domicilio!!,
        telefono: telefono!!,
        medio: medio!! ? 1 : 0,
        tipo_pago: tipoPagoId!!,
        calle_numero: calle_numero!!,
        calle_piso: calle_piso!!,
        calle_letra: calle_letra!!,
        calle_bloque: calle_bloque!!,
        renovacion: 0
      }
      this.envia_nueva_solicitud.emit(nueva_solicitud);
    } else {
      if (this.estado > 2) {
        const actualiza_solicitud: IActualizaSolicitud = {
          id_solicitud: this.solicitud!!.id,
          usuario_id: this.solicitud!!.usuario_id,
          destinatario: this.solicitud!!.destinatario,
          estado: this.solicitud!!.estado!!,
          tramita_id: this.solicitud!!.tramita_id,
          matricula: this.solicitud!!.matricula,
          marca: this.solicitud!!.marca,
          modelo: this.solicitud!!.modelo,
          tipocliente_id: this.solicitud!!.tipocliente_id!!,
          consentimiento: this.solicitud!!.consentimiento!! ? 1 : 0,
          zona_id: this.solicitud!!.zona_id!!,
          calle_id: this.solicitud!!.calle_id,
          domicilio: this.solicitud!!.domicilio,
          telefono: this.solicitud!!.telefono,
          medio: this.solicitud!!.medio ? 1 : 0,
          tipo_pago: this.formGroup.value.tipoPagoId!!,
          calle_numero: this.solicitud!!.calle_numero,
          calle_piso: this.solicitud!!.calle_piso,
          calle_letra: this.solicitud!!.calle_letra,
          calle_bloque: this.solicitud!!.calle_bloque,
          renovacion: this.solicitud!!.renovacion ? 1 : 0
        }
        this.envia_actualiza_solicitud.emit(actualiza_solicitud);
      } else {
        const actualiza_solicitud: IActualizaSolicitud = {
          id_solicitud: this.solicitud!!.id,
          usuario_id: usuarioId!!,
          destinatario: destinatario!!,
          estado: estado!!,
          tramita_id: tramitaId!!,
          matricula: matricula!!,
          marca: marca!!,
          modelo: modelo!!,
          tipocliente_id: tipoClienteId!!,
          consentimiento: consentimiento!! ? 1 : 0,
          zona_id: zonaId!!,
          calle_id: calleId!!,
          domicilio: domicilio!!,
          telefono: telefono!!,
          medio: medio!! ? 1 : 0,
          tipo_pago: tipoPagoId!!,
          calle_numero: calle_numero!!,
          calle_piso: calle_piso!!,
          calle_letra: calle_letra!!,
          calle_bloque: calle_bloque!!,
          renovacion: this.solicitud!!.renovacion ? 1 : 0
        }
        this.envia_actualiza_solicitud.emit(actualiza_solicitud);
        // await this.actualiza_solicitud(nueva_solicitud);
      }
      }
  }

  valida_formulario(calle_encontrada: boolean, calle_id: number, domicilio: string): boolean {
    if (!calle_encontrada) {
      if (calle_id === 0) {
        this._toasr.error('Selecciona una calle');
        this._load_service.notifyLoadChange(false);
        return false;
      }
      return true;
    } else {
      if (domicilio === '') {
        this._toasr.error('El campo Calle es obligatorio');
        this._load_service.notifyLoadChange(false);
        return false;
      }
      return true
    }
  }

  async actualiza_solicitud(solicitud: IActualizaSolicitud) {
    try {
      const data = await this._sol_service.actualiza_nuevo(solicitud)
      this._toasr.success(data.message);
      window.location.reload()
    } catch (e: any) {
      this._toasr.error(e);
    } finally {
      this._load_service.notifyLoadChange(false)
    }
  }

  /**
   * Obtiene las calles correspondientes a una zona específica.
   * @param zona_id - El ID de la zona para obtener sus calles.
   */
  async obten_calles_por_zona(evento: any): Promise<void> {
    try {
      const zona_id = evento.target.value;
      this.calles = [];
      // this.formGroup.value.calleId = 0;
      this.calles = await this._calle_services.calles_by_zona(zona_id);
      // console.log(this.calles)
    } catch (e: any) {
      this._toasr.error(e);
      this.calles = [];
    }
  }
}
