import { Component } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BorradoModalComponent } from 'src/app/components/common/borrado-modal/borrado-modal.component';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createCalle, createTipoDocuCliente } from 'src/app/services/helpers/model.helper';
import { CallesService } from 'src/app/services/https/calles.services';

@Component({
  selector: 'app-calles-zona',
  templateUrl: './calles-zona.component.html',
  styleUrls: ['./calles-zona.component.scss'],
  providers: [CallesService]
})
export class CallesZonaComponent {
  allData: CalleModel[] = [];
  nuevoIcon: IconDefinition = faPlus;
  volverIcon: IconDefinition = faArrowLeft;
  typeForm: string = 'lista';
  idCliente: number = 0;

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _service: CallesService,
    private _loader: LoadService,
    private _toast: ToastrService,
    private _modal_service: NgbModal
    ){
      this._route.params.subscribe((params: Params) => {
        // Accede al valor del parámetro
        if (params['slug']) {
          this.idCliente = params['slug'];
          this.obtainData(this.idCliente);
        }
      });
  }

  ngOnInit(){
  }

  async obtainData(zona_id: number) {
    try {
      this.allData = await this._service.calles_by_zona(zona_id);
    } catch (e: any) {
      this._toast.error(e);
    }

  }

  setTypeForm(tipo: string) {
    this.typeForm = tipo;
  }

  abrir_modal_borrado(id_calle: number) {
    const modalRef = this._modal_service.open(BorradoModalComponent, { centered: true })
    modalRef.componentInstance.tipo_objeto = "calle"
    modalRef.componentInstance.id = id_calle
    modalRef.componentInstance.tabla = "zonas_calles"
    modalRef.componentInstance.borrado_exitoso.subscribe(async (borrado_exitoso: boolean) => {
      if (borrado_exitoso) {
        try {
          this.allData = await this._service.calles_by_zona(this.idCliente);
        } catch (e: any) {
          this._toast.error(e);
        } finally {
          this._loader.notifyLoadChange(false);
        }
      }
    })
  }
}
