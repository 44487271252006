import { Component, EventEmitter, Input, Output,SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UsuariosService } from 'src/app/services/https/usuarios.services';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createUsuario } from 'src/app/services/helpers/model.helper';
import { TarjetasServices } from 'src/app/services/https/tarjetas.service';
import { globalState } from 'src/global';
import { timeoutTime } from 'src/app/services/helpers/utils.helper';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-nuevo-usuario',
  templateUrl: './modal-nuevo-usuario.component.html',
  styleUrls: ['./modal-nuevo-usuario.component.scss'],
  providers: [UsuariosService]
})
export class ModalNuevoUsuarioComponent {
   // Parametros paginacion
   @Output() envia_usuario: EventEmitter<UsuarioModel> = new EventEmitter();

  usuarios: UsuarioModel[] = [];

  searchText: string = '';
  totalPaginas : number = 1;

  usuariosData: UsuarioModel[] = [];
  totalUsuarios : number = 0;
  filtroUsuarios : string = '';
  paginaUsuarios : number = 1;
  registrosPagina : number = globalState.registrosPagina;

  formGroup = new FormGroup({
    nombre: new FormControl<string>('', [Validators.required]),
    activo: new FormControl<boolean>(true, [Validators.required]),
    role: new FormControl<number>(0, [Validators.required]),
    dni: new FormControl<string>('', [Validators.required]),
    telefono: new FormControl<string>('', [Validators.required, Validators.pattern(/^\d{9}$/),]),
    email: new FormControl<string>('', [Validators.email]),
    password: new FormControl<string>('', []),
    localidad: new FormControl<string>('Lorca', [Validators.required]),
    provincia: new FormControl<string>('Murcia', [Validators.required]),
    codigoPostal: new FormControl<string>('30800', [Validators.required]),
    domicilio: new FormControl<string>('', [Validators.required]),
  });

  constructor(
    private _service: UsuariosService,
    private _loader: LoadService,
    private _toast: ToastrService,
    public modal: NgbActiveModal,
  ) { }

  async crea_usuario() {
    this._loader.notifyLoadChange(true);
    let nuevo_usuario = new UsuarioModel(
      0,
      this.formGroup.value.email ?? '',
      this.formGroup.value.dni ?? '',
      this.formGroup.value.nombre ?? '',
      this.formGroup.value.activo ?? false,
      this.formGroup.value.telefono ?? '',
      this.formGroup.value.password ?? '',
      '',
      this.formGroup.value.role ?? 0,
      this.formGroup.value.domicilio ?? '',
      this.formGroup.value.codigoPostal ?? '',
      this.formGroup.value.localidad ?? '',
      this.formGroup.value.provincia ?? '',
      '',
    );
    try {
      const data = await this._service.crea_nuevo(nuevo_usuario);
      this.envia_usuario.emit(data);
      this.modal.close();
    } catch (e: any) {
      console.log(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }
}
