import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LoadService } from 'src/app/services/helpers/load.service';
import { BorradoServices } from 'src/app/services/https/borrado.services';

@Component({
  selector: 'app-borrado-modal',
  templateUrl: './borrado-modal.component.html',
  styleUrls: ['./borrado-modal.component.scss'],
  providers: [BorradoServices]
})
export class BorradoModalComponent {
  @Input() tipo_objeto!: string
  @Input() id!: number
  @Input() tabla!: string;
  @Output() borrado_exitoso: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    public active_modal: NgbActiveModal,
    private _borrado_service: BorradoServices,
    private _load_service: LoadService,
    private _toast_service: ToastrService
  ) {

  }

  async borra_entidad() {
    try {
      this._load_service.notifyLoadChange(true);
      const borrado = await this._borrado_service.borrar_nuevo(this.id, this.tabla)
      this._toast_service.success("Eliminado con éxito")
      this.borrado_exitoso.emit(true);
    } catch (e: any) {
      console.log(e);
      this._toast_service.error(e);
      this.borrado_exitoso.emit(false);
    } finally {
      this._load_service.notifyLoadChange(false);
      this.active_modal.close();
    }
  }
}
