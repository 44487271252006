import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { createCalle } from '../helpers/model.helper';
import { ICalle } from 'src/app/models/interface/calle.interface';

@Injectable()
export class CallesService {
  public url: string = globalState.url ;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router,) {
  }

  // Realiza el login del usuario
  ficha(id: number): Observable<any> {
   const params = new HttpParams()
      .set('id', id+'')

      const httpOptions = {
        headers: new HttpHeaders({ Accept: 'application/json' }),
        params: params,
      };

    return this._http.post(this.url + 'zonascalles/ficha', params, httpOptions);
  }

  // Realiza el login del usuario
  lista(): Observable<any> {
    const params = new HttpParams()
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'zonascalles/lista', params, httpOptions);
  }

  listaZona(id: number): Observable<any> {
    const params = new HttpParams().set('zona_id', id)
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'zonascalles/listaZona', params, httpOptions);
  }

  async calles_by_zona(id_zona: number): Promise<CalleModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}zonas_calles/zona?zona_id=${id_zona}`,httpOptions));
      const calles: CalleModel[] = [];
      data.forEach((item: any) => {
        calles.push(createCalle(item))
      })
      return calles;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  async calles_ficha_nuevo(id_calle: number): Promise<CalleModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}zonas_calles/${id_calle}`,httpOptions));
      return createCalle(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  async calles_lista(): Promise<CalleModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}zonas_calles`,httpOptions));
      const calles: CalleModel[] = [];
      data.forEach((item: any) => {
        calles.push(createCalle(item))
      })
      return calles;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  // Realiza el login del usuario
  crea(calle: CalleModel): Observable<any> {
    const params = new HttpParams()
    .set('nombre', calle.nombre)
    .set('zona_id', calle.zonaId)

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'zonascalles/crea', params, httpOptions);
  }

    // Realiza el login del usuario
  creaActualiza(calle: CalleModel, funcion: string): Observable<any> {
    let params = new HttpParams()
    .set('nombre', calle.nombre)
    .set('zona_id', calle.zonaId)

    if(funcion === 'actualiza') {
      params = params.set('id', calle.id)
    }

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'zonascalles/' + funcion, params, httpOptions);
  }

  // Realiza el login del usuario
  actualiza(calle: CalleModel): Observable<any> {
    const params = new HttpParams()
      .set('id', calle.id)
      .set('nombre', calle.nombre)
      .set('zona_id', calle.zonaId)

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'zonascalles/actualiza', params, httpOptions);
  }

  async crea_nuevo(calle: ICalle): Promise<CalleModel>
  {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const payload = {
      nombre: calle.nombre,
      zona_id: calle.zonaId
    }
    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}zonas_calles`, payload, httpOptions));
      return createCalle(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza_nuevo(calle: ICalle): Promise<CalleModel>
  {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const payload = {
      nombre: calle.nombre,
      zona_id: calle.zonaId
    }
    try {
      const { data } = await firstValueFrom(this._http.put<any>(`${this.url}zonas_calles/${calle.id}`,payload, httpOptions));
      return createCalle(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  async elimina(id: number): Promise<void>
  {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      await firstValueFrom(this._http.delete<any>(`${this.url}zonas_calles/${id}`, httpOptions));
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

}
