<!-- Lista -->
<div *ngIf="typeForm === 'lista'">
  <div class="mt-5">
<app-table-default
        *ngIf="allData.length >= 0"
        [data] = allData
        [columnas_tabla] = "['id','nombre', 'zonaId']"
        [nombre_columnas] = "['Código','Nombre', 'Zona']"
        [tipo_dato_tabla]="['','','']"
        [alineacion_columnas]="['start', 'start', 'start']"
        [ancho_columnas] = "['','','']"
        [nombre_tabla] = "'zonascalles'"
        [id] = "'id'"
        [ver_boton_editar]="true"
        [ver_boton_borrar]="true"
        [ruta_nueva]="'/zonas/'+ idCliente +'/calle/nuevo'"
        [ruta_edita]="'/zonas/'+ idCliente +'/calle/'"
        (envia_borrado)="abrir_modal_borrado($event)"
      ></app-table-default>

  </div>
</div>
<!-- Edita -->
