import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { LoadService } from 'src/app/services/helpers/load.service';
import { UsuariosService } from 'src/app/services/https/usuarios.services';
import { timeoutTime } from 'src/app/services/helpers/utils.helper';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [UsuariosService]
})
export class ChangePasswordComponent {
  caducidad: Date | null = null;

  formGroup = new FormGroup({
    dni: new FormControl<string>('', [Validators.required]),
    clave1: new FormControl<string>('', [Validators.required]),
    clave2: new FormControl<string>('', [Validators.required])
  });
  email: string | null = null;
  token: string | null = null;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _nav: Router,
    private _toast: ToastrService,
    private _loader: LoadService,
    private _service: UsuariosService,
  ) {
    // Obtén los parámetros de consulta
    this._route.queryParamMap.subscribe(params => {
      this.email = params.get('email');
      this.token = params.get('token');
    });
  }


  async onSubmit() {
    this._loader.notifyLoadChange(true);
    const valido = this.comprueba_formulario();
    if (valido) {
      const { dni, clave1 } = this.formGroup.value;
      try {
        const cambiada = await this._service.cambia_clave(dni!!, clave1!!, this.token!!);
        if (cambiada) {
          this._toast.success('Contraseña cambiada, redirigiendo al inicio de sesión');
          this._router.navigate(['login']);
        }
      } catch (e: any) {
        this._toast.error(e);
      } finally {
        this._loader.notifyLoadChange(false);
      }
    }
  }

  comprueba_formulario(): boolean {
    const { dni, clave1, clave2 } = this.formGroup.value;

    if (dni === null || dni === undefined || dni === '') {
      this._toast.error("Campo DNI obligatorio")
      return false;
    }
    if (clave1 === null || clave1 === undefined || clave1 === '') {
      this._toast.error("Campo contraseña obligatorio")
      return false;
    }
    if (clave2 === null || clave2 === undefined || clave2 === '') {
      this._toast.error("Campo repetir contraseña obligatorio")
      return false;
    }
    if (clave1 !== clave2) {
      this._toast.error("Las contraseñas no coinciden")
      return false;
    }
    return true
  }
}
