<app-page-container-publico>
  <form class="container-fluid mx-auto pt-5" style="max-width: 400px;" (ngSubmit)="onSubmit()" #zonaForm="ngForm">
      <div class="form-header mt-4  ">
        <h6  class="mb-0">Resetear Contraseña</h6>
      </div>

      <div class="form-body-three-cols mt-3">
        <div class="mt-3">
          <app-input
            [control]="formGroup.controls.dni"
            [label]="'DNI *'"
            [type]="'text'"
            [id]="'clave1'"
          />
        </div>
        <div class="mt-3">
          <app-input
            [control]="formGroup.controls.clave1"
            [label]="'Contraseña *'"
            [type]="'password'"
            [id]="'clave1'"
          />
        </div>
        <div class="mt-3">
          <app-input
            [control]="formGroup.controls.clave2"
            class="mt-3"
            [label]="'Repite Contraseña *'"
            [type]="'password'"
            [id]="'clave2'"
          />
        </div>

      </div>

      <div class="d-flex gap-3 justify-content-start">
        <button
          type="submit"
          class="btn btn-aceptar mt-5"
          [disabled]="!formGroup.valid"
        >
          <span > Cambiar </span>
        </button>
        <a
          [routerLink]="'/'"
          class="btn btn-outline-error mt-5"
        >
          Cancelar
        </a>
      </div>
    </form>
</app-page-container-publico>
