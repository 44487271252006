<div class="pb-5">
  <app-table-default
    [data]="documentos"
    [columnas_tabla]="['nombre', 'observaciones', 'valida_id','obligatorio']"
    [nombre_columnas]="['Nombre', 'Observaciones', 'Validado','Obligatorio']"
    [alineacion_columnas]="['start','start','','']"
    [tipo_dato_tabla]="['', 'openModal', 'validado', 'boolean' ]"
    [ancho_columnas]="['', '', '', '']"
    [propiedades_para_filtro]="['id', 'nombre']"
    [nombre_tabla]="'documentos'"
    [id_nombre]="'id'"
    [ruta_nueva]="'/tipo-documentacion-cliente/solicitud/'+ solicitud_id +'/tipo/nuevo'"
    [ruta_edita]="''"
    [ver_boton_borrar]="es_administrador"
    [ver_boton_nuevo]="es_administrador"
    [ver_barra_superior]="es_administrador"
    [ver_boton_editar]="false"
    [ver_boton_descargar]="true"
    [ver_boton_subir]="true"
    [ver_editar_observaciones]="true"
    (recarga_datos)="recarga_datos(solicitud_id)"
    />
</div>
