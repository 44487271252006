<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Subir archivo</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="active_modal.dismiss('')"
  ></button>
</div>
<div class="modal-body">
  <input
    type="file"
    class="form-control"
    (change)="this.formatear_archivo($event)"
    accept="image/png, image/jpeg, application/pdf"
  />
  <p>
    <kbd class="text-s">Formatos válidos: png, jpg y pdf</kbd><br>
    Tamaño máximo: 8MB
  </p>
</div>
<div *ngIf="active_modal" class="modal-footer">
  <button type="button" class="btn btn-aceptar" (click)="this.subir_archivo()">
    Subir archivo
  </button>
  <button
    type="button"
    class="btn btn-outline-danger"
    (click)="active_modal.close('')"
  >
    Cancelar
  </button>
</div>
