<form class="form-container" (ngSubmit)="onSubmit()" #zonaForm="ngForm">
  <div class="form-header mt-4">
    <h6 *ngIf="es_nuevo" class="mb-0">Registro de zona</h6>
    <h6 *ngIf="!es_nuevo" class="mb-0">Actualización de zona</h6>
    <div class="form-check form-switch form-check">

    </div>
  </div>
  <div class="form-body-three-cols mt-3">
    <div class="two-cols">
      <app-input
        [control]="formGroup.controls.nombre"
        [label]="'Nombre *'"
        [type]="'text'"
        [id]="'nombre'"
      >
      </app-input>
    </div>
    <app-input
      [control]="formGroup.controls.abreviatura"
      [label]="'Abreviatura *'"
      [type]="'text'"
      [id]="'abreviatura'"
    >
    </app-input>
  </div>

  <div class="row mt-3 ">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 ">
      <app-input
        [control]="formGroup.controls.completo_base"
        [label]="'Base Imp. Año *'"
        [type]="'number'"
        [id]="'precio_completo_base'"
        [step]="'0.01'"
      >
      </app-input>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3 ">
      <app-input
        [control]="formGroup.controls.completo_iva"
        [label]="'IVA Año *'"
        [type]="'number'"
        [id]="'precio_completo_iva'"
      >
      </app-input>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3 ">
      <app-input
        [control]="formGroup.controls.medio_base"
        [label]="'Base Imp. Medio Año *'"
        [type]="'number'"
        [id]="'medio_base'"
      >
      </app-input>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3 ">
      <app-input
        [control]="formGroup.controls.medio_iva"
        [label]="'IVA Medio Año *'"
        [type]="'number'"
        [id]="'medio_iva'"
      >
      </app-input>
    </div>
  </div>

  <div class="form-body-three-cols mt-3">

    <div class="d-flex flex-column">
      <div>
        <img *ngIf="formGroup.value.mapa && formGroup.value.mapa !== ''" height="150" [src]="formGroup.value.mapa" alt="mapa-zona" class="img img-fluid">
      </div>
      <label for="formFile">Mapa de Zona *</label>
      <input
      type="file"
      class="form-control"
      (change)="this.formatear_archivo($event)"
      accept="image/png, image/jpeg, application/pdf"
    />
    </div>
    <div class="">
      <app-color-picker
        [control]="formGroup.controls.color"
        [label]="'Color *'"
        [name]="'color'"
        [maxWidth]="'38px'"
        [maxHeight]="'38px'"
        [id]="'color'"
      >
      </app-color-picker>
    </div>
    <div class="full-width mt-3">
      <!-- <app-tiny-editor
        [control]="formGroup.controls.observaciones"
        [label]="'Observaciones'"
        [id]="'observaciones'"
      ></app-tiny-editor> -->
      <app-text-editor
        [control]="formGroup.controls.observaciones"
        [label]="'Observaciones'"
        [id]="'observaciones'" />
    </div>
  </div>
  <div class="d-flex gap-3">
    <button type="submit" class="btn btn-aceptar mt-5">
      <span *ngIf="es_nuevo"> Crear </span>
      <span *ngIf="!es_nuevo"> Actualiza </span>
    </button>
    <a [routerLink]="'/zonas'" class="btn btn-outline-error mt-5"> Cancelar </a>
  </div>
</form>

<app-lineal-component-selector *ngIf="!es_nuevo"
  [componentsLabel]="['Calles asignadas']"
  [dynamicComponent]="[tipoCalle]"
></app-lineal-component-selector>
