<app-solicitud-header-fomr
  *ngIf="!es_nuevo && solicitud"
  [data]="solicitud!!"
  (refresca_datos)="refresca_datos($event)"
  (envia_datos_tarjeta)="envia_datos_tarjeta.emit($event)"
  (envia_datos_factura)="envia_datos_factura.emit($event)"
  (envia_datos_tar_fac)="envia_datos_tar_fac.emit($event)" />

<form class="form-container" #zonaForm="ngForm" (ngSubmit)="onSubmit()">
  <div class="form-header mt-4">
    <h6 *ngIf="es_nuevo" class="mb-0">Nueva Solicitud</h6>
    <h6 *ngIf="!es_nuevo" class="mb-0">Actualizar Solicitud</h6>
  </div>
  <div class="form-body-three-cols mt-3">
    <div class="col mt-3">
      <div class="col-12" *ngIf="es_administrador">
        <label class="mt-auto" for="usuarioId">Usuario*</label>
        <div class="position-relative">
          <input
            (click)="abrir_modal_selecciona_usuario()"
            [id]="'usuarioId'"
            placeholder="Selecciona un usuario"
            class="form-control"
            type="text"
            #usuarioNombre
            disabled
            [value]="formGroup.controls.usuarioNombre.value" />

          <button
            #toggleButton
            type="button"
            (click)="pulsa_boton_menu()"
            class="btn btn-primary position-absolute end-0 top-0">
            <fa-icon [icon]="flecha_abajo"> </fa-icon>
          </button>
          <div
            #menu
            [ngClass]="{ 'not-show': !menu_boton_usuario }"
            class="header-usermenu">
            <div class="usermenu-container">

              <button
                (click)="abrir_modal_nuevo_usuario()"
                type="button"
                role="button"
                class="btn btn-primary position-absolute2 end-0 top-0">
                <fa-icon [icon]="icono_anyadir"> </fa-icon>
              </button>

              <button
                (click)="abrir_modal_selecciona_usuario()"
                role="button"
                type="button"
                class="btn btn-primary position-absolute end-0 top-0">
                <fa-icon [icon]="icono_buscar"> </fa-icon>
              </button>
              <a
                class="btn btn-primary position-absolute end-0 top-0"
                *ngIf="
                  (formGroup.controls.usuarioId.value != 0 &&
                    formGroup.controls.usuarioId.value != null) &&
                  formGroup.controls.usuarioId.value != null
                "
                target="_blank"
                [routerLink]="['/usuarios', formGroup.controls.usuarioId.value]">
                <fa-icon [icon]="icono_editar" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <app-input
          class="col mt-0"
          [control]="formGroup.controls.destinatario"
          [placeholder]="'Ej: Pedro Rodríguez'"
          [label]="'Destinatario'"
          [type]="'text'"
          [id]="'destinatario'"
          [disabled]="estado >= 2">
        </app-input>
      </div>
    </div>

    <div class="col mt-3">
      <app-input
          class="col mt-0"
          [control]="formGroup.controls.telefono"
          [placeholder]="'Ej: +34 689 74 89 21'"
          [label]="'Teléfono'"
          [type]="'tel'"
          [id]="'destinatario'"
          [disabled]="estado >= 2">
        </app-input>
    </div>

    <div class="col mt-3">
      <app-select-input
        [label]="'Tipo de cliente *'"
        [optionsLabels]="['id', 'nombre']"
        [optionData]="tipos_clientes"
        [defaultSelectText]="'Selecciona tipo de cliente'"
        [control]="formGroup.controls.tipoClienteId"
        [name]="'select-tipo-cliente'"
        [id]="'select-tipo-cliente'"
        [disabled]="estado >= 2" />
      <p
        *ngIf="formGroup.controls.tipoClienteId.value === 0 && formGroup.valid"
        class="error-message text-center">
        Tipo de cliente no seleccionado
      </p>
    </div>

    <app-input
      #matricula
      class="col mt-4"
      [control]="formGroup.controls.matricula"
      [label]="'Matrícula del vehículo *'"
      [placeholder]="'1234MU'"
      [type]="'text'"
      [id]="'matricula'"
      [maxLength]="10"
      [disabled]="estado >= 2">
    </app-input>

    <app-input
      class="col mt-4"
      [control]="formGroup.controls.marca"
      [label]="'Marca del vehículo *'"
      [type]="'text'"
      [placeholder]="'FORD'"
      [id]="'marca'"
      [disabled]="estado >= 2">
    </app-input>
    <app-input
      class="col mt-4"
      [control]="formGroup.controls.modelo"
      [label]="'Modelo del vehículo *'"
      [placeholder]="'FIESTA'"
      [type]="'text'"
      [id]="'modelo'"
      [disabled]="estado >= 2" />



    <div class="col mt-4">
      <app-select-input *ngIf="estado >= 2"
        [label]="'Zona *'"
        [optionsLabels]="['id', 'nombre']"
        [optionData]="zonas"
        [defaultSelectText]="'Selecciona ZONA'"
        [control]="formGroup.controls.zonaId"
        [name]="'select-zona'"
        [id]="'select-zona'"
        [disabled]="estado >= 2" />
      <div class="position-relative w-100 d-flex align-content-end" *ngIf="estado < 2">
        <div class="form-group" style="width: 90%;">
          <label [htmlFor]="'calle'">Zona *</label>
          <select
            [attr.disabled]="estado >= 2 ? true : null"
            [class.invalid]="formGroup.controls.zonaId.invalid && formGroup.controls.zonaId.dirty && formGroup.controls.zonaId.touched && formGroup.controls.zonaId.value == 0"
            [formControl]="formGroup.controls.zonaId"
            [id]="'zona'"
            [name]="'zona'"
            (change)="obten_calles_por_zona($event)"
            class="form-select">

            <option disabled [value]="0">Selecciona zona ...</option>
            <option *ngFor="let option of zonas" [value]="option.id">
              {{option.nombre}}
            </option>
          </select>
        </div>
        <button
          (click)="abrir_modal_mapa()"
          type="button"
          role="button"
          class="btn btn-primary mt-auto">
          <fa-icon [icon]="icono_mapa"> </fa-icon>
        </button>
      </div>
      <p
        *ngIf="formGroup.controls.zonaId.value === 0 && formGroup.valid"
        class="error-message text-center mb-0ç">
        Zona no seleccionada
      </p>
    </div>
    <div class="col mt-4">


      <app-select-input
        *ngIf="!formGroup.controls.calleEncontrada.value"
        class="mb-0"
        [label]="'Calle*'"
        [optionData]="calles"
        [optionsLabels]="['id', 'nombre']"
        [defaultSelectText]="'Selecciona calle'"
        [defaultSelectValue]="0"
        [control]="formGroup.controls.calleId"
        [name]="'select-calle'"
        [id]="'select-calle'"
        [disabled]="formGroup.controls.zonaId.value === 0 || ( estado >= 2 )" />
      <app-input
        *ngIf="formGroup.controls.calleEncontrada.value"
        [control]="formGroup.controls.domicilio"
        [label]="'Calle *'"
        [placeholder]="'Calle Perez Casas'"
        [type]="'text'"
        [disabled]="formGroup.controls.zonaId.value === 0 || (estado >= 2)"
        [id]="'calleEncontrada'" />

      <app-reverse-switch
        [label]="'No he encontrado mi calle'"
        [control]="formGroup.controls.calleEncontrada"
        [id]="'calle-encontrada'" />
      <p
        *ngIf="formGroup.controls.zonaId.value === 0 && formGroup.valid"
        class="error-message text-center mb-0">
        Seleciona una zona
      </p>
    </div>
    <div class="d-flex gap-3 align-content-center mt-4">
      <app-input
        class="col mt-0"
        [control]="formGroup.controls.calle_numero"
        [placeholder]="'Ej: 15'"
        [label]="'Numero'"
        [type]="'text'"
        [id]="'destinatario'"
        [disabled]="estado >= 2" />
      <app-input
        class="col mt-0"
        [control]="formGroup.controls.calle_piso"
        [placeholder]="'Ej: 2º/2'"
        [label]="'Piso/Altura'"
        [type]="'text'"
        [id]="'destinatario'"
        [disabled]="estado >= 2" />
      <app-input
        class="col mt-0"
        [control]="formGroup.controls.calle_letra"
        [placeholder]="'Ej: A/Izq'"
        [label]="'Piso/Alt Letra'"
        [type]="'text'"
        [id]="'destinatario'"
        [disabled]="estado >= 2" />
      <app-input
        class="col mt-0"
        [control]="formGroup.controls.calle_bloque"
        [placeholder]="'Ej: G'"
        [label]="'Bloque'"
        [type]="'text'"
        [id]="'destinatario'"
        [disabled]="estado >= 2" />

    </div>
    <div
      class="col d-flex flex-wrap align-content-center">
      <app-select-input
        class="mb-0 w-100"
        [label]="'Tipo de pago *'"
        [optionData]="tipo_pago_data"
        [optionsLabels]="['id', 'nombre']"
        [defaultSelectText]="'Selecciona Tipo de Pago'"
        [control]="formGroup.controls.tipoPagoId"
        [name]="'select-calle'"
        [id]="'select-calle'" />
    </div>
    <div
      class="mt-5 full-width">
      <app-reverse-switch
        [label]="'Segundo Semestre'"
        [control]="formGroup.controls.medio"
        [id]="'medio'" />
    </div>
    <div class="full-width mt-3">
      <div class="form-check form-switch form-check-reverse">
        <input
          class="form-check-input"
          type="checkbox"
          id="consentimiento"
          [formControl]="formGroup.controls.consentimiento" />
        <p class="form-check-label" for="consentimiento">
          <span>He leido y acepto las </span>
          <a
            class="text-primary"
            role="button"
            type="button"
            (click)="abrir_modal_consentimiento()">CLÁUSULA ADICIONAL DATOS
            TERCEROS</a>
        </p>
      </div>
    </div>
    <div class="full-width">
      <p class="text-end">
        <a
          class="text-primary"
          role="button"
          type="button"
          (click)="abrir_modal_condiciones()">CONDICIONES DE USO DE LA TARJETA
          (EXTRACTO ORDENANZAS)</a>
      </p>
    </div>
  </div>
  <div
    [ngClass]="
      es_nuevo
        ? 'd-flex gap-3 justify-content-end'
        : 'd-flex justify-content-between'
    ">
    <div *ngIf="!es_nuevo && es_administrador" class="d-flex gap-3">
      <button
        class="btn btn-outline-primary mt-5"
        type="button"
        (click)="abrir_modal_cambio_matricula()">
        Cambio de matrícula
      </button>
      <button
        class="btn btn-outline-primary mt-5"
        type="button"
        (click)="abrir_modal_cambio_domicilio()">
        Cambio de domicilio
      </button>
      <button
        class="btn btn-outline-primary mt-5"
        type="button"
        (click)="abrir_modal_cambio_particular()">
        Cambio de particular
      </button>
    </div>
    <div class="d-flex gap-3">
      <button
        type="submit"
        class="btn btn-aceptar mt-5"
        [disabled]="formGroup.invalid">
        <span *ngIf="es_nuevo"> Crear </span>
        <span *ngIf="!es_nuevo"> Actualiza </span>
      </button>
      <a [routerLink]="'/solicitudes'" class="btn btn-outline-error mt-5">
        Cancelar
      </a>
    </div>
  </div>
</form>

<app-lineal-component-selector
  *ngIf="!es_nuevo"
  [componentsLabel]="['Documentación']"
  [dynamicComponent]="[documentos_lista]" />
