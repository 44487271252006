<app-page-container-publico [title]="'Calles'" >
  <div class="container-fluid">
    <ng-container *ngIf="typeForm === ''">
      <!-- <app-table-default
        *ngIf="allData.length >= 0"
        [data] = allData
        [tableColumns] = "['id','nombre', 'zonaId']"
        [labelColumns] = "['Código','Nombre', 'Zona']"
        [tableItemType]="['','','']"
        [widthColumns] = "['','','']"
        [showEditButton]="true"
        [showDeleteButton]="true"
        [searcher] = "['id','nombre']"
        [tableName] = "'zonascalles'"
        [idName] = "'id'"
      ></app-table-default> -->
    </ng-container>
    <app-nuevo-edita-calles
    *ngIf="typeForm!== '' && !cargando"
    [calle]="calle"
    [id]="id"
    [id_zona]="id_zona"
    [zonas]="zonas"
    [type]="typeForm"/>
  </div>
</app-page-container-publico>
