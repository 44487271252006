import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { globalState } from 'src/global';
import { CallesService } from 'src/app/services/https/calles.services';
import { LoadService } from 'src/app/services/helpers/load.service';
import { timeoutTime } from 'src/app/services/helpers/utils.helper';

@Component({
  selector: 'tarjeta-print',
  templateUrl: './tarjeta-print.component.html',
  styleUrls: ['./tarjeta-print.component.scss'],
  providers: [CallesService]
})
export class TarjetaPrintComponent {

  @Input() tarjetaId: number = 0;
  @Input() annio: number = 0;
  @Input() numero: number = 0;
  @Input() zonaId!: number;
  @Input() zonaAbreviatura: string = '';
  @Input() matricula: string = '';
  @Input() color: string = '';
  @Input() imprime: Boolean = false;
  @Input() visualiza: Boolean = false;
  @Input() saltopagina: Boolean = true;

  public codigoQR = '';
  public calles1: any[] = [];
  public calles2: any[] = [];
  public calles3: any[] = [];
  public filas: number = 10;
  public columnas: number = 3;
  public numeroActual: number = 0;
  public cargado: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _service: CallesService,
    private _loader: LoadService
  ) {
    if (this.tarjetaId > 0) {
      this.getTarjeta();
      this.obtain_calles();
    }
  }

  ngOnInit(): void {
    if (this.tarjetaId > 0) {
      this.getTarjeta();

    }
  }

  ngDoCheck(): void {
    if ((this.numero > 0) && (this.numero != this.numeroActual)) {
      this.numeroActual = this.numero;
      this.obtain_calles();
    }
    // console.log(this.imprime)
    if (this.imprime && this.cargado) {
      this.imprime = false;
      this.imprimeTarjeta();
    }
  }

  getUrlTarjeta() {
    // http://localhost:4200/valida-tarjetas/check/1
    this.codigoQR = `${globalState.frontUrl}/valida-tarjetas/check/${this.tarjetaId}`
  }

  getTarjeta() {
    // Busca los datos de la tarjeta
    this. getUrlTarjeta();
  }

  imprimeTarjeta() {
    if (!this.visualiza && this.cargado) {
      window.print();
    }
  }

  async obtain_calles() {
    this.calles1 = [];
    this.calles2 = [];
    this.calles3 = [];
    try {
      const data = await this._service.calles_by_zona(this.zonaId)
      this.calles1 = data
      timeoutTime((100)).then(() => {
        this._loader.notifyLoadChange(false);
        this.cargado = true;
      });
    } catch (e: any) {
      console.log(e);
    }
  }

}
