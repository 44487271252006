import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { SolicitudModel } from 'src/app/models/entity/solicitud.model';
import { createSolicitud, createSolicitudView } from '../helpers/model.helper';
import { SolicitudView } from 'src/app/models/entity/views/solicitudes.view';
import { EditaSolicitudView } from 'src/app/models/entity/views/edita_solicitud.view';
import { IActualizaSolicitud, IInsertaSolicitud } from 'src/app/models/interface/solicitud.interface';

@Injectable()
export class SolicitudesServices {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router) { }

  // Realiza el login del usuario
  ficha(id: number): Observable<any> {
    const params = new HttpParams().set('id', id + '');

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + 'solicitudes/ficha', params, httpOptions);
  }



  // Realiza el login del usuario
  lista(filtro: string): Observable<any> {
    const params = new HttpParams().set('filtro', filtro);
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(
      this.url + 'solicitudes/lista',
      params,
      httpOptions
    );
  }

  async listapag(pagina: number, registros: number, filtro: string): Promise<{ solicitudes: SolicitudView[], pagina_actual: number, total_paginas: number }> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const res = await firstValueFrom(this._http.get<any>(`${this.url}solicitudes?pagina=${pagina}&registros=${registros}&filtro=${filtro}`, httpOptions));
      const list_solicitud: SolicitudView[] = [];
      res.data.Solicitudes.forEach((item: any) => {
        list_solicitud.push(createSolicitudView(item))
      })
      const { pagina_actual, total_paginas } = res.data
      return { solicitudes: list_solicitud, pagina_actual, total_paginas };
    } catch (e: any) {
      console.log(e);
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }

  // Realiza el login del usuario
  total(filtro: string): Observable<any> {
    const params = new HttpParams()
      .set('filtro', filtro);
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'tarjetas/total', params, httpOptions);
  }

  // Realiza el login del usuario
  listaRenovacion(pagina: number, registros: number, filtro: string): Observable<any> {
    const params = new HttpParams()
      .set('pagina', pagina + '')
      .set('registros', registros + '')
      .set('filtro', filtro);
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(
      this.url + 'solicitudes/listaRenovacion',
      params,
      httpOptions
    );
  }

  // Realiza el login del usuario
  crea(calle: CalleModel): Observable<any> {
    const params = new HttpParams()
      .set('nombre', calle.nombre)
      .set('zona_id', calle.zonaId);

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(
      this.url + 'solicitudes/crea',
      params,
      httpOptions
    );
  }

  // Realiza el login del usuario
  creaActualiza(model: SolicitudModel, funcion: string): Observable<any> {
    let params = new HttpParams()
      .set('usuario_id', model.usuarioId)
      .set('destinatario', model.destinatario)
      .set('tipocliente_id', model.tipoClienteId)
      .set('matricula', model.matricula)
      .set('marca', model.marca)
      .set('modelo', model.modelo)
      .set('medio', model.medio ? '1' : '0')
      .set('consentimiento', model.consentimiento ? '1' : '0')
      .set('tramita_id', model.tramitaId ? model.tramitaId : 0)
      .set('factura_numero', model.facturaNumero ? model.facturaNumero : 0)
      .set('factura_serie', model.facturaSerie ? model.facturaSerie : '')
      .set('tarjeta_id', model.tarjeta_id ? model.tarjeta_id : 0)
      .set('importe', model.importe)
      .set('iva', model.iva)
      .set('pagado', model.pagado ? '1' : '0')
      .set('zona_id', model.zonaId)
      .set('calle_id', model.calleId)
      .set('domicilio', model.domicilio)
      .set('renovacion', model.renovacion ? '1' : '0')
      .set('tarjetarenovada', model.tarjetarenovada)
      //       .set('documentoscorrectos', '0')
      //       .set('documentospendientes', '0')
      .set('tipo_pago', model.tipo_pago);

    if (funcion === 'actualiza') {
      params = params.set('id', model.id);
    } else {
      params = params.set('estado', 1);
    }

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(
      this.url + 'solicitudes/' + funcion,
      params,
      httpOptions
    );
  }

  async ficha_nueva(id: number): Promise<EditaSolicitudView> {

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}solicitudes/${id}`, httpOptions));
      return new EditaSolicitudView(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async crea_nuevo(model: IInsertaSolicitud): Promise<SolicitudModel> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}solicitudes`, model, httpOptions));
      return createSolicitud(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza_nuevo(model: IActualizaSolicitud): Promise<any> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    try {
      const data = await firstValueFrom(this._http.put<any>(`${this.url}solicitudes/${model.id_solicitud}`, model, httpOptions));
      return data;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  // Realiza el login del usuario
  actualiza(model: SolicitudModel): Observable<any> {
    const params = new HttpParams()
      .set('id', model.id)
      .set('usuario_id', model.usuarioId)
      .set('destinatario', model.destinatario)
      .set('estado', model.estado)
      .set('tipocliente_id', model.tipoClienteId)
      .set('matricula', model.matricula)
      .set('marca', model.marca)
      .set('modelo', model.modelo)
      .set('medio', model.medio ? '1' : '0')
      .set('consentimiento', model.consentimiento ? '1' : '0')
      // .set('condiciones', model.condiciones)
      .set('tramita_id', model.tramitaId)
      .set('factura_numero', model.facturaNumero)
      .set('tarjeta_id', model.tarjeta_id)
      .set('importe', model.importe)
      .set('iva', model.iva)
      .set('annio', model.annio)
      .set('pagado', model.pagado ? '1' : '0');

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(
      this.url + 'solicitudes/actualiza',
      params,
      httpOptions
    );
  }

  // Realiza el login del usuario
  actualizaEstado(id: number, estado: number): Observable<any> {
    const params = new HttpParams().set('id', id).set('estado', estado);

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(
      this.url + 'solicitudes/actualizaEstado',
      params,
      httpOptions
    );
  }

  async actualiza_estado(id: number, estado: number): Promise<SolicitudModel> {
    const json_payload = {
      id: id,
      estado: estado
    }
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}solicitudes/cambia_estado`,json_payload, httpOptions))
      return data;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  // Realiza el login del usuario
  vista(id: number): Observable<any> {
    const params = new HttpParams().set('id', id + '');

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + 'solicitudes/vista', params, httpOptions);
  }

  cambiaDomicilio(id_solicitud: number, domicilio: string, zona_id: number, persona_tramita: number): Observable<any> {
    const params = new HttpParams()
      .set('id_solicitud', id_solicitud)
      .set('domicilio', domicilio)
      .set('zona_id', zona_id)
      .set('persona_tramita', persona_tramita)

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + 'solicitudes/cambioDomicilio', params, httpOptions);
  }

  cambiaMatricula(id_solicitud: number, matricula: string, marca: string, modelo: string, persona_tramita: number): Observable<any> {
    const params = new HttpParams()
      .set('id_solicitud', id_solicitud)
      .set('matricula', matricula)
      .set('marca', marca)
      .set('modelo', modelo)
      .set('persona_tramita', persona_tramita)


    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + 'solicitudes/cambioMatricula', params, httpOptions);
  }



  getInforme(filtro: string): Observable<any> {
    const params = new HttpParams()
      .set('filtro', filtro)
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + 'solicitudes/informe', params, httpOptions);
  }

  getCount(annio: string): Observable<any> {
    const params = new HttpParams()
      .set('annio', annio)
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + 'solicitudes/informeTotal', params, httpOptions);
  }



  async cambia_matricula(id_solicitud: number, matricula: string, marca: string, modelo: string, persona_tramita: number): Promise<Number> {
    const json_payload = {
      id_solicitud: id_solicitud,
      matricula: matricula,
      marca: marca,
      modelo: modelo,
      persona_tramita: persona_tramita
    };
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}solicitudes/cambia_matricula`, json_payload, httpOptions));
      return data;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async cambia_particular(id_particular: number, id_solicitud: number): Promise<Number> {
    const json_payload = {
      id_solicitud: id_solicitud,
      id_particular: id_particular
    };

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}solicitudes/cambia_particular`, json_payload, httpOptions));
      return data;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async cambia_domicilio(
    id_solicitud: number,
    domicilio: string,
    zona_id: number,
    persona_tramita: number,
    calle_id: number,
    calle_piso: string,
    calle_numero:string,
    calle_bloque: string
  ): Promise<Number> {
    const json_payload = {
      id_solicitud: id_solicitud,
      domicilio: domicilio,
      zona_id: zona_id,
      persona_tramita: persona_tramita,
      calle_id: calle_id.toString(),
      calle_piso: calle_piso,
      calle_numero: calle_numero,
      calle_bloque: calle_bloque
    };

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}solicitudes/cambia_domicilio`, json_payload, httpOptions));
      return data;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al cambia el domicilio';
      return Promise.reject(errorMessage);
    }
  }

  async obtener_informe(filtro: string): Promise<SolicitudView[]> {
    const json_payload = {
      filtro: filtro,
    };

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}solicitudes/informes`, json_payload, httpOptions));
      const lista: SolicitudView[] = [];
      data.forEach((item: any) => {
        lista.push(createSolicitudView(item))
      });
      return lista;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al cambia el domicilio';
      return Promise.reject(errorMessage);
    }
  }

  async obtener_informe_total(annio: number): Promise<number> {
    const json_payload = {
      annio: annio.toString(),
    };

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}solicitudes/informes_total`, json_payload, httpOptions));
      return data
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al cambia el domicilio';
      return Promise.reject(errorMessage);
    }
  }

  async obtiene_importe_total_annio(annio: number): Promise<number> {
    const json_payload = {
      annio: annio.toString(),
    };

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}solicitudes/informes_total_importe`, json_payload, httpOptions));
      return data
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al cambia el domicilio';
      return Promise.reject(errorMessage);
    }
  }
}
