import { IZona } from "../interface/zona.interface";

export class ZonasModel implements IZona{
  id: number;
  nombre: string;
  abreviatura: string;
  activo: boolean;
  observaciones: string;
  color: string;
  completo_base: number;
  completo_iva: number;
  medio_base: number;
  medio_iva: number;
  mapa: string;

  constructor(
    id: number,
    nombre: string,
    abreviatura: string,
    activo: boolean,
    observaciones: string,
    color: string,
    completo_base: number,
    completo_iva: number,
    medio_base: number,
    medio_iva: number,
    mapa: string,

  ) {
    this.id = id;
    this.nombre = nombre;
    this.abreviatura = abreviatura;
    this.activo = activo;
    this.observaciones = observaciones;
    this.color = color;
    this.completo_base = completo_base;
    this.completo_iva = completo_iva;
    this.medio_base = medio_base;
    this.medio_iva = medio_iva;
    this.mapa = mapa;
  }
}

export interface IInsertaZona {
  nombre: string,
  abreviatura: string,
  activo: boolean,
  observaciones: string,
  color: string,
  completo_base: number,
  completo_iva: number,
  medio_base: number,
  medio_iva: number,
  mapa: string
}

export interface IInsertaZona {
  id: number,
  nombre: string,
  abreviatura: string,
  activo: boolean,
  observaciones: string,
  color: string,
  completo_base: number,
  completo_iva: number,
  medio_base: number,
  medio_iva: number,
  mapa: string,
  file: File
}

export interface IActualizaZona {
  id: number,
  nombre: string,
  abreviatura: string,
  activo: boolean,
  observaciones: string,
  color: string,
  completo_base: number,
  completo_iva: number,
  medio_base: number,
  medio_iva: number,
  mapa: string | undefined,
  file: File | undefined
}
