<div class="modal-header">
  <h4 class="modal-title">Solicitar Cambio de Domicilio</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="active_modal.dismiss('')"></button>
</div>
<div class="modal-body">
  <form class="mt-3" (ngSubmit)="this.cambia_domicilio();">
    <div class="row mb-3">
      <div class="position-relative w-100 d-flex align-content-end">
        <div class="form-group w-100">
          <label [htmlFor]="'zona'">Zona *</label>
          <select
            [class.invalid]="form.controls.zona_id.invalid && form.controls.zona_id.dirty && form.controls.zona_id.touched && form.controls.zona_id.value == 0"
            [formControl]="form.controls.zona_id"
            [id]="'zona'"
            [name]="'zona'"
            (change)="obten_calles_por_zona($event)"
            class="form-select">

            <option disabled [value]="0">Selecciona zona ...</option>
            <option *ngFor="let option of zonas_data" [value]="option.id">
              {{option.nombre}}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-3 w-100">
        <app-select-input
          *ngIf="!form.controls.calle_encontrada.value"
          class="mb-0"
          [label]="'Calle*'"
          [optionData]="calles"
          [optionsLabels]="['id', 'nombre']"
          [defaultSelectText]="'Selecciona calle'"
          [defaultSelectValue]="0"
          [control]="form.controls.calle_id"
          [name]="'select-calle'"
          [id]="'select-calle'"
          [disabled]="form.controls.zona_id.value === 0" />
        <app-input
          *ngIf="form.controls.calle_encontrada.value"
          [control]="form.controls.calle"
          [label]="'Calle *'"
          [placeholder]="'Calle Perez Casas'"
          [type]="'text'"
          [disabled]="form.controls.zona_id.value === 0"
          [id]="'calleEncontrada'" />

        <app-reverse-switch
          [label]="'No he encontrado mi calle'"
          [control]="form.controls.calle_encontrada"
          [id]="'calle-encontrada'" />
      </div>
      <div class="row d-flex gap-3 mt-3 w-100">
        <app-input
          [control]="form.controls.calle_numero"
          [label]="'Numero *'"
          [placeholder]="'10 / SN'"
          [type]="'text'"
          [id]="'calle_numero'" />
        <app-input
          [control]="form.controls.calle_piso"
          [label]="'Piso/Altura *'"
          [placeholder]="'2º'"
          [type]="'text'"
          [id]="'calle_piso'" />
        <app-input
          [control]="form.controls.calle_letra"
          [label]="'Letra *'"
          [placeholder]="'Izq / A'"
          [type]="'text'"
          [id]="'calle_letra'" />
        <app-input
          [control]="form.controls.calle_bloque"
          [label]="'Bloque *'"
          [placeholder]="'BLOQUE 5'"
          [type]="'text'"
          [id]="'calle_bloque'" />
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-aceptar"
      (click)="this.cambia_domicilio();">
      Aceptar
    </button>
    <button
      type="button"
      class="btn btn-outline-error"
      (click)="
      active_modal.close()
    ">
      Cancelar
    </button>
  </div>
