import { ChangeDetectorRef, Component } from '@angular/core';
import { LoadService } from 'src/app/services/helpers/load.service';
import { globalState } from 'src/global';

@Component({
  selector: 'app-loading-component',
  templateUrl: './loading-component.component.html',
  styleUrls: ['./loading-component.component.scss']
})

// Proporciona una pantalla de carga en cualquier parte de la aplicación
export class LoadingComponentComponent {
  public isLoading: boolean = globalState.isLoading;
  public message: string = "Cargando..."

  constructor(
    private _loader: LoadService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._loader.loadChanged$.subscribe((newLoad) => {
      this.isLoading = newLoad;
      // Forzar detección de cambios
      this.changeDetectorRef.detectChanges();
    })
  }
}
