import { Component } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createCalle } from 'src/app/services/helpers/model.helper';
import { CallesService } from 'src/app/services/https/calles.services';
import { ZonasService } from 'src/app/services/https/zonas.services';

@Component({
  selector: 'app-calles-page',
  templateUrl: './calles-page.component.html',
  styleUrls: ['./calles-page.component.scss'],
  providers: [CallesService, ZonasService]
})
export class CallesPageComponent {
  typeForm: string = '';
  id: number = 0;
  id_zona: number = 0;
  allData: CalleModel[] = [];
  calle: CalleModel | null = null;
  zonas: ZonasModel[] = [];
  cargando: boolean = true;
  constructor(
    private _route: ActivatedRoute,

    private _nav: Router,
    private _service: CallesService,
    private _zona_service: ZonasService,
    private _loader: LoadService,
    ){
    this._route.params.subscribe((params: Params) => {
      // Accede al valor del parámetro y asígnalo a la variable typeConfig
      if (params['slug'] !== undefined) {
        this.typeForm = params['slug'];
        this.id_zona = Number(params['id']);
        const es_nuevo = isNaN(params['slug'])
        this.id = es_nuevo ? 0 : Number(params['slug']);
      }
    });
  }

  async ngOnInit(): Promise<void> {
    if(this.typeForm !== '') {
      try {
        this._loader.notifyLoadChange(true);
        this.zonas = await this.obtener_zonas();
        if (this.id !== 0){
          this.calle = await this.obtener_calle(this.id);
        }
      } catch (e: any) {
        console.log(e);
      } finally {
        this._loader.notifyLoadChange(false);
        this.cargando = false;
      }
    }
  }

  async obtener_calle(id: number): Promise<CalleModel>
  {
    return await this._service.calles_ficha_nuevo(id);
  }

  async obtener_zonas(): Promise<ZonasModel[]>
  {
    const { zonas } = await this._zona_service.lista_completa();
    return zonas;
  }
}
