<div class="search-dropdown" [ngClass]="{'open':shown && list !== undefined && list!!.length !== 0,'small':size=='small'}">
  <div class="search-dropdown__btn" (click)="show()"><span>{{value | titlecase}}</span></div>
  <div class="search-dropdown__dropdown">
    <input class="search-dropdown__dropdown__search" type="text" placeholder="Search" name="search-select" [(ngModel)]="keyword" [ngModelOptions]="{standalone: true}"
      (keyup)="search(keyword)" #input />
    <div class="search-dropdown__dropdown__scroller">
      <div class="search-dropdown__dropdown__item" *ngFor="let item of list" (click)="select(item)">
        {{item[_label] | titlecase}}
      </div>
      <div class="search-dropdown__dropdown__msg" *ngIf="list?.length == 0">Sin resultado</div>
    </div>
  </div>
</div>
