import { Component } from '@angular/core';
import { UsuariosService } from './../../../../services/https/usuarios.services';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { createUsuario } from 'src/app/services/helpers/model.helper';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LoadService } from 'src/app/services/helpers/load.service';
import { globalState } from 'src/global';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-usuarios-page',
  templateUrl: './usuarios-page.component.html',
  styleUrls: ['./usuarios-page.component.scss'],
  providers: [UsuariosService]
})
export class UsuariosPageComponent {
  es_listado: boolean = true;
  data: UsuarioModel[] = [];
  usuario_nuevo: UsuarioModel | null = null;
  usuario: UsuarioModel = globalState.identity!!
  id: number = 0;
  // Paginacion
  pagina_actual: number = 1;
  filtro: string = '';
  salida_filtro: string = '';
  total_registros: number = 100;
  total_paginas: number = 0;
  registros_pagina: number = globalState.registrosPagina;
  filtros: any[] = [];
  cargando: boolean = true;

  constructor(
    private _router: Router,
    private _nav: Router,
    private _service: UsuariosService,
    private _loader: LoadService,
    private _toast: ToastrService
  ) {
    const { url } = this._router
    const segments = url.split('/');
    this.es_listado = segments.length === 2;
    if (!this.es_listado) {
      if (segments[segments.length - 1] !== "nuevo") {
        this.id = Number(segments[segments.length - 1]);
      }
    }
  }

  async ngOnInit() {
    try {
      this._loader.notifyLoadChange(true);
      if (this.es_listado) {
        const filtro = this.genera_filtro(this.filtro);
        const { usuarios, total_paginas, pagina_actual} = await this.obtener_usuarios(this.pagina_actual, this.registros_pagina, filtro);
        this.data = usuarios;
        this.total_paginas = total_paginas;
        this.pagina_actual = pagina_actual;
      } else {
        if (this.id !== 0)
          this.usuario_nuevo = await this.obten_usuario(this.id)
      }
    } catch(e: any) {
      this._toast.error(e)
    } finally {
      this._loader.notifyLoadChange(false);
      this.cargando = false;
    }
  }

  async obtener_usuarios(pagina: number, registros: number, filtro: string): Promise<{usuarios: UsuarioModel[], total_paginas: number, pagina_actual: number}> {
    return await this._service.lista_paginacion(pagina, registros, filtro);
  }

  async obten_usuario(id: number): Promise<UsuarioModel> {
    return await this._service.ficha_nuevo(id);
  }

  async recibe_filtro(event: any) {
    try {
      this._loader.notifyLoadChange(true);
      const { filtro_texto } = event
      this.filtro = filtro_texto;
      const filtro = this.genera_filtro(filtro_texto);
      const { usuarios, total_paginas, pagina_actual} = await this.obtener_usuarios(this.pagina_actual, this.registros_pagina, filtro);
      this.data = usuarios;
      this.total_paginas = total_paginas;
      this.pagina_actual = pagina_actual;
    } catch (e: any) {
      this._toast.error(e)
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  async recibe_pagina(event: number) {
    try {
      this._loader.notifyLoadChange(true);
      this.pagina_actual = event;
      const filtro = this.genera_filtro(this.filtro);
      const { usuarios, total_paginas, pagina_actual} = await this.obtener_usuarios(event, this.registros_pagina, filtro);
      this.data = usuarios;
      this.total_paginas = total_paginas;
      this.pagina_actual = pagina_actual;
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  genera_filtro(texto: string) {
    let filtro = '';
    // Si el role es de usuario solo debe ver sus solicitudes
    if (this.usuario!!.role < 1) {
      filtro += `where (id = '${this.usuario!!.id}')`;
    }
    // Filtro del cuadro de búsqueda
    if (texto !== '') {
      filtro += filtro === '' ? ' where ' : ' and ';
      filtro += `(INSTR(nombre, '${texto}') > 0) OR (INSTR(dni, '${texto}') > 0)`;
    }
    return filtro;
  }
}
