import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { CallesService } from 'src/app/services/https/calles.services';
import { SolicitudesServices } from 'src/app/services/https/solicitudes.services';
import { ZonasService } from 'src/app/services/https/zonas.services';
import { globalState } from 'src/global';

@Component({
  selector: 'app-modal-cambio-domicilio',
  templateUrl: './modal-cambio-domicilio.component.html',
  styleUrls: ['./modal-cambio-domicilio.component.scss'],
  providers: [SolicitudesServices, ZonasService, CallesService]
})
export class ModalCambioDomicilioComponent {
  @Input() id!: number;
  zonas_data: ZonasModel[] = [];
  calles: CalleModel[] = [];

  constructor(
    public active_modal: NgbActiveModal,
    private _solicitudes_services: SolicitudesServices,
    private _calle_service: CallesService,
    private _zonas_services: ZonasService,
    private _nav: Router,
    private _load_service: LoadService,
    private _toast: ToastrService
  ) { }

  form = new FormGroup({
    calle: new FormControl<string>('', [Validators.required]),
    error: new FormControl<string>('', [Validators.required]),
    zona_id: new FormControl<number>(0, [Validators.required]),
    calle_id: new FormControl<number>(0),
    calle_numero: new FormControl<string>(''),
    calle_piso: new FormControl<string>(''),
    calle_letra: new FormControl<string>(''),
    calle_bloque: new FormControl<string>(''),
    calle_encontrada: new FormControl<boolean>(false)
  });

  async ngOnInit(): Promise<void> {
    try {
      this._load_service.notifyLoadChange(true);
      const { zonas } = await this._zonas_services.lista_completa();
      this.zonas_data = zonas;
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._load_service.notifyLoadChange(false);
    }
  }

  async cambia_domicilio() {
    this._load_service.notifyLoadChange(true);
    const valido = this.valida_formulario();
    if (!valido) {
      this._load_service.notifyLoadChange(false);
      return;
    }
    this._load_service.notifyLoadChange(true);
    try {
      const { calle_id, calle_numero, calle_piso, calle_bloque, zona_id, calle } = this.form.value
      const nueva_id = await this._solicitudes_services.cambia_domicilio(
        this.id,
        calle!! ,
        zona_id!!,
        globalState.identity?.id ?? 0,
        calle_id!!,
        calle_piso!!,
        calle_numero!!,
        calle_bloque!!
        );
      this._nav.navigate(['solicitudes'])
      this.active_modal.dismiss();
      this._toast.success("¡Cambio de domicilio generado!")
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._load_service.notifyLoadChange(false);
    }
  }

  async obten_calles_por_zona(event: any) {
    this.calles = await this._calle_service.calles_by_zona(event.target.value)
  }

  valida_formulario(): boolean {
    const { calle_id, calle_numero, calle_piso, calle_bloque, zona_id, calle } = this.form.value
    if (calle_id === null || calle_id === undefined || calle_id === 0) {
      if (calle === null || calle !== undefined || calle === '') {
        this._toast.error('Verifica los datos para el cambio de domicilio')
        return false;
      }
    }
    if (calle_numero === null || calle_numero == undefined || calle_numero === '') {
      this._toast.error('Verifica los datos para el cambio de domicilio')
      return false;
    }
    if (zona_id === null || zona_id == undefined || zona_id === 0) {
      this._toast.error('Verifica los datos para el cambio de domicilio')
      return false;
    }
    if (calle_piso === null || calle_piso == undefined || calle_piso === '') {
      this._toast.error('Verifica los datos para el cambio de domicilio')
      return false;
    }
    return true
  }
}
