import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { UsuariosService } from 'src/app/services/https/usuarios.services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registro-form',
  templateUrl: './registro-form.component.html',
  styleUrls: ['./registro-form.component.scss'],
  providers: [UsuariosService]
})

export class RegistroFormComponent implements OnInit{

  emailMessage: string = '';
  formGroup = new FormGroup({
    nombre: new FormControl<string>('', [Validators.required]),
    dni: new FormControl<string>('', [Validators.required]),
    domicilio: new FormControl<string>('', [Validators.required]),
    codigoPostal: new FormControl<string>('', [Validators.required]),
    localidad: new FormControl<string>('', [Validators.required]),
    provincia: new FormControl<string>('', [Validators.required]),
    telefono: new FormControl<string>('', [Validators.required, Validators.pattern(/^\d{9}$/),]),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    password: new FormControl<string>('', [Validators.required]),
  })

  constructor(
    private _usuariosService: UsuariosService,
    private _loader: LoadService,
    private _toastr: ToastrService,
    private _route: Router,
    ) {}

  ngOnInit(): void {
  }

  onSubmit() {
   this._loader.notifyLoadChange(true);
    const nuevoUsuario = new UsuarioModel(
      0,
      this.formGroup.value.email ?? '',
      this.formGroup.value.dni ?? '',
      this.formGroup.value.nombre ?? '',
      false,
      this.formGroup.value.telefono ?? '',
      this.formGroup.value.password ?? '',
      '',
      0,
      this.formGroup.value.domicilio ?? '',
      this.formGroup.value.codigoPostal ?? '',
      this.formGroup.value.localidad ?? '',
      this.formGroup.value.provincia ?? '',
      '',
    );
    this.creaUsuario(nuevoUsuario);
  }

  creaUsuario(nuevoUsuario: UsuarioModel) {
    try {
      const usuario = this._usuariosService.crea_nuevo(nuevoUsuario)
      this._toastr.success('Usuario registrado');
      this._route.navigate([`/aviso-activa-email`]);
    } catch(e: any) {
      this._toastr.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }
}
