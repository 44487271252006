<app-page-container-publico class="solo-pantalla" id="informes" [title]="'Informes'">
  <div class="container-fluid mb-5">
    <form class="row mt-5" (submit)="ngOnInit()">
      <div class="col d-flex justify-content-center">
        <app-input
          [control]="formGroup.controls.fechaInicio"
          [label]="'Inicio'"
          [type]="'date'"
          [disabled]="false"
          [id]="'fecha_inicio'"
        />
      </div>
      <div class="col d-flex justify-content-center">
        <app-input
          [control]="formGroup.controls.fechaFin"
          [label]="'Fin'"
          [type]="'date'"
          [disabled]="false"
          [id]="'fecha_fin'"
        />
      </div>
      <div class="col d-flex justify-content-center">
        <button
          class="btn btn-aceptar"
          type="submit"
        >
        Buscar informe
      </button>
      </div>
    </form>
    <app-table-default *ngIf="data.length > 0"
      [data] = data
      [columnas_tabla]="[
        'factura_codigo',
        'usuarioNombre',
        'usuarioDomicilio',
        'matricula',
        'fecha_pagado',
        'nombreZona',
        'tarjeta_numero',
        'tipo_pago',
        'importe',
        'iva',
        'total',
      ]"
      [nombre_columnas]="[
        'Factura',
        'Nombre',
        'Domicilio',
        'Matricula',
        'Fecha',
        'Zona',
        'Tarjeta',
        'Tipo de pago',
        'Base imponible',
        'IVA',
        'Total',
      ]"
      [tipo_dato_tabla]="[
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'money',
        'money',
        'money'
      ]"
      [alineacion_columnas]="[
        'start#start',
        'start#start',
        'start#start',
        'start#start',
        'start#start',
        'start#start',
        'start#start',
        'start#start',
        'start#start',
        'start#start',
        'start#start',
      ]"
      [ancho_columnas]="[]"
      [nombre_tabla]="'ver_solicitudes'"
      [tiene_paginacion]="false"
      [pagina_actual]="0"
      [ver_barra_superior]="false"
      [ver_acciones]="false"
    />
  <p class="text-muted text-end pe-3">Total entre las fechas seleccionadas: <b class="text-muted">{{data.length}}</b></p>
  <p class="text-muted text-end pe-3">Importe total entre las fechas seleccionadas: <b class="text-muted">{{importe_entre_fechas}} €</b></p>
  <p class="text-muted text-end pe-3">TPV: <b class="text-muted">{{totalTpv}}</b></p>
  <p class="text-muted text-end pe-3">Efectivo: <b class="text-muted">{{totalEfectivo}}</b></p>
  <p class="text-muted text-end pe-3">Online: <b class="text-muted">{{totalOnline}}</b></p>
  <p class="text-muted text-end pe-3">Total desde el 1 de enero: <b class="text-muted">{{count}}</b></p>
  <p class="text-muted text-end pe-3">Importe total desde el 1 de enero: <b class="text-muted">{{importe_total_annio}} €</b></p>

  </div>
</app-page-container-publico>
