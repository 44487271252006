import { Injectable } from '@angular/core';
import { Router, Params } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { IActualizaZona, IInsertaZona, ZonasModel } from 'src/app/models/entity/zonas.model';
import { createZona } from '../helpers/model.helper';

@Injectable()
export class ZonasService {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router,) {
  }

  // Realiza el login del usuario
  ficha(id: number): Observable<any> {
    const params = new HttpParams()
      .set('id', id + '')

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + 'zonas/ficha', params, httpOptions);
  }

  // Realiza el login del usuario
  lista(): Observable<any> {
    const params = new HttpParams()
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'zonas/lista', params, httpOptions);
  }



  async lista_mapas(): Promise<{ id: number, mapa: string }[]> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}zonas/mapas`, httpOptions));
      return data;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  // Realiza el login del usuario
  mapas(): Observable<any> {
    const params = new HttpParams()
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'zonas/mapas', params, httpOptions);
  }

  // Realiza el login del usuario
  crea(zona: ZonasModel): Observable<any> {
    const params = new HttpParams()
      .set('nombre', zona.nombre)
      .set('abreviatura', zona.abreviatura)
      .set('activo', zona.activo ? '1' : '0')
      .set('color', zona.color)
      .set('observaciones', zona.observaciones);

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'zonas/crea', params, httpOptions);
  }

  // Realiza el login del usuario
  creaActualiza(zona: ZonasModel, funcion: string): Observable<any> {
    let params = new HttpParams()
      .set('nombre', zona.nombre)
      .set('abreviatura', zona.abreviatura)
      .set('activo', zona.activo ? '1' : '0')
      .set('color', zona.color)
      .set('observaciones', zona.observaciones)
      .set('medio_base', zona.medio_base)
      .set('medio_iva', zona.medio_iva)
      .set('completo_base', zona.completo_base)
      .set('completo_iva', zona.completo_iva)
      .set('observaciones', zona.observaciones)
      .set('mapa', zona.mapa);

    if (funcion === 'actualiza') {
      params = params.set('id', zona.id)
    }

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + 'zonas/' + funcion, params, httpOptions);
  }

  // Realiza el login del usuario
  actualiza(zona: ZonasModel): Observable<any> {
    const params = new HttpParams()
      .set('id', zona.id)
      .set('nombre', zona.nombre)
      .set('abreviatura', zona.abreviatura)
      .set('activo', zona.activo ? '1' : '0')
      .set('medio_base', zona.medio_base)
      .set('medio_iva', zona.medio_iva)
      .set('completo_base', zona.completo_base)
      .set('completo_iva', zona.completo_iva)
      .set('observaciones', zona.observaciones);

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'zonas/actualiza', params, httpOptions);
  }

  insertaZona(data: any): Observable<any> {
    const params = new HttpParams()
      .set('configuracion_id', data.configuracionId)
      .set('zona_id', data.zonaId);

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(this.url + 'configuracionzona/crea', params, httpOptions)
  }

  deleteConf(id: number): Observable<any> {
    const params = new HttpParams()
      .set('id', id)

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + 'configuracionzona/elimina', params, httpOptions);
  }


  async lista_completa(): Promise<{ zonas: ZonasModel[] }> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}zonas`, httpOptions));
      const zonas: ZonasModel[] = [];
      data.forEach((item: any) => {
        zonas.push(createZona(item))
      })
      return { zonas }
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  async ficha_nuevo(id: number): Promise<ZonasModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}zonas/${id}`, httpOptions));
      return createZona(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la zona ' + id;
      return Promise.reject(errorMessage);
    }
  }

  async inserta_nuevo(nuevo: IInsertaZona): Promise<ZonasModel>
  {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const formData = new FormData();
    formData.append('nombre', nuevo.nombre);
    formData.append('abreviatura', nuevo.abreviatura);
    formData.append('observaciones', nuevo.observaciones);
    formData.append('color', nuevo.color);
    formData.append('completo_base', nuevo.completo_base.toString());
    formData.append('completo_iva', nuevo.completo_iva.toString());
    formData.append('medio_base', nuevo.medio_base.toString());
    formData.append('medio_iva', nuevo.medio_iva.toString());
    formData.append('activo', nuevo.activo ? '1' : '0');
    formData.append('mapa', nuevo.mapa);
    formData.append('file', nuevo.file);
    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}zonas`, formData, httpOptions));
      return createZona(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar la zona ';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza_nuevo(nuevo: IActualizaZona): Promise<ZonasModel>
  {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const formData = new FormData();
    formData.append('id', nuevo.id.toString());
    formData.append('nombre', nuevo.nombre);
    formData.append('abreviatura', nuevo.abreviatura);
    formData.append('observaciones', nuevo.observaciones);
    formData.append('color', nuevo.color);
    formData.append('completo_base', nuevo.completo_base.toString());
    formData.append('completo_iva', nuevo.completo_iva.toString());
    formData.append('medio_base', nuevo.medio_base.toString());
    formData.append('medio_iva', nuevo.medio_iva.toString());
    formData.append('activo', nuevo.activo ? '1' : '0');
    if (nuevo.mapa && nuevo.file) {
      formData.append('mapa', nuevo.mapa);
      formData.append('file', nuevo.file);
    }
    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}zonas/actualiza`, formData, httpOptions));
      return createZona(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar la zona ';
      return Promise.reject(errorMessage);
    }
  }
}
