<app-page-container-publico class="solo-pantalla" [title]="'Tarjetas'">
  <div class="container-fluid">
    <ng-container *ngIf="typeForm === ''">
      <app-table-default
        [data]="tarjetas"
        [columnas_tabla]="[
          'codigo',
          'nombre#destinatario',
          'dni',
          'matricula',
          'zonaId',
          'annio',
          'facturaSerie',
          'facturaNumero',
          'solicitudId'
        ]"
        [nombre_columnas]="[
          'Tarjeta',
          'Nombre',
          'DNI',
          'Matrícula',
          'Zona',
          'Año',
          'F. Serie',
          'F. Numero',
          'Solicitud'
        ]"
        [tipo_dato_tabla]="[
          '', '', '', '', '', '', '', '', ''
        ]"
        [alineacion_columnas]="[
          'start',
          'start#start',
          'center',
          'center',
          'center',
          '',
          '',
          '',
          '',
          ''
          ]"
        [filtro_dinamico_principal]="filtros_dinamicos_principales"
        [ancho_columnas]="[]"
        [ver_boton_nuevo]="false"
        [ver_boton_editar]="true"
        [ver_boton_borrar]="false"
        [ver_boton_imprimir]="true"
        [propiedades_para_filtro]="['numero', 'nombre', 'dni', 'matricula']"
        [nombre_tabla]="'tarjetas'"
        [tiene_paginacion] = "true"
        [pagina_actual] = "pagina_actual"
        [total_paginas]="total_paginas"
        [ver_boton_modal_externo]="false"
        [texto_modal_externo]="'Renovaciones'"
        (envia_filtro)="recibe_filtro($event)"
        (envia_pagina)="recibe_pagina($event)"
        (enviar_datos_impresion)="recibir_ambos($event)"
      />
    </ng-container>
    <app-tarjeta-edit-nuevo
      *ngIf="typeForm !== '' && typeForm !== 'check'"
    ></app-tarjeta-edit-nuevo>
    <app-check-tarjeta-valida
      *ngIf="typeForm !== '' && typeForm === 'check'"
    ></app-check-tarjeta-valida>
  </div>
</app-page-container-publico>

<!-- <factura-print
  *ngIf="datos_factura !== null"
  id="factura"
  #factura
  [solicitud]="datos_factura.id"
  [imprime]="imprimir_factura"
/> -->

<tarjeta-print
  *ngIf="datos_tarjeta !== null"
  class="mt-2"
  id="tarjeta"
  #tarjeta
  [tarjetaId]="datos_tarjeta.id"
  [annio]="datos_tarjeta.annio"
  [numero]="datos_tarjeta.numero"
  [zonaId]="datos_tarjeta.zona_id"
  [zonaAbreviatura]="datos_tarjeta.zonaAbreviatura"
  [matricula]="datos_tarjeta.matricula"
  [color]="datos_tarjeta.color"
  [imprime]="imprimir_tarjeta"
/>

