import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { ICalle } from 'src/app/models/interface/calle.interface';
import { IZona } from 'src/app/models/interface/zona.interface';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createCalle, createZona } from 'src/app/services/helpers/model.helper';
import { timeoutTime } from 'src/app/services/helpers/utils.helper';
import { CallesService } from 'src/app/services/https/calles.services';
import { ZonasService } from 'src/app/services/https/zonas.services';

@Component({
  selector: 'app-nuevo-edita-calles',
  templateUrl: './nuevo-edita-calles.component.html',
  styleUrls: ['./nuevo-edita-calles.component.scss'],
  providers: [ZonasService, CallesService]
})
export class NuevoEditaCallesComponent {

  @Input() type: string = '';
  @Input() calle!: CalleModel | null;
  @Input()id!: number;
  @Input()id_zona!: number;
  @Input()zonas!: ZonasModel[];
  // Datos del formulario
  formGroup = new FormGroup({
    nombre: new FormControl<string>('', [Validators.required]),
    zonaId: new FormControl<number>(0,[Validators.required]),
  });

  constructor(
    private _calle_service: CallesService,
    private _toast: ToastrService,
    private _loader: LoadService,
    private _router: Router
  ) {

  }

  ngOnInit() {
    if (this.id !== 0 && this.calle ) {
      this.formGroup.patchValue({
        nombre: this.calle.nombre,
        zonaId: this.calle.zonaId,
      })
    }
  }



  async onSubmit() {
    const valido = this.comprueba_formulario()
    const { nombre, zonaId } = this.formGroup.value;
    if (!valido) {
      return;
    }
    try {
      this._loader.notifyLoadChange(true);
      if (this.id === 0) {
        const nuevo: ICalle = {
          id: 0,
          nombre: nombre!!,
          zonaId: zonaId!!
        }
        const data = await this._calle_service.crea_nuevo(nuevo);
        this._router.navigate([`zonas/${zonaId}`])
      } else {
        const actualiza: ICalle = {
          id: this.id,
          nombre: nombre!!,
          zonaId: zonaId!!
        }
        const data = await this._calle_service.actualiza_nuevo(actualiza);
        this._router.navigate([`zonas/${zonaId}`])
      }
    } catch (e: any) {
      this._toast.error(e)
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  comprueba_formulario(): boolean {
    const { nombre, zonaId } = this.formGroup.value;
    if (!nombre || nombre === '') {
      this._toast.error("El campo nombre es obligatorio")
      return false;
    }
    if (!zonaId || zonaId === 0) {
      this._toast.error("El campo zona es obligatorio")
      return false;
    }
    return true;
  }
}

