import { IEditaSolicitudView } from "../../interface/ver_edita_solicitud.interface";

export class EditaSolicitudView implements IEditaSolicitudView {
  id: number;
  annio: number;
  numero: number;
  usuario_id: number;
  telefono: string;
  usuario_nombre: string;
  usuario_dni: string;
  usuario_telefono: string;
  usuario_localidad: string;
  destinatario: string;
  fechasolicitud: string | null;
  estado: number;
  tipocliente_id: number;
  matricula: string;
  marca: string;
  modelo: string;
  zona_id: number;
  medio: boolean;
  consentimiento: boolean;
  tramita_id: number;
  tramita_nombre: string;
  fechaaceptacion: string | null;
  fechadenegacion: string | null;
  factura_serie: string;
  factura_numero: number;
  tarjeta_id: number;
  tarjeta_numero: number;
  importe: number;
  iva: number;
  total: number;
  pagado: boolean;
  calle_id: number;
  domicilio: string;
  documentosPendientes: number;
  documentosCorrectos: number;
  renovacion: boolean;
  tarjetarenovada: number;
  tiposolicitud: number;
  solicitud_destino: number;
  solicitud_origen: number;
  fecha_pagado: string | null;
  tipo_pago: string;
  calle_numero: string;
  calle_piso: string;
  calle_letra: string;
  calle_bloque: string;


  constructor(data: IEditaSolicitudView) {
    this.id = data.id;
    this.annio = data.annio;
    this.numero = data.numero;
    this.usuario_id = data.usuario_id;
    this.telefono = data.telefono;
    this.usuario_nombre = data.usuario_nombre;
    this.usuario_dni = data.usuario_dni;
    this.usuario_telefono = data.usuario_telefono;
    this.usuario_localidad = data.usuario_localidad;
    this.destinatario = data.destinatario;
    this.fechasolicitud = data.fechasolicitud;
    this.estado = data.estado;
    this.tipocliente_id = data.tipocliente_id;
    this.matricula = data.matricula;
    this.marca = data.marca;
    this.modelo = data.modelo;
    this.zona_id = data.zona_id;
    this.medio = data.medio;
    this.consentimiento = data.consentimiento;
    this.tramita_id = data.tramita_id;
    this.tramita_nombre = data.tramita_nombre;
    this.fechaaceptacion = data.fechaaceptacion;
    this.fechadenegacion = data.fechadenegacion;
    this.factura_serie = data.factura_serie;
    this.factura_numero = data.factura_numero;
    this.tarjeta_id = data.tarjeta_id;
    this.tarjeta_numero = data.tarjeta_numero;
    this.importe = data.importe;
    this.iva = data.iva;
    this.total = data.total;
    this.pagado = data.pagado;
    this.calle_id = data.calle_id;
    this.domicilio = data.domicilio;
    this.documentosPendientes = data.documentosPendientes;
    this.documentosCorrectos = data.documentosCorrectos;
    this.renovacion = data.renovacion;
    this.tarjetarenovada = data.tarjetarenovada;
    this.tiposolicitud = data.tiposolicitud;
    this.solicitud_destino = data.solicitud_destino;
    this.solicitud_origen = data.solicitud_origen;
    this.fecha_pagado = data.fecha_pagado;
    this.tipo_pago = data.tipo_pago;
    this.calle_numero = data.calle_numero;
    this.calle_piso = data.calle_piso;
    this.calle_letra = data.calle_letra;
    this.calle_bloque = data.calle_bloque;
  }

}
