import { Component } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BorradoModalComponent } from 'src/app/components/common/borrado-modal/borrado-modal.component';
import { TipoClienteModel } from 'src/app/models/entity/tipoCliente.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createTipoClient, createZona } from 'src/app/services/helpers/model.helper';
import { TipoClienteService } from 'src/app/services/https/tipoCliente.services';
import { ZonasService } from 'src/app/services/https/zonas.services';

@Component({
  selector: 'app-tipo-cliente-page',
  templateUrl: './tipo-cliente-page.component.html',
  styleUrls: ['./tipo-cliente-page.component.scss'],
  providers: [TipoClienteService]
})
export class TipoClientePageComponent {
  typeForm: string = '';
  idCliente: number = 0;
  allData: TipoClienteModel[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _modal_service: NgbModal,
    private _toast: ToastrService,
    private _service: TipoClienteService,
    private _loader: LoadService,
    ){
    this._route.params.subscribe((params: Params) => {
      // Accede al valor del parámetro y asígnalo a la variable typeConfig
      if (params['slug'] !== undefined) {
        this.typeForm = params['slug'];
        if(this.typeForm !== 'nuevo') {
        }
        this.idCliente = params['id']
      }
    });
  }

  async ngOnInit(){
    if(this.typeForm === '') {
      // this.obtainData();
      try {
        this.allData = await this.obten_tipo_cliente();
      } catch (e: any) {
        this._toast.error(e)
      }
    }
  }

  async obten_tipo_cliente(): Promise<TipoClienteModel[]> {
    try {
      const { tipos } = await this._service.lista_completa();
      return tipos
    } catch (e) {
      return Promise.reject(e)
    }
  }
  obtainData() {
    this._loader.notifyLoadChange(true);
    this._service.lista().subscribe({
      next: (res) => {
        res.data.forEach((element: any) => {
          this.allData.push(
            createTipoClient(element)
          );
        });
      this._loader.notifyLoadChange(false);
      },
      error: (err) => {
        this._loader.notifyLoadChange(false);
      },
      complete: () => {
        this._loader.notifyLoadChange(false);
      }
    })
  }

  abrir_modal_borrado(id: number): void {
    const modalRef = this._modal_service.open(BorradoModalComponent, { centered: true })
    modalRef.componentInstance.tipo_objeto = "tipo de cliente"
    modalRef.componentInstance.id = id
    modalRef.componentInstance.tabla = "tipo_cliente"
    modalRef.componentInstance.borrado_exitoso.subscribe(async (borrado_exitoso: boolean) => {
      if(borrado_exitoso) {
        this._loader.notifyLoadChange(true);
        this.allData = await this.obten_tipo_cliente();
        this._loader.notifyLoadChange(false);
      }
    })
  }
}
