import { Component, EventEmitter, Input, Output,SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SolicitudModel } from 'src/app/models/entity/solicitud.model';
import { TarjetaModel } from 'src/app/models/entity/tarjeta.model';
import { TarjetaView } from 'src/app/models/entity/views/tarjetas.view';
//import { SolicitudView } from 'src/app/models/entity/views/solicitudes.view';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createSolicitud, createTarjetaVista } from 'src/app/services/helpers/model.helper';
import { SolicitudesServices } from 'src/app/services/https/solicitudes.services';
import { TarjetasServices } from 'src/app/services/https/tarjetas.service';
import { globalState } from 'src/global';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IInsertaSolicitud } from 'src/app/models/interface/solicitud.interface';

@Component({
  selector: 'app-modal-renovaciones',
  templateUrl: './modal-renovaciones.component.html',
  styleUrls: ['./modal-renovaciones.component.scss'],
  providers: [TarjetasServices, SolicitudesServices]
})

export class ModalRenovacionesComponent {
  constructor(
    public active_modal: NgbActiveModal,
    private _tar_service: TarjetasServices,
    private _toast: ToastrService,
    private _loading: LoadService
  ) {}
  renovaciones: TarjetaView[] = [];
  filtro: string = "";
  filtro_texto: string = "";
  pagina_actual: number = 1;
  total_paginas: number = 1;
  icono_buscar: IconDefinition = faSearch;
  @Output() envia_renovacion: EventEmitter<TarjetaView> = new EventEmitter<TarjetaView>()

  async ngOnInit(): Promise<void> {
    try {
      this._loading.notifyLoadChange(true)
      await this.obtener_renovaciones();
    } catch (e:any) {
      this._toast.error(e)
    } finally {
      this._loading.notifyLoadChange(false)
    }
  }

  genera_filtro(): string {
    let filtro: string = ` WHERE ANNIO = ${globalState.annioActual - 1}`
    if (this.filtro_texto !== "") {
      filtro += ` AND (INSTR(nombre,'${this.filtro_texto}') or INSTR(dni, '${this.filtro_texto}') or INSTR(matricula, '${this.filtro_texto}') or INSTR(numero, '${this.filtro_texto}')or INSTR(destinatario, '${this.filtro_texto}')or INSTR(domicilio, '${this.filtro_texto}'))`;
    }
    return filtro;
  }

  async obtener_renovaciones(): Promise<void> {
    try {
      this._loading.notifyLoadChange(true);
      this.filtro = this.genera_filtro()
      const data = await this._tar_service.lista_paginacion_nueva(this.pagina_actual, 13, this.filtro)
      this.renovaciones = data.tarjetas;
      this.pagina_actual = data.pagina_actual;
      this.total_paginas = data.total_paginas
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loading.notifyLoadChange(false);
    }

  }

  async buscar_en_renovacion() {
    await this.obtener_renovaciones();
  }

  async cambia_pagina (pagina: number) {
    this.pagina_actual = pagina;
    this.obtener_renovaciones();
  }

  async selecciona_renovacion(position: number) {
    const renovacion = createTarjetaVista(this.renovaciones[position]);
    this.envia_renovacion.emit(renovacion)
  }
}
