<app-page-container-publico
  class="solo-pantalla"
  [title]="es_administrador ? 'Solicitudes' : 'Mis Solicitudes'">
  <div class="container-fluid">
    <ng-container *ngIf="usuario">
      <!-- Role administrador-->
      <ng-container *ngIf="es_listado && !cargando">
        <app-table-default
          [data]="allData"
          [columnas_tabla]="[
            'codigo#nombreZona',
            'usuarioNombre#destinatario',
            'usuarioDomicilio#usuarioTelefono',
            'calle_numero#calle_piso',
            'matricula',
            'marca#modelo',
            'fechaAceptacion#fechaDenegacion',
            'tarjeta_numero#tarjeta_activa',
            'factura_codigo#pagada'
          ]"
          [nombre_columnas]="[
            'Solicitud',
            'Nombre',
            'Domicilio',
            'Numero/Piso',
            'Matricula',
            'Vehiculo',
            'Aceptación',
            'Tarjeta',
            'Factura'
          ]"
          [tipo_dato_tabla]="[
            '',
            '',
            '',
            '',
            '',
            '',
            'badge-success#badge-error',
            '#boolean',
            '#boolean',
          ]"
          [alineacion_columnas]="[
            'start#start',
            'start#start',
            'start#start',
            'start#start',
            'start#start',
            'start#start',
            'start#start',
            'center#start',
            'center#start'
            ]"
          [filtro_dinamico_principal]="filtros_dinamicos_principales"
          [ancho_columnas]="[]"
          [ver_boton_editar]="true"
          [ver_boton_borrar]="true"
          [ver_boton_imprimir]="true"
          [propiedades_para_filtro]="['usuarioNombre','usuarioDni','matricula','destinatario']"
          [nombre_tabla]="'solicitudes'"
          [tiene_paginacion]="true"
          [pagina_actual]="pagina_actual"
          [total_paginas]="total_paginas"
          [ver_boton_modal_externo]="true"
          [texto_modal_externo]="'Renovaciones'"
          [filtro_tipo_solicitud]="true"
          [calles]="calles"
          (valores_modal_externo)="abrir_modal_externo($event)"
          (envia_filtro_solicitud)="recibe_filtro($event)"
          (envia_pagina)="recibe_pagina($event)"
          (envia_borrado)="abrir_modal_borrado($event)"
          (enviar_datos_impresion)="recibir_datos_listado($event)" />
      </ng-container>
      <app-edita-solicitud-admin
        *ngIf="!es_listado && !cargando"
        [id]="id"
        [es_nuevo]="id === 0"
        [solicitud]="solicitud"
        [zonas]="zonas"
        [calles]="calles"
        [tipos_clientes]="tipos_cliente"
        (envia_nueva_solicitud)="inserta_nueva_solicitud($event)"
        (envia_actualiza_solicitud)="actualiza_solicitud($event)"
        (envia_refresca_datos)="ngOnInit()"
        (envia_datos_tarjeta)="recibir_datos_tarjeta($event)"
        (envia_datos_factura)="recibir_datos_factura($event)"
        (envia_datos_tar_fac)="recibir_ambos($event)"

      />
    </ng-container>
  </div>
</app-page-container-publico>

<factura-print
  *ngIf="datos_factura !== null"
  id="factura"
  #factura
  [solicitud]="datos_factura.id"
  [imprime]="imprimir_factura" />

<tarjeta-print
  *ngIf="datos_tarjeta !== null"
  class="mt-2"
  id="tarjeta"
  #tarjeta
  [tarjetaId]="datos_tarjeta.id"
  [annio]="datos_tarjeta.annio"
  [numero]="datos_tarjeta.numero"
  [zonaId]="datos_tarjeta.zona_id"
  [zonaAbreviatura]="datos_tarjeta.zonaAbreviatura"
  [matricula]="datos_tarjeta.matricula"
  [color]="datos_tarjeta.color"
  [imprime]="imprimir_tarjeta" />
