import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs';
import { IActualizaZona, IInsertaZona, ZonasModel } from 'src/app/models/entity/zonas.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { createZona } from 'src/app/services/helpers/model.helper';
import { generateRandomName, timeoutTime } from 'src/app/services/helpers/utils.helper';
import { ZonasService } from 'src/app/services/https/zonas.services';
import { CallesZonaComponent } from '../calles-zona/calles-zona.component';
import { UploadServices } from 'src/app/services/https/upload.services';
import { globalState, tiposValidado } from 'src/global';
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faBell, faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import {
  faEye, faTrash
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-nuevo-edita-zona',
  templateUrl: './nuevo-edita-zona.component.html',
  styleUrls: ['./nuevo-edita-zona.component.scss'],
  providers: [ZonasService, UploadServices]
})
export class NuevoEditaZonaComponent {
  // Iconos a mostrar
  verIcon: IconDefinition = faEye;
  eliminaIcon: IconDefinition = faTrash;
  evento_subida: any
  public nombreMapa: string = '';
  public urlZona: string = '';
  public urlMapa: string = '';
  @Input() es_nuevo!: boolean;
  @Input() id!: number;
  @Input() zona!: ZonasModel | null;
  @Output() refresca_pagina: EventEmitter<void> = new EventEmitter<void>();
  tipoCalle: any = CallesZonaComponent;
  uploadFile: any;
  archivo: File | null = null;
  uploadFileName: string = '';
  formGroup = new FormGroup({
    nombre: new FormControl<string>('', [Validators.required]),
    abreviatura: new FormControl<string>('', [Validators.required]),
    completo_base: new FormControl<number>(0, [Validators.required]),
    completo_iva: new FormControl<number>(0, [Validators.required]),
    medio_base: new FormControl<number>(0, [Validators.required]),
    medio_iva: new FormControl<number>(0, [Validators.required]),
    color: new FormControl<string>('#fff', [Validators.required]),
    observaciones: new FormControl<string>('', []),
    mapa: new FormControl<string>('', []),
  });

  constructor(
    private _route: ActivatedRoute,
    private _nav: Router,
    private _zonasService: ZonasService,
    private _uploadService: UploadServices,
    private _toast: ToastrService,
    private _loader: LoadService,
    config: NgbModalConfig,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    if (!this.es_nuevo) {
      this.actualizaFormGroup(this.zona!!)
    }
  }

  actualizaFormGroup(zonaUpdated: ZonasModel) {
    this.formGroup.patchValue({
      nombre: zonaUpdated.nombre,
      completo_iva: zonaUpdated.completo_iva,
      medio_iva: zonaUpdated.medio_iva,
      completo_base: zonaUpdated.completo_base,
      medio_base: zonaUpdated.medio_base,
      abreviatura: zonaUpdated.abreviatura,
      color: zonaUpdated.color,
      observaciones: zonaUpdated.observaciones,
      mapa: zonaUpdated.mapa
    });
  }

  async onSubmit() {
    this._loader.notifyLoadChange(true);
    try {
      const { nombre, abreviatura,  color, completo_base, completo_iva, medio_base, medio_iva, observaciones } = this.formGroup.value;
      const medio_base_value: number = parseFloat(String(medio_base ?? 0.00));
      const medio_iva_value: number = parseFloat(String(medio_iva ?? 0.00));
      const completo_base_value: number = parseFloat(String(completo_base ?? 0.00));
      const completo_iva_value: number = parseFloat(String(completo_iva ?? 0.00));

      const valido = this.comprueba_formulario();
      if (!valido) {
        return
      }
      if (this.es_nuevo) {
        const nueva_zona: IInsertaZona = {
          nombre: nombre!!,
          abreviatura: abreviatura!!,
          activo: true,
          observaciones: observaciones!!,
          color: color!!,
          completo_base: completo_base_value,
          completo_iva: completo_iva_value,
          medio_base: medio_base_value,
          medio_iva: medio_iva_value,
          mapa: this.archivo!!.name,
          id: 0,
          file: this.archivo!!
        };

        const { id } = await this._zonasService.inserta_nuevo(nueva_zona);
        this._nav.navigate([`zonas/${id}`]);
        this.refresca_pagina.emit();
      } else {
        const actualiza_zona: IActualizaZona = {
          id: this.id,
          nombre: nombre!!,
          abreviatura: abreviatura!!,
          activo: true,
          observaciones: observaciones!!,
          color: color!!,
          completo_base: completo_base_value,
          completo_iva: completo_iva_value,
          medio_base: medio_base_value,
          medio_iva: medio_iva_value,
          mapa: this.archivo === null ? undefined : this.archivo.name,
          file: this.archivo === null ? undefined : this.archivo,
        }
        const { id } = await this._zonasService.actualiza_nuevo(actualiza_zona);
        this.refresca_pagina.emit();
      }
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  comprueba_formulario(): boolean {
    const { nombre, abreviatura,  color, completo_base, completo_iva, medio_base, medio_iva } = this.formGroup.value;
    if (!nombre || nombre === '') {
      this._toast.error("El campo nombre es obligatorio");
      return false
    }
    if (!abreviatura || abreviatura === '') {
      this._toast.error("El campo abreviatura es obligatorio");
      return false
    }
    if (!color || color === '') {
      this._toast.error("El campo color es obligatorio");
      return false
    }
    if (!completo_base || completo_base === 0) {
      this._toast.error("El campo Base Imp. Año es obligatorio");
      return false
    }
    if (!completo_iva || completo_iva === 0) {
      this._toast.error("El campo IVA año es obligatorio");
      return false
    }
    if (!medio_base || medio_base === 0) {
      this._toast.error("El campo Base Imp. Medio Año es obligatorio");
      return false
    }
    if (!medio_iva || medio_iva === 0) {
      this._toast.error("El campo IVA Medio Año es obligatorio");
      return false
    }
    return true
  }

  formatear_archivo(evento: any) {
    this.evento_subida = evento.target;
    try {
      const upload_event = this.evento_subida;
      // Comprobamos que exista algún archivo seleccionado en el evento
      if (upload_event.files.length > 0) {
        // Indicamos que se va a realizar una petición http (pantalla de carga)
        this._loader.notifyLoadChange(true);
        // Recorremos los archivos
        for (let i = 0; i < upload_event.files.length; i++) {
          // Se guarda extension
          const extension = upload_event.files[i].name
            .split('.')
            .slice(-1)[0];
          // Se guarda nombre de archivo
          const filename = `${generateRandomName()}.${extension}`;
          // Se genera datos del formulario
          const form_data = new FormData();
          // Creamos un tipo archivo
          const file = new File([upload_event.files[i]], filename);
          // Almacenamos la imagen
          this.archivo = file;
        }
      }
    } catch (e: any) {
      this._loader.notifyLoadChange(false);
      this._toast.error('No ha sido posible subir el archivo.');
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }
}
