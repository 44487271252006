import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { TipoClienteModel } from 'src/app/models/entity/tipoCliente.model';
import { ConfigGeneralModel } from 'src/app/models/entity/configuracion.model';
import { createConfigGenereal } from '../helpers/model.helper';
import { IActualizaConfig } from 'src/app/models/interface/configuracion.interface';

@Injectable()
export class ConfiguracionService {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router) {}

  // Realiza el login del usuario
  ficha(id: number): Observable<any> {
    const params = new HttpParams().set('id', id + '');

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(
      this.url + 'configuracion/ficha',
      params,
      httpOptions
    );
  }

  async ficha_nueva(id: number): Promise<ConfigGeneralModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}configuracion/${id}`, httpOptions));
      return createConfigGenereal(data)
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al iniciar sesión';
      return Promise.reject(errorMessage);
    }
  }

  // Realiza el login del usuario
  lista(): Observable<any> {
    const params = new HttpParams();
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(
      this.url + 'configuracion/lista',
      params,
      httpOptions
    );
  }

  // Realiza el login del usuario
  actualiza(model: ConfigGeneralModel): Observable<any> {
    const params = new HttpParams()
      .set('id', model.id)
      .set('nombre', model.nombre)
      .set('cif', model.cif)
      .set('domicilio', model.domicilio)
      .set('codigopostal', model.codigoPostal)
      .set('localidad', model.localidad)
      .set('provincia', model.provincia)
      .set('web', model.web)
      .set('email', model.email)
      .set('telefono', model.telefono)
      .set('notificaciones_email', model.notificacionesEmail)
      .set('notificaciones_host', model.notificacionesHost)
      .set('notificaciones_usuario', model.notificacionesUsuario)
      .set('notificaciones_puerto', model.notificacionesPuerto)
      .set('notificaciones_password', model.notificacionesPassword)
      .set('registro_mercantil', model.registroMercantil)
      .set('annio_actual', model.annioActual)
      .set('factura_serie', model.facturaSerie)
      .set('consentimiento', model.consentimiento)
      .set('condiciones', model.condiciones);

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post<any>(
      this.url + 'configuracion/actualiza',
      params,
      httpOptions
    );
  }

  // Realiza el login del usuario
  listaZonas(): Observable<any> {
    const params = new HttpParams();

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(
      this.url + 'configuracionzona/lista',
      params,
      httpOptions
    );
  }

  async lista_zonas_nuevo() {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}configuracion_zonas`, httpOptions));
      return data
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al iniciar sesión';
      return Promise.reject(errorMessage);
    }
  }

  async inserta_configuracion_zona(zona_id: number) {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const json_payload = {
      configuracion_id: 1,
      zona_id: zona_id
    }
    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}configuracion_zonas`, json_payload, httpOptions));
      return data
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al iniciar sesión';
      return Promise.reject(errorMessage);
    }
  }

  async elimina_zona_configuracion(zona_id: number): Promise<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      await firstValueFrom(this._http.delete<any>(`${this.url}configuracion_zonas/${zona_id}`, httpOptions));
      return true
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al iniciar sesión';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza_nuevo(actualiza: IActualizaConfig): Promise<ConfigGeneralModel>
  {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const formData = new FormData();
    formData.append('id', actualiza.id.toString());
    formData.append('nombre', actualiza.nombre.toString());
    formData.append('cif', actualiza.cif.toString());
    formData.append('domicilio', actualiza.domicilio.toString());
    formData.append('codigopostal', actualiza.codigopostal.toString());
    formData.append('localidad', actualiza.localidad.toString());
    formData.append('provincia', actualiza.provincia.toString());
    formData.append('web', actualiza.web.toString());
    formData.append('email', actualiza.email.toString());
    formData.append('telefono', actualiza.telefono.toString());
    formData.append('notificaciones_email', actualiza.notificaciones_email.toString());
    formData.append('notificaciones_host', actualiza.notificaciones_host.toString());
    formData.append('notificaciones_puerto', actualiza.notificaciones_puerto.toString());
    formData.append('notificaciones_usuario', actualiza.notificaciones_usuario.toString());
    formData.append('notificaciones_password', actualiza.notificaciones_password.toString());
    formData.append('registro_mercantil', actualiza.registro_mercantil.toString());
    formData.append('annio_actual', actualiza.annio_actual.toString());
    formData.append('consentimiento', actualiza.consentimiento.toString());
    formData.append('condiciones', actualiza.condiciones.toString());
    formData.append('factura_serie', actualiza.factura_serie.toString());

    if (actualiza.mapa && actualiza.archivo) {
      formData.append('mapa', actualiza.mapa);
      formData.append('file', actualiza.archivo);
    }
    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}configuracion/actualiza`, formData, httpOptions));
      return data;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar la zona ';
      return Promise.reject(errorMessage);
    }
  }
}
