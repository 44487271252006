<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Confirmar Cambio de Contraseña
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('')"></button>
</div>
<div class="modal-body">
  <p>¿Seguro que desea resetear la contraseña del usuario {{ dni }}?</p> <br />
  <p>Se enviará un email a su cuenta de correo para cambiar la
    contraseña</p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary text-white"
    (click)="envia_cambio_clave()">
    Cambiar
  </button>
</div>
